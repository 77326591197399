import { forgotPassword as resetPassword } from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import { MutateOptions, useMutation } from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";

const forgotPassword = async (body: any) => {
  try {
    const { data } = await axiosAction(
      "POST",
      resetPassword,
      HttpStatusCode.Ok,
      body
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useForgotPasswordMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  return useMutation(forgotPassword, args);
};
