import useDisplayDays from "../../../../pages/dashboard/hooks/useDisplayDays";
import React, { FC, useCallback, useMemo } from "react";
import {
  convertToLocalTime,
  days as Days,
  getDateString,
  isDayInBetweenDaysInclusive,
} from "../../../../utils/dateHelper";
import DayOfMonth from "./DayOfMonth";

export type IBooking = {
  booking_id: string;
  package: IPackage;
  num_of_packages: number;
  start_date: string;
  end_date: string;
  customer: ICustomer;
  duration: string;
  clock_in?: any;
  clock_out?: any;
  clock_status: "clock-in" | "on-seat" | "clocked-out";
  space: {
    name: string;
    space_type: "work station" | "meeting room";
  };
};

interface ICustomer {
  first_name: string;
  last_name: string;
  customer_type: string;
  image?: any;
}

interface IPackage {
  name: string;
  package_type: "day" | "month" | "hour";
  color_code: string;
}

function MonthlyView(props: { data: any[]; currentDate: Date }) {
  const { data, currentDate } = props;

  return (
    <div
      className="w-full flex flex-col justify-start items-start mb-8 pb-4"
      role="grid"
    >
      <DaysOfWeek />
      <DaysOfMonths date={currentDate} items={data} />
    </div>
  );
}

const DaysOfWeek: FC<{}> = (props) => {
  return (
    <div className="grid grid-cols-7 w-full" role="group">
      {Days.map((day) => {
        const res = day.slice(0, 3);
        return (
          <div
            role="gridcell"
            key={day}
            className="col-span-1 flex py-3 
             px-5 h-[56px] justify-center items-center bg-white text-[#475569] font-semibold text-[20px] leading-7 border-[#94a3b8]
              first-of-type:rounded-tl-lg last-of-type:rounded-tr-lg first-of-type:border-l border border-l-0"
          >
            {res}
          </div>
        );
      })}
    </div>
  );
};

const DaysOfMonths: FC<{ date: Date; items: any[] }> = (props) => {
  const { date, items } = props;
  const displayableDays = useDisplayDays(date);

  const computeDataForDate = useCallback(
    (day: Date) => {
      const result = items.reduce((acc: IBooking[], cur) => {
        const bookings: IBooking[] = cur.bookings;
        const bookingsInThatDay = bookings
          .filter((booking) => {
            const startTime = convertToLocalTime(booking.start_date) as Date;
            const endTime = convertToLocalTime(booking.end_date) as Date;
            if (
              (booking.package.package_type === "month" ||
                booking.package.package_type === "day") &&
              isDayInBetweenDaysInclusive(day, startTime, endTime)
            ) {
              return true;
            }
            if (
              booking.package.package_type === "hour" &&
              day.getMonth() === startTime.getMonth() &&
              day.getDate() === startTime.getDate() &&
              day.getFullYear() === startTime.getFullYear()
            ) {
              return true;
            }
          })
          .map((booking) => {
            return {
              ...booking,
              space: {
                ...(booking.space || {}),
                name: cur.name,
                space_type: cur.space_type,
              },
            };
          });
        // const dayStr = getDateString(day);
        // acc[dayStr] = (acc[dayStr] || []).concat(bookingsInThatDay);
        return acc.concat(bookingsInThatDay);
      }, []);
      return result;
    },
    [items]
  );

  return (
    <div
      role="group"
      className="w-full grid grid-cols-7 grid-rows-5 grid-flow-row"
    >
      {displayableDays.map((day, ind) => {
        return (
          <DayOfMonth
            data={computeDataForDate(day)}
            key={+day + ind}
            day={day}
            index={ind}
            currentDate={date}
          />
        );
      })}
    </div>
  );
};

export default MonthlyView;
