import React, { useEffect } from "react";
import Backdrop from "./Backdrop";

function Modal({
  onClose,
  open,
  className,
  children,
  variant = "sm",
  disableBackdrop,
}: {
  onClose: () => void;
  className?: string;
  open: boolean;
  variant?: "lg" | "sm" | "md";
  children: React.ReactNode;
  disableBackdrop?: boolean;
}) {
  const sizesMap = {
    sm: 508,
    md: 760,
    lg: 876,
  };
  // useEffect(() => {
  //   if (open) {
  //     document.body.style.overflowY = "hidden";
  //     document.body.style.position = "fixed";
  //     document.body.style.top = `-${window.scrollY}px`;
  //     document.body.style.left = `0px`;
  //     document.body.style.right = `0px`;
  //   } else {
  //     document.body.style.overflowY = "auto";
  //     const scrollY = document.body.style.top;
  //     document.body.style.position = "";
  //     document.body.style.top = "";
  //     window.scrollTo(0, parseInt(scrollY || "0") * -1);
  //   }
  // }, [open]);
  return open ? (
    <Backdrop onClose={disableBackdrop ? () => {} : onClose}>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          marginLeft: `calc(50vw - ${sizesMap[variant] / 2}px)`,
          width: `${sizesMap[variant]}px`,
        }}
        className={`${
          className ? className : ""
        } bg-white absolute rounded-lg shadow-md`}
      >
        {children}
      </div>
    </Backdrop>
  ) : null;
}

export default Modal;
