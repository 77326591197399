import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  convertToLocalTime,
  daysInMonth,
  getDateString,
  getDaysBetweenInclusive,
} from "../../../../utils/dateHelper";

import { useClickOutside } from "../../../../hooks/useOutsideClick";
import SingleMonth from "./SingleMonth";
import StatusIndicator from "./StatusIndicator";

import { useGetCustomerBookingsQuery } from "../../../../pages/customers/utils/apis/bookings";
import { DatesTypes } from "../../../../pages/dashboard/utils/apis/getOccupiedSpaces";
import { ToastNotify } from "../../../../utils/toastNotify";

function MonthViewOfPackage(props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}) {
  const { setFieldValue, values, errors, type } = props;
  const today = new Date();
  const isInThesameMonth = () => {
    const daysCount = daysInMonth(today.getFullYear())[today.getMonth()];
    return daysCount > today.getDate() + 29;
  };
  return (
    <div className="flex flex-col gap-6 justify-start items-start w-full">
      <h1 className="font-light text-sm leading-[180%] text-point-secondary-text">
        Monthly Booking (30 Days)
      </h1>
      <div className="w-full flex flex-col justify-start gap-2 ">
        <div
          className={`w-full flex items-center gap-3 ${
            isInThesameMonth() ? "justify-start" : "justify-center"
          }`}
        >
          {type === "meeting room" && <StatusIndicator status="booked" />}
          <StatusIndicator status="selected day" />
          <StatusIndicator status="today" />
        </div>
        <MonthCalendar
          type={type}
          setFieldValue={setFieldValue}
          currentDate={today}
          sameMonth={isInThesameMonth()}
          values={values}
        />
      </div>
    </div>
  );
}

const MonthCalendar: FC<{
  currentDate: Date;
  sameMonth: boolean;
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}> = ({ currentDate, sameMonth, setFieldValue, values, type }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [date, setDate] = useState(currentDate);
  const { data: bookedTimes } = useGetCustomerBookingsQuery(
    {
      package: values?.package,
      space: values?.space,
      available_space: true,
    },
    {
      enabled: Boolean(values?.package) || type === "meeting room",
    }
  );
  useEffect(() => {
    setDate(currentDate);
  }, [currentDate.getDate()]);

  const bookedDays = useMemo(() => {
    if (!bookedTimes) return [];
    if (!values?.space) return [];
    return (
      (values?.space ? bookedTimes : bookedTimes.results) as DatesTypes[]
    ).reduce((acc: Date[], cur) => {
      const { start_date, end_date } = cur;

      const startDate = convertToLocalTime(start_date) as Date;
      const endDate = convertToLocalTime(end_date) as Date;
      const dates = getDaysBetweenInclusive(startDate, endDate);
      return acc.concat(dates);
    }, []);
  }, [bookedTimes]);

  useEffect(() => {
    if (!selectedStartDate) return;
    const endDate = new Date(
      selectedStartDate.getFullYear(),
      selectedStartDate.getMonth(),
      selectedStartDate.getDate() + 29
    );
    if (bookedDays[0]) {
      const daysFromStartToEnd = getDaysBetweenInclusive(
        selectedStartDate,
        endDate
      );
      if (
        daysFromStartToEnd.find((day) =>
          bookedDays.find(
            (bookedDay) => getDateString(day) === getDateString(bookedDay)
          )
        )
      ) {
        ToastNotify("info", "You can not book a date between booked dates");
        return;
      }
    }
    setSelectedEndDate(endDate);

    setFieldValue?.("start_date", selectedStartDate.toISOString());
    setFieldValue?.("end_date", endDate.toISOString());
    setFieldValue?.("num_of_packages", 1);
  }, [selectedStartDate, bookedDays]);

  useEffect(() => {
    if (!values || !(values?.start_date && values?.end_date)) {
      setFieldValue?.("num_of_packages", "");
      setFieldValue?.("start_date", "");
      setFieldValue?.("end_date", "");
      return;
    }
    const { start_date, end_date } = values;
    const startDate = convertToLocalTime(start_date) as Date;
    const endDate = convertToLocalTime(end_date) as Date;
    console.log(startDate.toISOString(), endDate.toISOString(), "HERE WE ARE");
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setDate(startDate);
  }, [values?.start_date, values?.end_date]);

  const calendarRef = useRef<HTMLDivElement | null>(null);

  useClickOutside([calendarRef], () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  });

  console.log(bookedDays, "BOOKED TIMES");
  return (
    <div className="flex w-full justify-between items-start" ref={calendarRef}>
      <SingleMonth
        disabled={false}
        date={date}
        setDate={setDate}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        selectedEndDate={selectedEndDate}
        bookedDays={bookedDays}
      />
      {(!sameMonth || selectedStartDate) && (
        <SingleMonth
          disabled={true}
          date={
            new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
          }
          setDate={setDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedEndDate={selectedEndDate}
          bookedDays={bookedDays}
        />
      )}
    </div>
  );
};

export default MonthViewOfPackage;
