import auditIcon from "./menubar/audit.svg";
import auditSelectedIcon from "./menubar/audit_selected.svg";
import dashboardIcon from "./menubar/dashboard.svg";
import dashboardSelectedIcon from "./menubar/dashboard_selected.svg";
import customerIcon from "./menubar/customer.svg";
import customerSelectedIcon from "./menubar/customer_selected.svg";
import spaceIcon from "./menubar/space.svg";
import spaceSelectedIcon from "./menubar/space_selected.svg";
import packageIcon from "./menubar/package.svg";
import packageSelectedIcon from "./menubar/package_selected.svg";
import reportIcon from "./menubar/report.svg";
import reportSelectedIcon from "./menubar/report_selected.svg";
import settingIcon from "./menubar/setting.svg";
import notificationIcon from "./menubar/notification.svg";
import logoutIcon from "./menubar/logout.svg";
import messagesIcon from "./menubar/messages.svg";

import dropdownIcon from "./utility/dropdown.svg";
import dropdownBlackIcon from "./utility/dropdown_black.svg";
import cancelIcon from "./utility/cancel.svg";
import editIcon from "./utility/edit.svg";
import searchIcon from "./utility/search.svg";
import moreHorizIcon from "./utility/more_horizontal.svg";
import filterIcon from "./utility/filter.svg";
import chevronLeftIcon from "./utility/chevron_left.svg";
import chevronRightIcon from "./utility/chevron_right.svg";
import paperExportIcon from "./utility/paper_export.svg";
import sendIcon from "./utility/send.svg";
import starIcon from "./utility/star.svg";
import leftArrowIcon from "./utility/left_arrow.svg";
import rightArrowIcon from "./utility/right_arrow.svg";
import userEditIcon from "./utility/user_edit.svg";
import userDeleteIcon from "./utility/user_delete.svg";
import defaultUserIcon from "./utility/default_user.svg";
import successCirleIcon from "./utility/success_circle.svg";
import dangerIcon from "./utility/danger.svg";
import resetIcon from "./utility/reset.svg";

import eyeClose from "./auth/eye_close.svg";
import eyeOpen from "./auth/eye.svg";
import arrowLeft from "./auth/arrow_left.svg";

import greenCardIcon from "./dashboard/greencard.svg";
import orangeCardIcon from "./dashboard/orangecard.svg";
import purpleCardIcon from "./dashboard/purplecard.svg";
import pinkCardIcon from "./dashboard/pinkcard.svg";
import residentIcon from "./dashboard/resident.svg";
import visitorIcon from "./dashboard/visitor.svg";
import uploadImageIcon from "./dashboard/uploadImage.svg";
import checkMarkIcon from "./dashboard/empty_check.svg";
import checkMarkGreenIcon from "./dashboard/checkmark_green.svg";
import editOutlineIcon from "./dashboard/edit_empty.svg";
import clockoutIcon from "./dashboard/clockout.svg";
import clockoutblackIcon from "./dashboard/clockoutblack.svg";
import onseatgreenIcon from "./dashboard/onseatgreen.svg";
import onseatIcon from "./dashboard/onseat.svg";
import stationIcon from "./dashboard/station.svg";
import codepenIcon from "./dashboard/codepen.svg";
import downloadIcon from "./dashboard/download.svg";
import meetingIcon from "./dashboard/meeting.svg";
import meetingUserIcon from "./dashboard/meeting_user.svg";
import stationUserIcon from "./dashboard/station_user.svg";
import stationUserCheckedIcon from "./dashboard/station_user_checked.svg";
import meetingUserCheckedIcon from "./dashboard/meeting_user_checked.svg";
import circleChecked from "./dashboard/circle_check.svg";
import circleCheckedEmpty from "./dashboard/circle_check_empty.svg";
import spaceEmpty from "./dashboard/space_empty.svg";
import expandIcon from "./dashboard/expand.svg";
import collapseIcon from "./dashboard/collapse.svg";
import broadcastEmpty from "./dashboard/broadcast_empty.svg";
import arrowLeftDashboard from "./dashboard/arrow_left.svg";
import arrowRightDashboard from "./dashboard/arrow_right.svg";
import emptyCalendar from "./dashboard/empty_calender.svg";
import Calendar from "./dashboard/calender.svg";
import emptyListing from "./dashboard/empty_listings.svg";
import listingIcon from "./dashboard/listing.svg";

import listings from "./spaces/listings.svg";
import facilities from "./spaces/facilities.svg";
import close from "./spaces/close.svg";
import closeRed from "./spaces/close_red.svg";
import checked from "./spaces/checked.svg";
import unchecked from "./spaces/unchecked.svg";
import defaultChecked from "./spaces/default_checked.svg";
import defaultUnchecked from "./spaces/default_unchecked.svg";

import platinumIcon from "./packages/platinum.svg";
import cardIcon from "./packages/card.svg";
import flutterIcon from "./packages/flutter.svg";
import cashIcon from "./packages/cash.svg";
import deleteIcon from "./packages/delete.svg";
import editPackageIcon from "./packages/edit.svg";

export const Icons = {
  dashboard: {
    greenCardIcon,
    purpleCardIcon,
    orangeCardIcon,
    pinkCardIcon,
    residentIcon,
    visitorIcon,
    uploadImageIcon,
    checkMarkIcon,
    editOutlineIcon,
    clockoutIcon,
    onseatIcon,
    stationIcon,
    meetingIcon,
    clockoutblackIcon,
    onseatgreenIcon,
    circleChecked,
    circleCheckedEmpty,
    codepenIcon,
    checkMarkGreenIcon,
    downloadIcon,
    meetingUserIcon,
    stationUserIcon,
    meetingUserCheckedIcon,
    stationUserCheckedIcon,
    spaceEmpty,
    broadcastEmpty,
    arrowLeftDashboard,
    arrowRightDashboard,
    expandIcon,
    collapseIcon,
    emptyCalendar,
    emptyListing,
    Calendar,
    listingIcon,
  },
  space: {
    listings,
    facilities,
    closeIcon: close,
    checked,
    unchecked,
    defaultChecked,
    defaultUnchecked,
    closeRed,
  },
  package: {
    platinumIcon,
    cardIcon,
    flutterIcon,
    cashIcon,
    editPackageIcon,
    deleteIcon,
  },
  menubar: {
    notificationIcon,

    auditIcon,
    auditSelectedIcon,
    dashboardIcon,
    dashboardSelectedIcon,
    customerIcon,
    customerSelectedIcon,
    spaceIcon,
    spaceSelectedIcon,
    packageIcon,
    packageSelectedIcon,
    reportIcon,
    reportSelectedIcon,
    settingIcon,
    logoutIcon,
    messagesIcon,
  },
  utility: {
    dropdownIcon,
    dropdownBlackIcon,
    sendIcon,
    cancelIcon,
    editIcon,
    searchIcon,
    moreHorizIcon,
    filterIcon,
    chevronLeftIcon,
    chevronRightIcon,
    paperExportIcon,
    starIcon,
    leftArrowIcon,
    rightArrowIcon,
    userDeleteIcon,
    userEditIcon,
    defaultUserIcon,
    successCirleIcon,
    dangerIcon,
    resetIcon,
  },
  auth: {
    eyeClose,
    eyeOpen,
    arrowLeft,
  },
};
