import { Icons } from "../../assets/icons";
import React, { AllHTMLAttributes } from "react";
import InputWithAdornment from "./InputWithAdornment";

interface ISearch extends AllHTMLAttributes<HTMLInputElement> {}

function SearchInput(props: ISearch) {
  const { height, ...rest } = props;
  return (
    <InputWithAdornment
      left={
        <img
          src={Icons.utility.searchIcon}
          alt="Search field"
          aria-label="search"
        />
      }
      height={height as string}
      className="max-w-[calc(100%_-_24px)]"
      {...rest}
    />
  );
}
SearchInput.defaultProps = {
  height: "38px",
};
export default SearchInput;
