import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import MainLayout from "./layouts/main";
import HashLoader from "react-spinners/HashLoader";
function App() {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    const id = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(id);
    };
  }, []);
  return isLoading ? (
    <div className="w-screen h-screen bg-white flex justify-center items-center">
      <HashLoader color="#1C3C54" loading={isLoading} size={100} />
    </div>
  ) : (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}

export default App;
