import { GET_CUSTOMERS } from "./../../../customers/utils/apis/bookings";
import {
  dashboard,
  frontdesk,
  getBookings,
  getSpaces,
} from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import {
  MutateOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";
import { addDataToQueryString } from "../../../../utils/stringHelpers";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_FRONTDESK = "GET_FRONTDESK";
export const GET_FRONTDESK_CALENDAR = "GET_FRONTDESK_CALENDAR";

export type CalendarQuery = {
  space_type?: string;
  start_date?: string;
  end_date?: string;
  search?: string;
};
export interface INewBooking {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  customer_type: string;
  space: string;
  package: string;
  start_date: string;
  end_date: string;
  num_of_packages: string;
  payment_type: string;
}
const getDashboard = async () => {
  try {
    const { data } = await axiosAction("GET", dashboard, HttpStatusCode.Ok);
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
const getFrontdesk = async () => {
  try {
    const { data } = await axiosAction("GET", frontdesk, HttpStatusCode.Ok);
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

const getFrontdeskCalendar = async (body: CalendarQuery) => {
  const qs = addDataToQueryString("", { ...body });
  try {
    const { data } = await axiosAction(
      "GET",
      "/frontdesk/calendar/" + (qs ? `?${qs}` : ""),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

const verifyPayment = async (bookingId: number) => {
  try {
    const { data } = await axiosAction(
      "PATCH",
      getBookings(`${bookingId}/`),
      HttpStatusCode.Ok,
      {
        paid: true,
        action: "payment validation",
      }
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
const rejectPayment = async (bookingId: number) => {
  try {
    const { data } = await axiosAction(
      "DELETE",
      getBookings(`${bookingId}/`),
      HttpStatusCode.No_Response
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
const verifyRefund = async (bookingId: number) => {
  try {
    const { data } = await axiosAction(
      "PATCH",
      getBookings(`${bookingId}/`),
      HttpStatusCode.Ok,
      {
        refunded: true,
        action: "validate refund",
      }
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

const createBooking = async (body: INewBooking) => {
  try {
    const { data } = await axiosAction(
      "POST",
      frontdesk + "booking/",
      HttpStatusCode.Created,
      body
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
const clockInUser = async (bookingId: string) => {
  try {
    const { data } = await axiosAction(
      "POST",
      frontdesk + "clock/?booking_id=" + bookingId,
      HttpStatusCode.Created
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
export const useGetDashboardQuery = () => {
  return useQuery(GET_DASHBOARD, getDashboard);
};

export const useGetFrontdeskQuery = () => {
  return useQuery(GET_FRONTDESK, getFrontdesk);
};
export const useGetFrontdeskCalendarQuery = (body: CalendarQuery) => {
  return useQuery([GET_FRONTDESK_CALENDAR, body], () =>
    getFrontdeskCalendar(body)
  );
};

export const useCreateBookingMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(createBooking, {
    onSuccess: () => {
      qc.invalidateQueries([GET_CUSTOMERS]);
      qc.invalidateQueries([GET_FRONTDESK]);
      qc.invalidateQueries([GET_DASHBOARD]);
    },
    ...args,
  });
};
export const useClockinBookingMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(clockInUser, {
    onSuccess: () => {
      qc.invalidateQueries([GET_CUSTOMERS]);
      qc.invalidateQueries([GET_FRONTDESK]);
      qc.invalidateQueries([GET_DASHBOARD]);
    },
    ...args,
  });
};

export const useUpdateVerifyPaymentMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(verifyPayment, {
    onSuccess: () => {
      qc.invalidateQueries([GET_CUSTOMERS, { pending: true }]);
    },
    ...args,
  });
};
export const useRejectPaymentMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(rejectPayment, {
    onSuccess: () => {
      qc.invalidateQueries([GET_CUSTOMERS, { pending: true }]);
    },
    ...args,
  });
};
export const useUpdateVerifyRefundMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(verifyRefund, {
    onSuccess: () => {
      qc.invalidateQueries([GET_CUSTOMERS, { refund: true }]);
    },
    ...args,
  });
};
