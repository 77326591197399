import { TOKEN_KEY, CURRENT_USER, HttpStatusCode } from "./constants";
import axios, { AxiosResponse, GenericAbortSignal, Method } from "axios";

import { Storage } from "../store/Storage";
import { baseAPIURL } from "./endpoints";

import { ToastNotify } from "../utils/toastNotify";
import { merge } from "../utils/stringHelpers";

/**
 * Custom axios instance
 * @param {*} method
 * @param {*} url
 * @param {*} body
 * @returns
 */
const getController = () => new AbortController();
export const customAxios = async (
  method: Method,
  url: string,
  clientBody: any,
  extraHeaders = {}
) => {
  let body = clientBody;
  const savedToken =
    typeof window !== "undefined" ? Storage.getItem(TOKEN_KEY) : null;

  const headers = merge(
    {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    extraHeaders
  );

  // add user token to request headers
  if (savedToken && location.pathname.includes("auth") === false) {
    headers.authorization = `Token ${savedToken || ""}`;
  }

  let signal: GenericAbortSignal | undefined;
  if ("abortApi" in headers && typeof headers.abortApi === "function") {
    const controller = getController();
    signal = controller.signal;
    headers.abortApi(controller.abort);
    delete headers.abortApi;
  }
  if (!window.navigator.onLine) {
    ToastNotify("info", "Please Kindly connect to the internet");
    return;
  }
  try {
    const response = await axios.request({
      url,
      method,
      baseURL: baseAPIURL,
      data: body,
      headers,
      signal,
      responseType: "json",
      validateStatus: function (status_1) {
        return status_1 >= 200 && status_1 <= 501; // default
      },
    });

    switch (response.status) {
      case 500:
        console.log("in Here");
        ToastNotify(
          "error",
          `Ooops something not right, please refresh and try angain or contact support.`
        );

        break;
      case 501:
        ToastNotify("error", response?.data?.message);
        break;
      case 401:
        console.log(response);
        // console.clear();
        Storage.removeItem(CURRENT_USER);
        Storage.removeItem(TOKEN_KEY);

        ToastNotify("error", `Unauthourize access, redirecting to login.`);

        setTimeout(() => {
          location.replace(`${location.origin}/auth`);
        }, 500);

        break;
      case 422:
        ToastNotify(
          "error",
          `${
            response.data.errors
              ? Object.entries(response.data.errors).forEach(
                  ([key, value]) => `${key}: ${value}`
                )
              : response.data.message
          }`
        );
        break;
      case 404:
        ToastNotify(
          "error",
          "Url request does not exist, please refresh and try again later."
        );
        break;
      case 400:
        ToastNotify("error", response?.data?.message);
        break;
      default:
        return response;
    }
  } catch (error) {
    console.log(error);

    if (axios.isCancel(error)) {
      const message = error?.message;

      return {
        data: { data: null, message, status: false },
      } as AxiosResponse;
    }
    ToastNotify(
      "error",
      "A unexpected error has occured, please check network connection, refresh and try again"
    );
  }
};

export const axiosAction = async (
  method: Method = "get",
  path = "",
  code?: HttpStatusCode,
  body?: any,
  extraHeaders?: Record<string, any>
) => {
  try {
    const res = await customAxios(method, path, body, extraHeaders);
    if (res?.status === code) {
      return {
        status: true,
        data: res?.data?.data,
        message: res?.data?.message,
      };
    } else {
      return {
        status: false,
        message:
          res?.data?.message ||
          "An error occured, please refresh and try again later.",
      };
    }
  } catch (error: any) {
    return (
      error?.response?.data || {
        status: false,
        message:
          error.message ||
          "An error occured, please refresh and try again later.",
      }
    );
  }
};
