import { Icons } from "../../../assets/icons";
import React, { FC, HTMLAttributes, startTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/images/defaultImage.png";

type User = {
  image?: string;
  fullName: string;
  type: "visitor" | "resident";
};
interface IBookVerify extends HTMLAttributes<HTMLDivElement> {
  count: number;
  users: Array<User>;
}

function BookingVerification(props: IBookVerify) {
  const { count, users = [], className, ...rest } = props;
  const navigate = useNavigate();
  return (
    <section
      {...rest}
      className={`${className} w-full bg-white rounded-lg border border-[#d9d9d9] gap-4 py-5 px-8 flex flex-col justify-start items-start h-[184px]`}
    >
      <div className="w-full flex justify-between items-center">
        <h4 className="text-point-primary-text font-medium text-[22px] leading-[140%]">
          Booking Verification
        </h4>
        <button
          onClick={() =>
            startTransition(() =>
              navigate("/app/dashboard/pending-verification")
            )
          }
          className="text-sm text-point-tertiary-bg font-medium leading-[160%] text-right"
        >
          View All({count})
        </button>
      </div>
      <div className="w-full flex justify-between  items-center">
        {users.slice(0, 3).map((user, id) => (
          <UserRepresentation key={id} user={user} />
        ))}
      </div>
    </section>
  );
}

const imageMap = {
  resident: Icons.dashboard.residentIcon,
  visitor: Icons.dashboard.visitorIcon,
};
const UserRepresentation: FC<{ user: User }> = ({ user }) => {
  const { image, fullName, type } = user;
  console.log(image, "IMAGE");
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <img
        src={image || defaultImage}
        alt={`image of ${fullName}`}
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
        className="w-10 h-10 object-fill object-center rounded-full"
      />
      <h5 className="font-medium text-sm leading-[17px] capitalize">
        {fullName}
      </h5>
      <div className="flex items-center gap-1 justify-center">
        <img
          src={imageMap[type]}
          alt={`representation of ${type}`}
          width={10}
          height={10}
        />
        <strong
          className={`uppercase text-[10px] leading-[160%] font-medium ${
            type === "visitor"
              ? "text-point-secondary-bg"
              : "text-point-tertiary-bg"
          }`}
          style={{ letterSpacing: "0.08em" }}
        >
          {type}
        </strong>
      </div>
    </div>
  );
};
export default BookingVerification;
