import InputWithAdornment from "../../../../components/Input/InputWithAdornment";
import FormGroup from "../../../auth/components/FormGroup";
import React, { FC } from "react";
import SelectInput from "../../../../components/Input/SelectInput";
import { FormikHandlers } from "formik";

const ContactInformation: FC<{
  handleChange?: (e: React.ChangeEvent<any>) => void;
  handleBlur?: (e: React.FocusEvent<any, Element>) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  touched?: Record<string, boolean>;
}> = ({ handleBlur, handleChange, values, errors, touched }) => {
  return (
    <section role="tabpanel" className="flex flex-col gap-6 w-[90%]">
      <h1 className="font-medium text-[22px] leading-[140%]">
        Customer Information
      </h1>
      <div className="w-full grid grid-cols-12 gap-6 gap-y-3">
        <div className="col-span-6">
          <FormGroup id="firstName" label="First Name">
            <InputWithAdornment
              name="first_name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.first_name && touched?.first_name && errors.first_name
              }
              value={values?.first_name}
            />
          </FormGroup>
        </div>
        <div className="col-span-6">
          <FormGroup id="lastName" label="Last Name">
            <InputWithAdornment
              name="last_name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.last_name && touched?.last_name && errors.last_name
              }
              value={values?.last_name}
            />
          </FormGroup>
        </div>
        <div className="col-span-6">
          <FormGroup id="customerType" label="Customer Type">
            <SelectInput
              className="w-full bg-point-input-bg px-4 py-3 rounded h-12"
              menuClassName="w-[280px]"
              options={[
                { label: "Resident", value: "resident" },
                { label: "Visitor", value: "visitor" },
              ]}
              optionsText={(item) => item.label}
              optionsValue={(item) => item.value}
              name="customer_type"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.customer_type &&
                touched?.customer_type &&
                errors.customer_type
              }
              value={values?.customer_type}
            />
          </FormGroup>
        </div>
        <div className="col-span-6">
          <FormGroup id="email" label="Email">
            <InputWithAdornment
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors?.email && touched?.email && errors.email}
              value={values?.email}
            />
          </FormGroup>
        </div>
        <div className="col-span-6">
          <FormGroup id="phoneNo" label="Phone No">
            <InputWithAdornment
              name="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors?.phone_number &&
                touched?.phone_number &&
                errors.phone_number
              }
              value={values?.phone_number}
            />
          </FormGroup>
        </div>
      </div>
    </section>
  );
};

export default ContactInformation;
