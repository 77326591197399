import { useGetSpacesQuery } from "../../../../pages/spaces/utils/apis/getSpaces";
import React, { FC, useEffect, useState } from "react";

const SelectMeetingRoom = (props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
}) => {
  const { setFieldValue, values } = props;
  const { data: allMeetingRoom } = useGetSpacesQuery(
    "",
    "",
    1,
    "meeting room",
    true
  );
  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState<string>(
    values?.space || ""
  );
  useEffect(() => {
    setFieldValue?.("space", selectedMeetingRoom);
  }, [selectedMeetingRoom]);

  return (
    <div className="pt-6 bg-white mb-16 w-full flex flex-col gap-7">
      <h2 className="text-point-primary-text font-medium leading-[140%] text-[22px]">
        Meeting Room
      </h2>
      <div
        role="radiogroup"
        className="w-full grid grid-cols-2 gap-x-10 gap-y-8 py-4 px-6 h-full bg-point-input-bg"
      >
        {(allMeetingRoom?.results || []).map((el: any) => {
          return (
            <MeetingRoomCard
              key={el.space_id}
              meeting={el}
              checked={el.id === selectedMeetingRoom}
              onClick={() => setSelectedMeetingRoom(el.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

const MeetingRoomCard: FC<{
  checked?: boolean;
  onClick?: () => void;
  meeting: any;
}> = ({ checked, onClick, meeting }) => {
  return (
    <div
      onClick={onClick}
      className={`col-span-1 flex flex-col justify-start items-start transition-colors cursor-pointer rounded-lg hover:shadow-md border ${
        checked ? "shadow-sm border-point-secondary-bg" : "border-transparent"
      }`}
      role="radio"
      aria-checked={checked}
    >
      <img
        className="rounded-tl-lg rounded-tr-lg object-cover object-center w-full h-[115px] self-stretch"
        src={meeting.images?.find?.((image: any) => image.default)?.image}
        alt="representation of meeting room"
      />
      <div className="flex flex-col py-3 px-6 gap-1 bg-white w-full rounded-bl-lg rounded-br-lg">
        <h4 className="text-point-primary-text font-medium text-base leading-[160%]">
          {meeting.name}
        </h4>
        <p className="font-medium tracking-[0.08em] uppercase text-xs leading-[160%] text-point-secondary-text">
          ID: {meeting.space_id}
        </p>
      </div>
    </div>
  );
};

export default SelectMeetingRoom;
