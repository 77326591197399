import React, { AllHTMLAttributes } from "react";

interface IRadio extends AllHTMLAttributes<HTMLInputElement> {
  label?: string;
  orientation?: "horizontal" | "vertical";
}
function RadioButton(props: IRadio) {
  const { label, id, orientation = "horizontal", ...rest } = props;
  return (
    <div
      className={`flex text-[#666666] cursor-pointer text-base leading-[180%] font-medium hover:text-point-primary-text disabled:cursor-not-allowed
    ${
      orientation === "horizontal"
        ? "flex-row justify-start gap-2 items-center"
        : "flex-col justify-start items-start"
    } `}
    >
      <input
        type="radio"
        id={id}
        {...rest}
        className=" peer disabled:cursor-not-allowed disabled:border-2 disabled:border-point-tertiary-text disabled:bg-point-blue disabled:hover:after:bg-point-blue disabled:checked:after:bg-point-tertiary-text disabled:checked:hover:bg-point-blue disabled:checked:hover:after:bg-point-secondary-text
        appearance-none m-0 accent-point-blue flex justify-center items-center checked:after:bg-point-blue checked:hover:bg-white checked:hover:border-2 checked:hover:border-point-bg-blue checked:hover:after:bg-point-blue
        w-6 h-6 border-2 rounded-full border-point-blue after:block after:rounded-full after:w-3 after:h-3 after:m-[3px]"
      />
      <label htmlFor={id} className="peer-checked:text-point-primary-text">
        {label}
      </label>
    </div>
  );
}

RadioButton.defaultProps = {
  orientation: "horizontal",
};
export default RadioButton;
