import React, { AllHTMLAttributes, FC } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";

import styles from "./breadcrumbs.module.css";

import Breadcrumbs from "./Breadcrumbs";
import { Icons } from "../../assets/icons";
/**
 *
 * @param {object} nameMap The Route to the display name map
 * @returns
 */
interface ICrumbs extends AllHTMLAttributes<HTMLElement> {
  separator?: React.ReactNode;
  maxItems?: number;
  nameMap: Record<string, string>;
  ignoreFirst?: boolean;
}

const RouteAwareCrumbs: FC<ICrumbs> = ({
  maxItems,
  separator = "/",
  nameMap,
  ignoreFirst,
  style,
}) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const navigate = useNavigate();
  return (
    <Breadcrumbs
      maxItems={maxItems}
      separator={separator}
      className={styles.Route}
      ignoreFirst={ignoreFirst as boolean}
    >
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const first = index === 0;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        return last ? (
          <p style={style} key={to}>
            {nameMap[to]}
          </p>
        ) : first ? (
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(ignoreFirst ? "" : to, {
                state: {
                  from: pathnames[pathnames.length - 1],
                },
              });
            }}
            style={{ ...style }}
            className="hover:text-point-primary-text flex items-center justify-start gap-2 cursor-pointer"
            color="inherit"
            key={to}
            role="link"
          >
            <img src={Icons.auth.arrowLeft} alt="back image" />
            <span>{nameMap[to]}</span>
          </a>
        ) : (
          <a
            onClick={(e) => {
              e.preventDefault();
              navigate(to, {
                state: {
                  from: pathnames[pathnames.length - 1],
                },
              });
            }}
            style={{ ...style }}
            className="hover:text-point-primary-text cursor-pointer"
            color="inherit"
            key={to}
          >
            {nameMap[to]}
          </a>
        );
      })}
    </Breadcrumbs>
  );
};

export default RouteAwareCrumbs;
