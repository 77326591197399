import PackageRepresentation from "../../../packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../packages/utils";
import React, { FC, useEffect, useState } from "react";
import FormGroup from "../../../auth/components/FormGroup";
import InputWithAdornment from "../../../../components/Input/InputWithAdornment";
import { useGetPackagesQuery } from "../../../packages/utils/apis/getPackages";
import MonthViewOfPackage from "./MonthViewOfPackage";
import DailyViewOfPackage from "./DailyViewOfPackage";
import HourlyViewOfPackage from "./HourlyViewOfPackage";
import { useGetCustomerBookingsQuery } from "../../../../pages/customers/utils/apis/bookings";

function SelectPackageType(props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}) {
  const { values, errors, setFieldValue, type } = props;
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const { data: allPackages } = useGetPackagesQuery({
    page: 1,
    space_type: type,
    published: true,
  });

  useEffect(() => {
    if (!values?.package || !allPackages) return;
    const packageSelected = allPackages.results.find(
      (pac: any) => pac.id == values.package
    );
    setSelectedPackage(packageSelected);
  }, [values?.package, allPackages]);

  return (
    <section role="tabpanel" className="flex flex-col gap-6 w-full">
      <h1 className="font-medium text-[22px] leading-[140%]">
        Select a Workstation Package
      </h1>
      <div
        className="w-full flex justify-start gap-2 items-start content-start flex-wrap"
        role="radiogroup"
      >
        {allPackages?.results?.map((pack: any) => (
          <PackageCard
            key={pack.name}
            name={pack.name}
            color={pack.color_code}
            checked={pack.id == values?.package}
            onClick={() => {
              setSelectedPackage(pack);
              setFieldValue?.("package", pack.id);
              setFieldValue?.("start_date", "");
              setFieldValue?.("end_date", "");
              setFieldValue?.("num_of_packages", "");
            }}
          />
        ))}
      </div>
      <div className="flex flex-col w-full justify-start items-start gap-4">
        {selectedPackage && selectedPackage.package_type === "month" && (
          <MonthViewOfPackage
            type={type}
            setFieldValue={setFieldValue}
            values={values}
          />
        )}
        {selectedPackage && selectedPackage.package_type === "day" && (
          <DailyViewOfPackage
            type={type}
            setFieldValue={setFieldValue}
            values={values}
          />
        )}
        {selectedPackage && selectedPackage.package_type === "hour" && (
          <HourlyViewOfPackage
            type={type}
            setFieldValue={setFieldValue}
            values={values}
          />
        )}
        <div className="w-[70%]">
          <FormGroup id="total" label="Total Packages">
            <InputWithAdornment
              readOnly
              name="num_of_packages"
              error={errors?.num_of_packages}
              value={values?.num_of_packages}
            />
          </FormGroup>
        </div>
      </div>
    </section>
  );
}

const PackageCard: FC<{
  name: string;
  color: string;
  checked: boolean;
  onClick: () => void;
}> = ({ name, color, checked, onClick }) => {
  return (
    <div
      onClick={onClick}
      aria-checked={checked}
      role="radio"
      style={
        checked
          ? {
              background: `linear-gradient(#fff, #fff) padding-box, linear-gradient(45deg,  ${color}, ${getOtherColorVariant(
                color || ""
              )}) border-box`,
              border: "1px solid transparent",
              boxShadow: "0px 8px 24px rgba(13, 41, 63, 0.1)",
            }
          : {}
      }
      className={`flex justify-start items-center rounded p-2 gap-2 hover:shadow-md cursor-pointer
       border  text-[#d9d9d9] ${
         checked
           ? `bg-white text-[${color}]`
           : "bg-[#f7f7f7] border-[#f7f7f7] text-[#d9d9d9]"
       } `}
    >
      <PackageRepresentation
        color={color}
        otherVariant={getOtherColorVariant(color)}
      />
      <h6 className="capitalize    text-sm leading-[180%] font-medium">
        {name}
      </h6>
    </div>
  );
};

export default SelectPackageType;
