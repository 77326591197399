import { Icons } from "../../assets/icons";
import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from "react";
import { usePagination } from "./usePagination";
interface IDirection extends HTMLAttributes<HTMLButtonElement> {
  label: "next" | "prev";
  color?: string;
  disabled: boolean;
}

interface IPage extends HTMLAttributes<HTMLButtonElement> {
  label?: string;
  active: boolean;
  color?: string;
}
interface IPagination {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  activeColor?: string;
  onPageChange?: (page: number) => void;
}
function Pagination(props: IPagination) {
  const { totalCount, pageSize, currentPage, activeColor, onPageChange } =
    props;
  const numberOfButtonsArr = new Array(Math.ceil(totalCount / pageSize)).fill(
    0
  );

  const [activePage, setActivePage] = useState(currentPage);

  const paginationRange = usePagination({
    currentPage,
    pageSize,
    siblingCount: 1,
    totalCount,
  });
  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);
  const onSelectPage = useCallback(
    (ind: number) => {
      setActivePage(ind);
      onPageChange?.(ind);
    },
    [onPageChange]
  );

  const changeDirection = useCallback(
    (dir: "next" | "prev") => {
      if (dir === "next" && currentPage <= numberOfButtonsArr.length) {
        setActivePage((prev) => {
          onPageChange?.(prev + 1);
          return prev + 1;
        });
      }
      if (dir === "prev" && currentPage >= 1) {
        setActivePage((prev) => {
          onPageChange?.(prev - 1);
          return prev - 1;
        });
      }
    },
    [onPageChange]
  );
  if (
    currentPage === 0 ||
    !paginationRange?.length ||
    paginationRange.length < 2
  ) {
    return null;
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="w-full flex justify-center items-center gap-3">
      {paginationRange.length > 3 && (
        <DirectionButton
          label="prev"
          onClick={() => changeDirection("prev")}
          disabled={!paginationRange[0] || activePage === 1}
        />
      )}
      {paginationRange.map((pageNumber, i) => {
        if (pageNumber === "...") {
          return (
            <img
              key={i + pageNumber}
              src={Icons.utility.moreHorizIcon}
              alt="more"
            />
          );
        }

        // Render our Page Pills
        return (
          <PageButton
            key={pageNumber}
            active={pageNumber === activePage}
            onClick={() => onSelectPage(pageNumber as number)}
            label={pageNumber.toString()}
            color={activeColor || "var(--secondary-bg)"}
          />
        );
      })}
      {paginationRange.length > 3 && (
        <DirectionButton
          label="next"
          onClick={() => changeDirection("next")}
          disabled={!lastPage || activePage === numberOfButtonsArr.length}
        />
      )}
    </div>
  );
}

const PageButton: FC<IPage> = ({ label, active, color, ...rest }) => {
  return (
    <button
      style={{
        color: active ? "#FFFFFF" : color || "var(--secondary-bg)",
        borderColor: color,
        backgroundColor: active ? color || "var(--secondary-bg)" : "#FFFFFF",
      }}
      className="flex justify-center items-center border border-point-blue rounded p-1.5 gap-2 w-9 h-10"
      {...rest}
    >
      {label}
    </button>
  );
};

const DirectionButton: FC<IDirection> = ({
  label,
  disabled,
  color,
  ...rest
}) => {
  return (
    <button
      disabled={disabled}
      {...rest}
      className="capitalize disabled:bg-point-accent-boder flex items-center justify-center gap-1 font-medium text-sm leading-6 text-point-blue rounded bg-white border border-point-blue h-10"
      style={{ color, borderColor: color, padding: "8px 10px 8px 14px" }}
    >
      {label === "prev" && (
        <img src={Icons.utility.chevronLeftIcon} alt={label} />
      )}
      <span>{label}</span>
      {label === "next" && (
        <img src={Icons.utility.chevronRightIcon} alt={label} />
      )}
    </button>
  );
};
export default Pagination;
