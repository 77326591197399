import React from "react";

type Props = {
  label?: React.ReactNode;
  id: string;
  subtext?: string;
  children: any;
};
function FormGroup({ children, label, id, subtext }: Props) {
  return (
    <div className="focus-within:text-point-action-text flex flex-col text-point-label-text active:text-point-action-text gap-2 w-full">
      <div className="w-full flex justify-between items-center">
        <label htmlFor={id}>{label}</label>
        <span className="text-xs font-light leading-5">{subtext}</span>
      </div>
      {React.cloneElement(children, {
        id,
      })}
    </div>
  );
}

export default FormGroup;
