import { Icons } from "../../../../assets/icons";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  convertToLocalTime,
  days,
  getDateString,
  getDaysBetweenInclusive,
  getHoursBetweenInclusive,
  getYearsFrom,
  hours,
  isNextMonth,
  isPrevDays,
  isPrevMonth,
  isThatDay,
  isToday,
  months,
} from "../../../../utils/dateHelper";
import StatusIndicator from "./StatusIndicator";
import { ToastNotify } from "../../../../utils/toastNotify";
import { useClickOutside } from "../../../../hooks/useOutsideClick";
import { useGetCustomerBookingsQuery } from "../../../../pages/customers/utils/apis/bookings";
import { DatesTypes } from "../../../../pages/dashboard/utils/apis/getOccupiedSpaces";
import useDisplayDays from "../../../../pages/dashboard/hooks/useDisplayDays";

const HourlyViewOfPackage = (props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedHours, setSelectedHours] = useState<number[]>([]);
  const { setFieldValue, values, type } = props;

  useEffect(() => {
    const [first, second] = selectedHours;
    console.log(first, second, "Days");
    if (first !== undefined && second === undefined) {
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        first,
        0,
        0
      );
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        first,
        59,
        0
      );
      const numOfPac = 1;
      setFieldValue?.("start_date", startDate.toISOString());
      setFieldValue?.("end_date", endDate.toISOString());
      setFieldValue?.("num_of_packages", numOfPac);
      return;
    }

    if (first === undefined || second === undefined) return;
    const startDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      first,
      0,
      0
    );
    const endDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      second,
      59,
      0
    );
    const numOfPac = second - first + 1;
    setFieldValue?.("start_date", startDate.toISOString());
    setFieldValue?.("end_date", endDate.toISOString());
    setFieldValue?.("num_of_packages", numOfPac);
  }, [selectedHours, currentDate]);

  useEffect(() => {
    if (!values || !(values?.start_date && values?.end_date)) {
      setFieldValue?.("num_of_packages", "");
      setFieldValue?.("start_date", "");
      setFieldValue?.("end_date", "");
      return;
    }
    const { start_date, end_date } = values;
    const startDate = convertToLocalTime(start_date) as Date;
    const endDate = convertToLocalTime(end_date) as Date;
    setSelectedHours([startDate.getHours(), endDate.getHours()]);
    setCurrentDate(startDate);
  }, [values?.start_date, values?.end_date]);
  // useEffect(() => {
  //   const [first, second] = selectedHours;
  //   let isMounted = true;
  //   if (first === undefined && second === undefined && !isMounted) {
  //     setFieldValue?.("start_date", "");
  //     setFieldValue?.("end_date", "");
  //     setFieldValue?.("num_of_packages", "  ");
  //     return;
  //   }

  //   return () => {
  //     isMounted = true;
  //   };
  // }, [selectedHours]);

  const { data: bookedTimes } = useGetCustomerBookingsQuery(
    {
      package: values?.package,
      space: values?.space,
      available_space: true,
    },
    {
      enabled: Boolean(values?.package) || type === "meeting room",
    }
  );
  const bookedDays = useMemo(() => {
    if (!bookedTimes || !values?.space) return [];

    return (
      ((values?.space ? bookedTimes : bookedTimes?.results) ||
        []) as DatesTypes[]
    ).reduce((acc: Date[], cur) => {
      const { start_date, end_date } = cur;

      const startDate = convertToLocalTime(start_date) as Date;
      const endDate = convertToLocalTime(end_date) as Date;
      const dates = getHoursBetweenInclusive(startDate, endDate);
      return acc.concat(dates);
    }, []);
  }, [bookedTimes]);
  return (
    <div className="flex flex-col gap-6 justify-start items-start w-full">
      <h1 className="font-light text-sm leading-[180%] text-point-secondary-text">
        Hourly Booking (1 Hour)
      </h1>
      <div className="w-full flex flex-col justify-start gap-2 ">
        <div
          className={`w-full flex items-center gap-3 
           justify-start`}
        >
          {type === "meeting room" && <StatusIndicator status="booked" />}
          <StatusIndicator status="selected hour" />
        </div>
        <SingleDay
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedHours={selectedHours}
          setSelectedHours={setSelectedHours}
          type={type}
          bookedDays={bookedDays}
        />
      </div>
    </div>
  );
};

const isInBetweenInclive = (
  num: number,
  startNumber: number,
  endNumber: number
) => {
  return num >= startNumber && num <= endNumber;
};

const getHoursFromString = (hourStr: string) => {
  const [hour, remaining] = hourStr.split(":");
  const meridian = remaining.split("00")[1];

  if (meridian === "am" || parseInt(hour) == 12) return parseInt(hour);
  return parseInt(hour) + 12;
};
const SingleDay: FC<{
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  disabled?: boolean;
  selectedHours: number[];
  setSelectedHours: React.Dispatch<React.SetStateAction<number[]>>;
  type: "meeting room" | "work station";
  bookedDays?: Date[];
}> = (props) => {
  const {
    currentDate,
    setCurrentDate,
    disabled,
    selectedHours,
    setSelectedHours,
    type,
    bookedDays,
  } = props;
  const [showMonthlyDate, setShowMonthlyDate] = useState(false);

  const toggleView = useCallback((e: any) => {
    e.stopPropagation?.();
    setShowMonthlyDate((prev) => !prev);
  }, []);

  return (
    <div className="p-5 flex flex-col justify-start items-start gap-4 w-full">
      <SingleDayHeader
        setCurrentDate={setCurrentDate}
        toggleView={toggleView}
        currentDate={currentDate}
        disabled={disabled}
      />
      {showMonthlyDate ? (
        <SelectDate
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          onClose={() => {
            setShowMonthlyDate(false);
            setSelectedHours([]);
          }}
        />
      ) : (
        <SelectTime
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          selectedHours={selectedHours}
          setSelectedHours={setSelectedHours}
          type={type}
          bookedDays={bookedDays}
        />
      )}
    </div>
  );
};

const SingleDayHeader: FC<{
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  disabled?: boolean;

  bookedDays?: Date[];
  toggleView: (e: any) => void;
}> = (props) => {
  const { setCurrentDate, disabled, currentDate, toggleView } = props;
  const goToNextDay = () => {
    if (disabled) return;
    setCurrentDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          prev.getMonth(),
          prev.getDate() + 1,
          prev.getHours()
        )
    );
  };
  const goToPrevDay = () => {
    if (disabled) return;
    setCurrentDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          prev.getMonth(),
          prev.getDate() - 1,
          prev.getHours()
        )
    );
  };
  return (
    <div className="w-full flex justify-between items-center">
      <button onClick={goToPrevDay} type="button">
        <img
          src={Icons.dashboard.arrowLeftDashboard}
          alt="Move to previous month"
        />
      </button>
      <button
        type="button"
        onClick={toggleView}
        className="text-sm px-3.5 rounded-md py-1 font-medium leading-[160%] text-point-primary-text hover:bg-point-input-bg hover:text-point-tertiary-bg"
      >
        {days[currentDate.getDay()]}
        {"  "}
        {currentDate.getDate()}
        {"  "}
        {months[currentDate.getMonth()]}
        {",  "}
        {currentDate.getFullYear()}
      </button>
      <button onClick={goToNextDay} type="button">
        <img
          src={Icons.dashboard.arrowRightDashboard}
          alt="Move to next month"
        />
      </button>
    </div>
  );
};
const SelectTime: FC<{
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  disabled?: boolean;
  selectedHours: number[];
  setSelectedHours: React.Dispatch<React.SetStateAction<number[]>>;
  type: "meeting room" | "work station";
  bookedDays?: Date[];
}> = (props) => {
  const { currentDate, selectedHours, setSelectedHours, type, bookedDays } =
    props;
  const bookedHours: number[] = useMemo(() => {
    return type === "work station" || !bookedDays?.[0]
      ? []
      : (() => {
          const isInday = bookedDays.find(
            (bookedDay) =>
              getDateString(bookedDay) === getDateString(currentDate)
          );
          if (!isInday) return [];
          return bookedDays.map((bookedDay) => bookedDay.getHours());
        })();
  }, [currentDate, type, bookedDays]);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside([ref], () => {
    setSelectedHours([]);
  });

  const clickHandler = (hour: number) => {
    setSelectedHours((prev) => {
      let newState = [...prev];

      const [first, second] = newState;
      if (first !== undefined && second === undefined) {
        if (
          bookedHours.find((bookedHour) =>
            isInBetweenInclive(
              bookedHour,
              first < hour ? first : hour,
              first < hour ? hour : first
            )
          )
        ) {
          ToastNotify(
            "info",
            "Cannot make a booking between days that are already booked"
          );
          return newState;
        }
        newState.push(hour);
      } else if (first !== undefined && second !== undefined) {
        if (
          bookedHours.find((bookedHour) =>
            isInBetweenInclive(
              bookedHour,
              first < hour ? first : hour,
              first < hour ? hour : first
            )
          )
        ) {
          ToastNotify(
            "info",
            "Cannot make a booking between days that are already booked"
          );
          return newState;
        }
        if (isInBetweenInclive(hour, first, second)) {
          newState = [];
        } else {
          if (hour < first) {
            newState[0] = hour;
          } else {
            newState[1] = hour;
          }
        }
      } else {
        newState.push(hour);
      }

      newState.sort((a, b) => (a > b ? 1 : -1));

      return newState;
    });
  };
  const usedHours = hours.slice(7, 22);
  return (
    <main
      id="mainbody"
      role="grid"
      className="w-full grid grid-cols-5 gap-x-2 gap-y-3"
      ref={ref}
    >
      {usedHours.map((hour, ind) => {
        const [first, second] = selectedHours;

        const selected =
          first !== undefined && second !== undefined
            ? isInBetweenInclive(getHoursFromString(hour), first, second)
            : selectedHours.includes(getHoursFromString(hour));
        return (
          <button
            type="button"
            disabled={Boolean(
              bookedHours.find((booked) => booked === getHoursFromString(hour))
            )}
            onClick={() => {
              if (
                (isToday(currentDate) &&
                  new Date().getHours() >= getHoursFromString(hour)) ||
                isPrevDays(currentDate)
              ) {
                ToastNotify(
                  "info",
                  "Invalid selection, You can't book this time"
                );
                return;
              }

              clickHandler(getHoursFromString(hour));
            }}
            key={hour}
            className={`col-span-1  flex justify-center items-center whitespace-nowrap p-2 rounded h-12 gap-2 text-sm font-light leading-[180%]
              ${
                selected
                  ? "font-bold text-white bg-point-secondary-bg"
                  : " text-point-secondary-text font-medium"
              }
              ${
                (isToday(currentDate) &&
                  new Date().getHours() >= getHoursFromString(hour)) ||
                isPrevDays(currentDate)
                  ? "border-none bg-point-input-bg"
                  : "border border-[#D9D9D9] bg-white"
              }
                  disabled:font-light disabled:text-point-pink-dark disabled:opacity-50`}
          >
            {hour}
          </button>
        );
      })}
    </main>
  );
};

const SelectDate: FC<{
  currentDate: Date;
  setCurrentDate: React.Dispatch<React.SetStateAction<Date>>;
  onClose: () => void;
}> = (props) => {
  const {
    setCurrentDate: setDate,

    currentDate,
    onClose,
  } = props;
  const today = new Date();
  const [dateParts, setDateParts] = useState<[number, number]>([
    currentDate.getFullYear(),
    currentDate.getMonth(),
  ]);
  const viewRef = useRef<HTMLDivElement>(null);
  const years = useMemo(() => {
    const oldYears = getYearsFrom(1998);
    for (let i = today.getFullYear() + 1; i < today.getFullYear() + 20; i++) {
      oldYears.unshift(i);
    }
    return oldYears;
  }, []);

  useEffect(() => {
    viewRef.current?.scrollTo({ top: 200 * 7 });
  }, [years]);
  const selectYearAndMonth = (year: number, monthIdx: number) => {
    setDateParts([year, monthIdx]);
  };
  const selectDate = (day: number) => {
    setDate(
      new Date(
        dateParts?.[0] || today.getFullYear(),
        dateParts?.[1] || today.getMonth(),
        day
      )
    );
    onClose();
  };
  const date = new Date(
    dateParts?.[0] || today.getFullYear(),
    dateParts?.[1] || today.getMonth(),
    1
  );
  const displayableDays = useDisplayDays(date);
  return (
    <div className="w-full grid grid-cols-2 h-full">
      <div
        ref={viewRef}
        className="w-full col-span-1 flex flex-col gap-3 h-[200px] scroller overflow-auto"
      >
        {years.map((year) => {
          const isYearselected = dateParts?.[0] === year;
          return (
            <div
              key={year}
              className={`w-full flex pl-2 justify-start items-center gap-1.5 border rounded-md ${
                isYearselected ? "border-point-blue" : "border-transparent"
              }`}
            >
              <h5 className="text-sm">{year}</h5>
              <div className="grid gap-x-1 gap-y-0.5 flex-1 grid-cols-4">
                {months.map((month, idx) => (
                  <button
                    type="button"
                    onClick={() => selectYearAndMonth(year, idx)}
                    className={`col-span-1 text-[13px] text-point-blue  hover:bg-point-input-bg ${
                      isYearselected && idx === dateParts?.[1]
                        ? "bg-point-input-bg"
                        : "bg-transparent"
                    }`}
                    key={month + year}
                  >
                    {month.substring(0, 3)}
                  </button>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className={`col-span-1 ${dateParts?.length === 2 ? "flex" : "hidden"}`}
      >
        <div
          role="group"
          className="w-full grid grid-cols-7 grid-rows-5 grid-flow-row gap-x-0 gap-y-1"
        >
          {displayableDays.map((day: Date, ind: number) => {
            const isSelected = isThatDay(currentDate, day);
            return (
              <button
                type="button"
                onClick={(e) => {
                  selectDate(day.getDate());
                }}
                key={getDateString(day)}
                className={`flex col-span-1 flex-col justify-center items-center 
                    h-8 rounded-sm 
                font-medium text-xs leading-[160%] px-2 py-3
                  ${isSelected ? "bg-[#1C3C54] !rounded-full text-white" : ""}
                    hover:bg-[#1C3C54] hover:rounded-2xl hover:text-white 
                    hover:disabled:rounded-none hover:disabled:bg-white hover:disabled:text-point-primary-text
                    disabled:hover:text-point-secondary-text disabled:hover:cursor-text
                    ${
                      isPrevMonth(date, day) || isNextMonth(date, day)
                        ? "hover:!bg-white hover:rounded-none !text-point-secondary-text font-light !bg-white rounded-none"
                        : ""
                    }
                `}
              >
                {day.getDate()}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HourlyViewOfPackage;
