import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import { capitalize, currencyFormat } from "../../../utils/stringHelpers";
import { Icons } from "../../../assets/icons";
import InputWithAdornment from "../../../components/Input/InputWithAdornment";
import SimpleTable from "../../../components/tables/SimpleTable";
import Button from "../../auth/components/Button";
import Card from "../components/Card";
import Customer from "../components/Customer";
import { useGetDashboardQuery } from "../utils/apis/getDashboard";
import { useGetOccupiedSpacesQuery } from "../utils/apis/getOccupiedSpaces";

function AdminDashboard() {
  const { data } = useGetDashboardQuery();

  const cardData = [
    {
      title: "Total Customers",
      value: data?.total_customers,
      color: "orange",
      options: [
        {
          label: "All",
          value: data?.total_customers,
          name: "all",
        },
        {
          label: "Residents",
          value: data?.total_guests,
          name: "resident",
        },
        {
          label: "Guests",
          value: data?.total_visitors,
          name: "visitor",
        },
      ],
    },
    {
      title: "Total Active Bookings",
      value: currencyFormat(data?.total_active_bookings)?.split?.(".")?.[0],
      color: "green",
    },
    {
      title: "Total Workspaces",
      value: data?.total_spaces,
      color: "purple",
      options: [
        {
          label: "All",
          value: data?.total_spaces,
          name: "all",
        },
        {
          label: "Meeting Rooms",
          value: data?.total_meeting_rooms,
          name: "meeting",
        },
        {
          label: "Work Stations",
          value: data?.total_workstations,
          name: "station",
        },
      ],
    },
    {
      title: "Active Promotions",
      value: currencyFormat(data?.active_promotions)?.split?.(".")?.[0],
      color: "pink",
    },
  ];

  return (
    <div className="w-full flex flex-col gap-8">
      <DashboardCards data={cardData} />
      <hr />
      <div className="-mt-8 flex justify-start gap-6 items-start">
        <div className="w-2/3">
          <OccupiedSpaces />
        </div>
        <div className="border-l  pl-[26px] pr-2 w-1/3 min-h-[60vh]">
          <TopCustomers />
        </div>
      </div>
    </div>
  );
}

const DashboardCards: FC<{ data: any[] }> = ({ data }) => {
  return (
    <div className="w-full flex justify-between items-center gap-5">
      {data.map((datum) => {
        return (
          <Card
            key={datum.title}
            title={datum.title}
            value={datum.value}
            color={datum.color}
            options={datum.options}
          />
        );
      })}
    </div>
  );
};

const OccupiedSpaces = () => {
  const [search, setSearch] = useState("");
  const { data } = useGetOccupiedSpacesQuery(search);

  return (
    <div className="flex flex-col justify-start items-start w-full py-[33px] gap-8">
      <div className="flex justify-between items-center w-full">
        <h2 className="font-medium text-point-lg leading-7">Occupied Spaces</h2>
        <InputWithAdornment
          left={
            <img
              src={Icons.utility.searchIcon}
              alt="Search field"
              aria-label="search"
            />
          }
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder="Find space..."
          height="38px"
        />
      </div>
      <SimpleTable
        heading
        columns={[
          {
            field: "space_id",
            title: "Space ID",
            headerStyle: {
              color: "var(--secondary-text)",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              minWidth: "100px",
            },
          },
          {
            field: "space_name",
            title: "Space Name ",
            headerStyle: {
              color: "var(--secondary-text)",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              minWidth: "244px",
            },
          },
          {
            title: "Status ",
            renderItem: (rowData) => {
              return (
                <Button
                  label="Booked"
                  className="rounded-lg h-[33px] px-4 py-1.5"
                />
              );
            },
            headerStyle: {
              color: "var(--secondary-text)",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              minWidth: "244px",
            },
          },
          {
            field: "timer",
            title: "Timer",
            headerStyle: {
              color: "var(--secondary-text)",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              minWidth: "244px",
            },
            cellStyle: {
              color: "var(--secondary-text)",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "21px",
            },
          },
        ]}
        perPage={5}
        data={
          data?.results?.map((ele: any) => ({
            space_id: ele.space_id,
            space_name: ele.name,
            timer: "2d : 03h : 23min",
          })) || []
        }
        emptyComponent={<EmptyComponent />}
      />
    </div>
  );
};

const TopCustomers = () => {
  const { data } = useGetDashboardQuery();
  const customers: {
    status: "active" | "inactive";
    type: "resident" | "visitor";
    id: string;
    name: string;
  }[] =
    data?.top_customers?.map?.((el: any) => ({
      status: el.status.toLowerCase(),
      type: el.customer_type,
      name: `${capitalize(el.first_name)} ${capitalize(el.last_name)}`,
    })) || [];
  return (
    <div className="flex flex-col gap-5 py-[33px]">
      <h3 className="font-medium text-point-lg leading-snug">Top Customers</h3>
      <div className="flex flex-col">
        {customers.map((el, ind) => (
          <Customer {...el} key={ind} />
        ))}
      </div>
    </div>
  );
};

const EmptyComponent = () => {
  return (
    <div className="w-full h-[50vh] flex flex-col justify-center items-center gap-4">
      <img
        className="w-[230px] h-[230px] object-cover"
        src={Icons.dashboard.spaceEmpty}
        alt="No Occupied Space"
      />
      <div className="space-y-2 flex flex-col  w-full">
        <p className="font-medium text-[21px] leading-[140%] text-center">
          No Spaces Occupied
        </p>
        <span className="w-full text-point-secondary-text text-center font-medium texg-sm leading-[160%]">
          There are currently no spaces occupied right now
        </span>
      </div>
    </div>
  );
};

export default AdminDashboard;
