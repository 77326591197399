import React, { FC } from "react";
import { Icons } from "../../../../assets/icons";

const StatusButton: FC<{
  status: "On-Seat" | "clocked out" | "clock in";
  onClick?: () => void;
  className?: string;
}> = ({ status, onClick, className }) => {
  const statusMap = {
    "clocked out": Icons.dashboard.clockoutIcon,
    "On-Seat": Icons.dashboard.onseatIcon,
  };
  const statusbg = {
    "clocked out": "bg-[#979797]",
    "clock in": "bg-point-blue",
    "On-Seat": "bg-[#12B76A]",
  };
  return (
    <div className="w-full">
      <button
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation();
            e.preventDefault();
            onClick?.();
          }
        }}
        className={`w-full capitalize rounded h-6 ${
          statusbg[status]
        } flex justify-center items-center py-0.5
         text-white font-semibold text-xs leading-[160%] gap-2 ${
           className || ""
         }`}
      >
        {status !== "clock in" && (
          <img src={statusMap[status]} alt={`representation of ${status}`} />
        )}
        <strong>{status}</strong>
      </button>
    </div>
  );
};

export default StatusButton;
