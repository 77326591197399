import { TOKEN_KEY, CURRENT_USER, USER_PERMISSIONS } from "./../apis/constants";
import { Storage } from "./../store/Storage";

export const addAuthToDevice = ({
  token,
  user,
  permissions,
}: {
  token: string;
  user: any;
  permissions: string[];
}) => {
  Storage.setItem(TOKEN_KEY, token);
  Storage.setItem(CURRENT_USER, user);
  Storage.setItem(USER_PERMISSIONS, permissions);
};

export const removeAuthFromDevice = () => {
  Storage.removeItem(TOKEN_KEY);
  Storage.removeItem(CURRENT_USER);
};
