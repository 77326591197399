import React, { AllHTMLAttributes, FC, forwardRef } from "react";
import PropTypes from "prop-types";

interface IProps extends AllHTMLAttributes<HTMLInputElement> {
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  left?: React.ReactNode;
  right?: React.ReactNode;
  style?: React.CSSProperties;
  height?: string;
  className?: string;
  name?: string;
}
const InputWithAdornment = forwardRef<HTMLInputElement, IProps>(
  (
    {
      left,
      right,
      onChange,
      placeholder,
      className,
      height,
      name,
      error,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="flex flex-col gap-1">
        <div
          className={
            "w-full flex items-center py-0 rounded  border-point-gray px-2 bg-point-input-bg border " +
            (error ? " border-luc-red border-solid  " : "") +
            (height ? `h-[${height}]` : "h-12")
          }
          style={style}
        >
          {left && left}
          <input
            placeholder={placeholder}
            onChange={onChange}
            ref={ref}
            name={name}
            {...rest}
            className={`focus:outline-none outline-none border-opacity-0 flex-grow border-none text-xs px-1 py-2 placeholder-point-placeholder-text text-point-label-text bg-point-input-bg ${
              className ? className : ""
            }`}
          />
          {right && right}
        </div>
        <small className="text-point-pink-dark">{error}</small>
      </div>
    );
  }
);
InputWithAdornment.displayName = "InputWithAdornment";
export default InputWithAdornment;
