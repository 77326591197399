import {
  getChatsSummary,
  getMessagesOfChat,
  getNotified,
} from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import {
  MutateOptions,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";
import { addDataToQueryString } from "../../../../utils/stringHelpers";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATION = "GET_NOTIFICATION";

export type RetrieveNotificationDTO = {
  page?: number;
  chatId?: number;
};

export const getNotificationsAction = async (body: { page: number }) => {
  const qs = addDataToQueryString("", {
    page: body.page,
  });
  try {
    const { data } = await axiosAction(
      "GET",
      getNotified(`?${qs}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getNotificationAction = async (id: number) => {
  try {
    const { data } = await axiosAction(
      "GET",
      getNotified(`${id}/`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useGetNotificationQuery = <T = any>(
  id: number,
  opts?: Omit<UseQueryOptions<T, unknown, T, QueryKey>, "queryKey" | "queryFn">
) => {
  const qc = useQueryClient();
  return useQuery<T>([GET_NOTIFICATION, id], () => getNotificationAction(id), {
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: [GET_NOTIFICATIONS],
      });
    },
    ...opts,
  });
};
