import React, { FC, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Icons } from "../assets/icons";
import Modal from "../components/modal/Modal";
import { useMediaQuery } from "../hooks/useMedia";
import { Storage } from "../store/Storage";
import SidebarItem from "./components/SidebarItem";

import defaultImage from "../assets/images/defaultImage.png";
import { useClickOutside } from "../hooks/useOutsideClick";
import SidebarItemWithChildren from "./components/SidebarItemWithChildren";
import { removeAuthFromDevice } from "../utils/api";
import Button from "../pages/auth/components/Button";
import Checkbox from "../components/Input/Checkbox";
import { CURRENT_USER, TOKEN_KEY } from "../apis/constants";
import Sidebar from "../components/modal/Sidebar";
import { webSocketEndpoint } from "../constants/api";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import useNotification from "../hooks/useNotification";
import useUnreadChats from "../hooks/useUnreadChat";
import {
  GET_CUSTOMERS_CHATS,
  GET_CUSTOMER_MESSAGES,
  useGetCustomerChatsQuery,
} from "../pages/customers/utils/apis/chats";
import { useQueryClient } from "react-query";
import {
  GET_NOTIFICATION,
  GET_NOTIFICATIONS,
} from "../pages/customers/utils/apis/notification";
import Notifications from "./components/notification/Notifications";

const DONT_SHOW_LOGOUT_MODAL = "dont_show_logout_modal";

const MenuBar = [
  {
    label: "Dashboard",
    activeIcon: Icons.menubar.dashboardSelectedIcon,
    icon: Icons.menubar.dashboardIcon,
    url: "/app/dashboard",
  },
  {
    label: "Spaces",
    activeIcon: Icons.menubar.spaceSelectedIcon,
    icon: Icons.menubar.spaceIcon,
    url: "/app/spaces",
  },
  {
    label: "Packages",
    activeIcon: Icons.menubar.packageSelectedIcon,
    icon: Icons.menubar.packageIcon,
    url: "/app/packages",
  },
  {
    label: "Customers",
    activeIcon: Icons.menubar.customerSelectedIcon,
    icon: Icons.menubar.customerIcon,
    url: "/app/customers",
  },
  {
    label: "Audit Logs",
    activeIcon: Icons.menubar.auditSelectedIcon,
    icon: Icons.menubar.auditIcon,
    url: "/app/audit-logs",
  },
  // {
  //   label: "Reports",
  //   activeIcon: Icons.menubar.reportSelectedIcon,
  //   icon: Icons.menubar.reportIcon,
  //   url: "/app/reports",
  // },
];
const MessagesBar: FC<{ count: number }> = ({ count }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="relative"
        role="button"
        onClick={() => navigate("/app/customers/chat?view=unread")}
      >
        <img
          src={Icons.menubar.messagesIcon}
          alt="messages"
          className="w-7 h-7"
        />
        {count ? (
          <span className="absolute -top-0.5 -right-1.5 bg-point-pink-dark align-middle text-point-white rounded-2xl font-medium text-[8px] leading-[10px] w-6 h-2.5 py-0 px-1 flex justify-center items-center">
            {count > 999 ? "999+" : count}
          </span>
        ) : null}
      </div>
    </div>
  );
};

const ProfileImage: FC<{ url?: string }> = ({
  url = Icons.utility.defaultUserIcon,
}) => {
  return (
    <div className="w-11 h-11 rounded-full">
      <img
        src={url || Icons.utility.defaultUserIcon}
        alt="profile picture"
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
        className="object-cover object-center max-w-full rounded-full"
      />
    </div>
  );
};
let isMounted = false;
const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const qc = useQueryClient();
  const [showPanel, setShowPanel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { pathname } = useLocation();

  const {
    total: totalChats,
    loadChats,
    addChat,
    currentPage,
    chats,
  } = useUnreadChats();

  const [activeMenu, setActiveMenu] = useState(pathname);

  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const anchorElRef = useRef<HTMLImageElement | null>(null);
  anchorElRef.current = anchorEl;
  useClickOutside([dropdownRef, anchorElRef], () => {
    setAnchorEl(null);
  });
  let currentUser = Storage.getItem(CURRENT_USER);
  let isMobile = useMediaQuery("(max-width: 768px)");
  useEffect(() => {
    isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []);
  const { data: unreadChats } = useGetCustomerChatsQuery({
    page: currentPage,
    unread: true,
  });

  useEffect(() => {
    setActiveMenu(pathname);
    currentUser = Storage.getItem(CURRENT_USER);
  }, [pathname]);
  const toggleShowPanel = () => {
    setShowPanel((prev) => !prev);
  };
  const showProfilePage = () => {
    setAnchorEl(null);
    navigate("/app/user-profile");
  };
  const logoutAction = () => {
    removeAuthFromDevice();
    navigate("/auth/sign-in");
  };
  useEffect(() => {
    const token = Storage.getItem(TOKEN_KEY);
    const client = new W3CWebSocket(
      `${webSocketEndpoint}/${token}/notifications/`
    );
    client.onopen = () => {
      console.log("WebSocket Client Connected");
    };
    client.onmessage = (ev: any) => {
      let dataFromServer;
      console.log(ev.data, "RECEIVED");
      try {
        dataFromServer = JSON.parse(ev.data);
        if (dataFromServer) {
          const { action } = dataFromServer;
          console.log(dataFromServer, "DATA");
          if (action === "notify") {
            qc.invalidateQueries({
              queryKey: GET_NOTIFICATIONS,
            });
            qc.invalidateQueries({
              queryKey: GET_NOTIFICATION,
            });
          }
        }
      } catch (err) {
        console.log(ev.data, "DATA FROM SERVER");
      }
    };
  }, []);
  useEffect(() => {
    const token = Storage.getItem(TOKEN_KEY);
    const client = new W3CWebSocket(`${webSocketEndpoint}/${token}/chats/`);

    client.onopen = () => {
      console.log("WebSocket Client Connected TO chats");
    };
    client.onmessage = (ev: any) => {
      let dataFromServer;
      console.log(ev.data, "RECEIVED");
      try {
        dataFromServer = JSON.parse(ev.data);
        if (dataFromServer) {
          const { action } = dataFromServer;
          console.log(dataFromServer, "DATA");
          if (action === "message") {
            qc.invalidateQueries({
              queryKey: GET_CUSTOMERS_CHATS,
            });
            qc.invalidateQueries({
              queryKey: GET_CUSTOMER_MESSAGES,
            });
          }
        }
      } catch (err) {
        console.log(ev.data, "DATA FROM SERVER");
      }
    };
  }, []);

  useEffect(() => {
    if (!unreadChats) return;
    const res = unreadChats.results.filter(
      (el: any) => el.unread_message_count && el.last_message
    );
    loadChats(res, res.length);
  }, [unreadChats]);

  return (
    <div className="px-0 mx-0 relative scroller">
      <div className="px-[72px] py-4 fixed top-0 left-0 border border-solid border-transparent border-b-point-gray flex justify-between w-full z-50 bg-white">
        <img
          src="/companyLogo.png"
          alt="CompanyLogo"
          width="60px"
          height="27px"
        />
        <div className="flex items-center justify-end gap-7 bg-white z-50">
          <MessagesBar count={totalChats} />
          <Notifications />
          <div className="flex items-center justify-start gap-3">
            <ProfileImage url={currentUser?.image || defaultImage} />
            <p className="font-medium leading-8">{`${currentUser?.first_name} ${currentUser?.last_name}`}</p>
          </div>
          <img
            className="cursor-pointer"
            role="button"
            src={Icons.utility.dropdownBlackIcon}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          />
          {Boolean(anchorEl) && (
            <ul
              ref={dropdownRef}
              style={{
                position: "absolute",
                right: 50,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                backgroundColor: "#fff",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                padding: "10px 0px",
                zIndex: 30,
                marginRight: "12px",
                width: "max-content",
                gap: "20px",
                top: "76px",
              }}
            >
              <li
                role="menuitem"
                className="px-4 py-2 font-medium cursor-pointer hover:bg-gray-200"
                style={{ minWidth: "200px" }}
                onClick={showProfilePage}
              >
                View Profile
              </li>
            </ul>
          )}
        </div>
      </div>

      <div className="flex scroller">
        {/** Backdrop - Show on mobile */}
        <div
          className={`fixed inset-0 z-10 bg-point-blue bg-opacity-75 lg:hidden`}
          style={{
            opacity: 0.5,
            display: isMobile && showPanel ? "block" : "none",
          }}
          aria-hidden="true"
          onClick={toggleShowPanel}
        ></div>

        <aside
          className={`fixed pt-6 pb-2 inset-y-0 top-0 px-0 left-0 border-r-2 border-point-blue z-40 bg-point-blue w-60 ${
            isMobile && !showPanel && "hidden"
          }`}
        >
          <div className="flex flex-col h-full space-y-1 overflow-y-hidden hover:overflow-y-auto">
            <nav className="space-y-1 px-2 mb-24"></nav>

            <nav className="flex-1 space-y-2 pt-2">
              <ul role="meunbar" className="px-0 mx-0">
                {MenuBar.map((menuItem) => (
                  <SidebarItem
                    key={menuItem.url}
                    label={menuItem.label}
                    url={menuItem.url}
                    icon={
                      <img
                        src={
                          activeMenu.includes(menuItem.url)
                            ? menuItem.activeIcon
                            : menuItem.icon
                        }
                        alt={menuItem.label}
                      />
                    }
                    selected={activeMenu.includes(menuItem.url)}
                    onClick={() => setActiveMenu(menuItem.url)}
                  />
                ))}
                {/* <SettingsDropDown /> */}
                <SidebarItemWithChildren
                  title="Analytics"
                  icon={Icons.menubar.reportIcon}
                  activeIcon={Icons.menubar.reportSelectedIcon}
                  parentPath="/app/analytics"
                  position={6}
                  items={[
                    {
                      url: "/app/analytics/reports",
                      label: "Reports",
                    },
                    {
                      url: "/app/analytics/metrics",
                      label: "Metrics",
                    },
                  ]}
                />
                <SidebarItem
                  label={"Users"}
                  url={"/app/users"}
                  icon={
                    <img
                      src={
                        activeMenu.includes("/app/users")
                          ? Icons.menubar.settingIcon
                          : Icons.menubar.settingIcon
                      }
                      alt={"Users Settings"}
                    />
                  }
                  selected={activeMenu.includes("/app/users")}
                  onClick={() => setActiveMenu("/app/users")}
                />
              </ul>
            </nav>

            <nav className="flex flex-col pt-2 px-2 divide-x divide-point-white space-y-12 ">
              <SidebarLoggedOutUser
                setShowModal={setShowModal}
                logoutAction={logoutAction}
              />
            </nav>
          </div>
        </aside>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          className="top-[25%]"
        >
          <LogoutWarning
            setShowModal={setShowModal}
            logoutAction={logoutAction}
          />
        </Modal>
        <main className={`flex-1 w-full h-full ${!isMobile && "pl-60"}`}>
          {/* Mobile header */}
          <div
            className={`sticky w-full flex items-center justify-between bg-luc-white border-b border-point-blue p-4 top-0 ${
              !isMobile && "hidden"
            } ${isMobile && showPanel && "opacity-10"}`}
          >
            <div className="flex items-center space-x-2">
              <img
                style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
                width="35px"
                height="35px"
                src="/companyLogo.png"
                alt="logo"
              />
            </div>
            <img
              src="/companyLogo.png"
              width={24}
              height={24}
              className="text-white"
              onClick={toggleShowPanel}
            />
          </div>

          {/* content */}
          <div className="h-full min-h-[602px] overflow-x-hidden overflow-y-auto px-5 py-2 mt-[102px] bg-point-white">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

const SidebarLoggedOutUser: FC<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  logoutAction: () => void;
}> = ({ setShowModal, logoutAction }) => {
  const dontShow = Storage.getItem(DONT_SHOW_LOGOUT_MODAL);
  const logoutHandler = () => {
    if (dontShow) {
      logoutAction();
    } else {
      setShowModal(true);
    }
  };
  return (
    <>
      <li
        onClick={logoutHandler}
        role="menuitem"
        tabIndex={0}
        className={`flex items-center justify-start gap-4 px-4 py-3 bg-transparent w-full cursor-pointer`}
      >
        <span id={"Logout"} className="text-white">
          <img src={Icons.menubar.logoutIcon} />
        </span>
        <span
          aria-labelledby={"Logout"}
          className="text-white font-medium text-base leading-[30px]"
        >
          {"Logout"}
        </span>
      </li>
    </>
  );
};

const LogoutWarning: FC<{
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  logoutAction: () => void;
}> = ({ setShowModal, logoutAction }) => {
  const [dontShowPassword, setDontShowPassword] = useState(
    Boolean(Storage.getItem(DONT_SHOW_LOGOUT_MODAL))
  );
  const dontShowHandler = (checked: boolean) => {
    Storage.setItem(DONT_SHOW_LOGOUT_MODAL, checked);
    setDontShowPassword(checked);
  };
  return (
    <div className="py-7 w-full pl-7 pr-2 flex flex-col gap-10">
      <div className="w-full flex items-center justify-between">
        <h2 className="font-medium text-[28px] leading-8">Log Out ?</h2>
        <button
          onClick={() => {
            setShowModal(false);
          }}
          className="-mt-2"
        >
          <img src={Icons.utility.cancelIcon} alt="cancel" />
        </button>
      </div>
      <div className="flex flex-col gap-4 justify-start items-start">
        <p className="text-point-secondary-text font-light leading-[160%] text-base">
          Logging out from your account will end your session and require a
          login on next launch.
        </p>
        <div className="flex justify-start items-center gap-2 cursor-pointer">
          <Checkbox
            id="remember"
            checked={dontShowPassword}
            onChange={(e) => dontShowHandler(e.currentTarget.checked)}
          />
          <label
            htmlFor="remember"
            className="font-light text-sm leading-[180%]"
          >
            Don&apos;t show again
          </label>
        </div>
      </div>
      <div className="flex justify-start items-center gap-2.5">
        <Button
          onClick={logoutAction}
          className="capitalize min-w-[160px] px-3 text-base whitespace-nowrap"
          label="Yes, Continue"
        />
        <Button
          onClick={() => setShowModal(false)}
          className="capitalize w-[190px] px-2 text-base bg-[#F7F7F7] text-point-primary-text whitespace-nowrap"
          label="Cancel"
        />
      </div>
    </div>
  );
};

export default MainLayout;
