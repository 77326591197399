import React, { HTMLAttributes } from "react";
import { Icons } from "../../../assets/icons";

interface ICustomer extends HTMLAttributes<HTMLElement> {
  status: "active" | "inactive";
  name: string;
  type: "resident" | "visitor";
  id: string;
}
const imageMap = {
  resident: Icons.dashboard.residentIcon,
  visitor: Icons.dashboard.visitorIcon,
};
function Customer({ status, name, type, id }: ICustomer) {
  return (
    <div className="p-3.5 flex justify-between items-start border-b border-point-accent-boder">
      <div className="flex flex-col">
        <h5 className="font-medium leading-7 pb-0.5">{name}</h5>
        <p
          className="uppercase leading-5 text-xs font-medium text-point-secondary-text mb-2.5"
          style={{ letterSpacing: "0.08em" }}
        >
          {id}
        </p>
        <div className="flex items-center gap-1 justify-start">
          <img src={imageMap[type]} alt={`representation of ${type}`} />
          <strong
            className={`uppercase leading-5 text-xs font-medium ${
              type === "visitor"
                ? "text-point-secondary-bg"
                : "text-point-tertiary-bg"
            }`}
            style={{ letterSpacing: "0.08em" }}
          >
            {type}
          </strong>
        </div>
      </div>
      <div className="flex justify-end items-center gap-2">
        <span
          className={`w-2 h-2 rounded-full ${
            status === "active" ? "bg-point-green-dark" : "bg-point-pink-dark"
          }`}
        ></span>
        <span
          className={`capitalize text-sm font-light leading-5 ${
            status === "active"
              ? "text-point-green-dark"
              : "text-point-pink-dark"
          }`}
        >
          {status}
        </span>
      </div>
    </div>
  );
}

export default Customer;
