import SimpleTable from "../../../../components/tables/SimpleTable";
import Button from "../../../../pages/auth/components/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertToLocalTime,
  getDateString,
  isDayInBetweenDaysInclusive,
} from "../../../../utils/dateHelper";
import { IBooking } from "../Calendar/MonthlyView";
import EmptyTable from "../EmptyTable";
import useDisplayDays from "../../../../pages/dashboard/hooks/useDisplayDays";
import useMismatchPages from "../../../../hooks/useMismatchPages";
import { debounce } from "../../../../utils/debounce";
import { pad } from "../../../../utils/stringHelpers";
import { MonthlyDetailsModal } from "../Calendar/DayOfMonth";

const MonthViewList = (props: {
  currentDate: Date;
  calendarData: any;
  searchBooking?: string;
  isLoading?: boolean;
}) => {
  const { calendarData, currentDate, searchBooking, isLoading } = props;
  const [viewType, setViewType] = useState<"work station" | "meeting room">(
    "work station"
  );
  const [viewDetail, setViewDetail] = useState<any>(null);
  const displayableDays = useDisplayDays(currentDate);
  const computeDataForDate = useCallback(
    (day: Date) => {
      const result = (calendarData || []).reduce(
        (acc: IBooking[], cur: any) => {
          const bookings: IBooking[] = cur.bookings;
          const bookingsInThatDay = bookings
            .filter((booking) => {
              const startTime = convertToLocalTime(booking.start_date) as Date;
              const endTime = convertToLocalTime(booking.end_date) as Date;
              if (
                (booking.package.package_type === "month" ||
                  booking.package.package_type === "day") &&
                isDayInBetweenDaysInclusive(day, startTime, endTime)
              ) {
                return true;
              }
              if (
                booking.package.package_type === "hour" &&
                day.getMonth() === startTime.getMonth() &&
                day.getDate() === startTime.getDate() &&
                day.getFullYear() === startTime.getFullYear()
              ) {
                return true;
              }
            })
            .map((booking) => {
              return {
                ...booking,
                space: {
                  ...(booking.space || {}),
                  name: cur.name,
                  space_type: cur.space_type,
                },
              };
            });

          // const dayStr = getDateString(day);
          // acc[dayStr] = (acc[dayStr] || []).concat(bookingsInThatDay);
          return acc.concat(bookingsInThatDay);
        },
        []
      );
      return result;
    },
    [calendarData]
  );
  const countDetails =
    useMemo(() => {
      if (!viewDetail) return {};
      const day = new Date(viewDetail.id);
      const data = computeDataForDate(day);
      return data?.reduce?.((acc: Record<string, IBooking[]>, cur: any) => {
        acc[cur.space.space_type] = (acc[cur.space.space_type] || []).concat(
          cur
        );
        return acc;
      }, {});
    }, [viewDetail]) || {};

  return (
    <div className="w-full h-full">
      <SimpleTable
        perPage={10}
        loading={isLoading}
        heading
        style={{ width: "100%" }}
        className="w-full"
        columns={[
          {
            title: "Day",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              borderRadius: "8px 0 0 0",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "12px",
              height: "55px",
              maxWidth: "50px",
            },
            field: "day",
            cellStyle: {
              paddingLeft: "20px",
              color: "var(--secondary-text)",
            },
          },
          {
            title: "Work Stations",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "12px",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "160%",
              fontWeight: "500",

              color: "var(--primary-text)",
              minWidth: "220px",
              textTransform: "capitalize",
            },
            field: "work_stations",
          },

          {
            title: "Meeting rooms",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "12px",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "600",
              minWidth: "160px",
              color: "#666",
              textTransform: "capitalize",
            },
            field: "meeting_rooms",
          },
          {
            title: "Active",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "12px",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "160%",
              fontWeight: "500",
              minWidth: "200px",
              color: "#181818",
            },
            field: "active",
          },

          {
            title: "Status/Action",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "12px",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "160%",
              fontWeight: "600",
              minWidth: "195px",
            },
            field: "action",
            renderItem: (rowData) => {
              return (
                <Button
                  onClick={() => setViewDetail(rowData)}
                  label={"View"}
                  className="rounded h-9 gap-2 py-1 px-[65px] font-medium text-base leading-[180%]"
                />
              );
            },
          },
        ]}
        data={useMemo(
          () =>
            displayableDays.map((day) => ({
              id: getDateString(day),
              work_stations: "23",
              meeting_rooms: "3",
              day: `${pad(day.getDate(), 2)}/${pad(day.getMonth() + 1, 2)}`,
              active: ((data: IBooking[]) => {
                const groupedBySpaceType = data.reduce(
                  (acc: Record<string, IBooking[]>, cur) => {
                    acc[cur.space?.space_type] = (
                      acc[cur.space?.space_type] || []
                    ).concat(cur);
                    return acc;
                  },
                  {}
                );
                return `${
                  groupedBySpaceType["work station"]?.length || 0
                } Workstation${
                  groupedBySpaceType["work station"]?.length > 1 ? "s" : ""
                }, ${
                  groupedBySpaceType["meeting room"]?.length || 0
                } Meeting Room${
                  groupedBySpaceType["meeting room"]?.length > 1 ? "s" : ""
                }`;
              })(computeDataForDate(day)),
            })),
          [displayableDays, calendarData]
        )}
        emptyComponent={
          <EmptyTable
            title={
              searchBooking
                ? "No Space with that search criteria"
                : "No Spaces yet"
            }
          />
        }
        footerStyle={{
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
          right: "-30%",
          justifySelf: "flex-end",
          position: "relative",
          bottom: "0",
        }}
      />
      <MonthlyDetailsModal
        countDetails={countDetails}
        viewType={viewType}
        setViewType={setViewType}
        showDetail={!!viewDetail}
        setShowDetail={() => setViewDetail(null)}
      />
    </div>
  );
};

export default MonthViewList;
