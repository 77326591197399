import React, { HTMLAttributes } from "react";
import CircularProgress from "./CircularProgress";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  label?: React.ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
}

function Button({
  label,
  className,
  loading,
  fullWidth,
  type = "button",
  ...rest
}: Props) {
  return (
    <button
      type={type}
      className={`bg-point-blue disabled:bg-[#1C3C5480] flex text-base font-medium leading-7 capitalize justify-center items-center text-point-white rounded h-12 gap-4  select-none ${
        className ? className : ""
      } ${fullWidth ? "w-full" : "w-28"} `}
      {...rest}
    >
      {loading && <CircularProgress size="18px" color="inherit" />}
      {label}
    </button>
  );
}

export default Button;
