import { date, lazy, mixed, number, object, string } from "yup";

const createWorkStationSchema = (currentStage = 1) =>
  object().shape({
    first_name: string()
      .typeError("First Name must be a string")
      .min(2)
      .required("First Name is a required field"),
    last_name: string()
      .typeError("Last Name must be a string")
      .min(2)
      .required("Last Name is a required field"),
    email: string()
      .email()
      .typeError("Email must be a string")
      .min(2)
      .required("Email is a required field"),
    customer_type: string()
      .oneOf(["resident", "visitor"], "Select what represents a customer")
      .required(),
    phone_number: string()
      .typeError("Phone must be a string")
      .min(2)
      .required("Phone is a required field"),
    package: lazy((value) => {
      if (currentStage >= 2) {
        return number()
          .typeError("Select a package")

          .required("Package is required");
      }
      return mixed().notRequired();
    }),
    start_date: lazy((value) => {
      if (currentStage >= 2) {
        return date().typeError("Start Date must be a Date");

        // .required("Start Date is required");
      }
      return mixed().notRequired();
    }),
    end_date: lazy((value) => {
      if (currentStage >= 2) {
        return date().typeError("End Date must be a Date ");

        // .required("End Date is required");
      }
      return mixed().notRequired();
    }),
    num_of_packages: lazy((value) => {
      if (currentStage >= 2) {
        return number()
          .typeError("Pick number of packages")

          .required("Number of Packages is required");
      }
      return mixed().notRequired();
    }),
    space: lazy((value) => {
      if (currentStage >= 3) {
        return number()
          .typeError("Select an available Work Station")

          .required("Select an available work station");
      }
      return mixed().notRequired();
    }),
    payment_type: lazy((value) => {
      if (currentStage >= 4) {
        return string()
          .typeError("Select a payment method ")

          .required("Payment method is required");
      }
      return mixed().notRequired();
    }),
  });

export default createWorkStationSchema;
