import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";

interface IChat {
  chats: any[];
  loadChats: (chats: any[], total?: number) => void;
  addChat: (chat: any) => void;
  total: number;
  currentPage: number;
}
export const unreadChatCtx = createContext<IChat>({
  chats: [],
  addChat: (chat: any) => {},
  loadChats: (chats: any, total?: number) => {},
  total: 0,
  currentPage: 1,
});

const ChatProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [chats, setChats] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const loadChats = useCallback((Chats: any[], total?: number) => {
    setChats(Chats);

    setTotal(total || 0);
  }, []);
  const addChat = (chat: any) => {
    setChats((prev) => [chat, ...prev]);
    setTotal((prev) => prev + 1);
  };
  const api: IChat = useMemo(() => {
    return {
      chats,
      loadChats,
      addChat,
      total,
      currentPage,
    };
  }, [total, currentPage, chats]);

  return (
    <unreadChatCtx.Provider value={api}>{children}</unreadChatCtx.Provider>
  );
};

export default ChatProvider;
