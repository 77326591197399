import React, { FC } from "react";
import Button from "../../../../pages/auth/components/Button";
import { Icons } from "../../../../assets/icons";
import { convertToLocalTime, getMeridian } from "../../../../utils/dateHelper";
import { pad } from "../../../../utils/stringHelpers";
import PackageRepresentation from "../../../../pages/packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../../pages/packages/utils";

import useDownload from "../../../../hooks/useDownload";

const BookSpaceSuccessful: FC<{
  onClose: () => void;
  payload?: Record<string, any>;
  type: "meeting room" | "work station";
}> = (props) => {
  const { onClose, payload, type } = props;
  return (
    <section className="px-6 py-[33px] flex flex-col gap-[30px] overflow-auto scroller max-h-[80vh]">
      <SuccessfulHeader />
      <SuccessSummary payload={{ ...payload, type }} />
      <Button
        onClick={onClose}
        label="Go to Dashboard"
        className="bg-[#F7F7F7] self-center rounded h-12 w-[184px] gap-2.5 text-point-primary-text font-medium text-sm"
      />
    </section>
  );
};

const SuccessfulHeader: FC<{}> = (props) => {
  return (
    <header className="flex justify-start gap-6 items-center">
      <div className="flex justify-center items-center w-[71px] h-[71px] rounded-full bg-point-green-light">
        <img src={Icons.dashboard.checkMarkGreenIcon} alt="succesful" />
      </div>
      <div className="flex flex-col justify-center items-start">
        <h2 className="text-point-primary-text font-medium text-[24px] leading-[140%]">
          Space Booked Successfully
        </h2>
        <p className="font-medium text-base leading-[180%] text-point-secondary-text">
          A copy has been sent to the email provided above
        </p>
      </div>
    </header>
  );
};

const SuccessSummary: FC<{
  payload?: Record<string, any>;
}> = (props) => {
  const { payload } = props;
  const { exportHandler, isDownloading, doneDownloading } = useDownload();

  return (
    <main className="flex flex-col gap-4 w-full">
      <div className="w-full flex justify-end items-center">
        <Button
          loading={isDownloading}
          onClick={() =>
            exportHandler(
              `frontdesk/booking/download/?booking_id=${payload?.booking_id}`,
              {
                file_type: "pdf",
                file_name: "booking report",
              }
            )
          }
          className="bg-[#F8F8F8] w-[142px] h-[32px] rounded-lg  "
          label={
            <span className="gap-2.5 flex justify-center items-center font-normal text-sn leading-[18px] text-[#040411] px-3 py-2 w-full">
              <img src={Icons.dashboard.downloadIcon} alt="download receipt" />
              <span>Download</span>
            </span>
          }
        ></Button>
      </div>
      <div className="w-full flex flex-col bg-point-input-bg pt-[25px] pr-[15px] pb-[25px] pl-[31px] gap-2.5 rounded">
        <div className="flex justify-between items-center">
          <h3>Running Package</h3>
          <PackageCard
            name={payload?.package?.name}
            color={payload?.package?.color_code}
          />
        </div>
        <hr className="my-4" />
        <div className="grid grid-cols-12 gap-6">
          <FormCard title="Full Name">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text">
              {`${payload?.customer}`}
            </strong>
          </FormCard>
          <FormCard title="Email Address">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text">
              {payload?.customer_email}
            </strong>
          </FormCard>
          <FormCard title="Space Type">
            <div className="w-full flex justify-start items-center gap-2.5">
              <div className="w-8 h-8 rounded-full flex justify-center items-center bg-[#D9D9D9]">
                <img
                  width={16}
                  height={16}
                  src={Icons.dashboard.codepenIcon}
                  alt="space type"
                />
              </div>
              <p className="text-base font-medium leading-[180%] text-point-primary-text">
                {payload?.type === "work station"
                  ? "Workstation"
                  : "Meeting Room"}
              </p>
            </div>
          </FormCard>
          <FormCard title="Space Name">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text">
              {payload?.space?.name}
            </strong>
          </FormCard>
          <FormCard title="Start Date">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text">
              {payload?.start_date
                ? new Date(payload.start_date).toLocaleDateString()
                : ""}{" "}
              <span className="px-4 text-base text-[#666]">
                {payload?.start_date
                  ? (() => {
                      const date = convertToLocalTime(
                        payload.start_date
                      ) as Date;
                      return `${pad(date.getHours(), 2)}:${pad(
                        date.getMinutes(),
                        2
                      )} ${getMeridian(payload.start_date).toUpperCase()}`;
                    })()
                  : ""}
              </span>
            </strong>
          </FormCard>
          <FormCard title="End Date">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text">
              {payload?.end_date
                ? new Date(payload.end_date).toLocaleDateString()
                : ""}{" "}
              <span className="px-4 text-base text-[#666]">
                {payload?.end_date
                  ? (() => {
                      const date = convertToLocalTime(payload.end_date) as Date;
                      return `${pad(date.getHours(), 2)}:${pad(
                        date.getMinutes(),
                        2
                      )} ${getMeridian(payload.end_date).toUpperCase()}`;
                    })()
                  : ""}
              </span>
            </strong>
          </FormCard>
          <FormCard title="Payment Method">
            <strong className="text-base font-medium leading-[180%] text-point-primary-text capitalize">
              {payload?.payment_type}
            </strong>
          </FormCard>
        </div>
      </div>
    </main>
  );
};

const PackageCard: FC<{
  name: string;
  color: string;
}> = ({ name, color }) => {
  return (
    <div
      role="presentation"
      style={{
        background: `linear-gradient(#fff, #fff) padding-box, linear-gradient(45deg,  ${color}, ${getOtherColorVariant(
          color || ""
        )}) border-box`,
        border: "1px solid transparent",
      }}
      className={`flex justify-start items-center rounded p-2 gap-2 hover:shadow-md cursor-pointer
       border  text-[#d9d9d9] bg-white text-[${color}]`}
    >
      <PackageRepresentation color={color} />
      <h6 className="capitalize    text-sm leading-[180%] font-medium">
        {name}
      </h6>
    </div>
  );
};

const FormCard: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <div className="col-span-6 flex flex-col justify-start gap-1 items-start">
      <h4 className="font-medium text-sm leading-[160%] text-[#666] ">
        {title}
      </h4>
      <div>{children}</div>
    </div>
  );
};

export default BookSpaceSuccessful;
