import { Icons } from "../../../../assets/icons";
import Modal from "../../../../components/modal/Modal";
import defaultImage from "../../../../assets/images/defaultImage.png";
import React, { FC } from "react";

import PackageRepresentation from "../../../../pages/packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../../pages/packages/utils";
import { BookingStatus } from "../../../../pages/dashboard/dashboard.types";
import { useClockinBookingMutation } from "../../../../pages/dashboard/utils/apis/getDashboard";
import Button from "../../../../pages/auth/components/Button";
import { ToastNotify } from "../../../../utils/toastNotify";
import { pad } from "../../../../utils/stringHelpers";
import { getMeridian, getTimeLeft } from "../../../../utils/dateHelper";

interface IDetail {
  open: boolean;
  onClose: () => void;
  details: any;
}
function DetailModal(props: IDetail) {
  const { open, onClose, details } = props;
  return (
    <Modal open={open} onClose={onClose} className="top-[15%]">
      <div className="w-full p-6 flex flex-col gap-12 justify-start items-center">
        <DetailHeader onClose={onClose} />
        <UserRepresentation details={details} />
        <SpaceRepresentation details={details} />
        <PackageSection details={details} />
        <StatusSection details={details} />
      </div>
    </Modal>
  );
}

const DetailHeader: FC<Pick<IDetail, "onClose">> = ({ onClose }) => {
  return (
    <header className="w-full flex justify-between items-center font-medium">
      <h2 className="text-[24px] leading-[140%]">Detail</h2>
      <button onClick={onClose}>
        {" "}
        <img src={Icons.utility.cancelIcon} />
      </button>
    </header>
  );
};

const imageMap = {
  resident: Icons.dashboard.residentIcon,
  visitor: Icons.dashboard.visitorIcon,
};
const UserRepresentation: FC<Pick<IDetail, "details">> = ({ details }) => {
  const { image, fullName, status: type } = details.user;
  return (
    <div className="flex flex-col justify-start items-center gap-2 -mt-1">
      <img
        src={image || defaultImage}
        alt={`image of ${fullName}`}
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
        className="w-[100px] h-[100pxpx] object-fill object-center rounded-full"
      />
      <div className="flex flex-col justify-center items-center">
        <h5 className="font-medium text-[28px] leading-[140%] capitalize">
          {fullName}
        </h5>
        <div className="flex items-center gap-1 justify-center">
          <img
            src={imageMap[type as keyof typeof imageMap]}
            alt={`representation of ${type}`}
            width={10}
            height={10}
          />
          <strong
            className={`uppercase text-[12px] leading-[160%] font-medium ${
              type === "visitor"
                ? "text-point-secondary-bg"
                : "text-point-tertiary-bg"
            }`}
            style={{ letterSpacing: "0.08em" }}
          >
            {type}
          </strong>
        </div>
      </div>
    </div>
  );
};

const SpaceRepresentation: FC<Pick<IDetail, "details">> = ({ details }) => {
  const { name, space_type, expectedGuests = 5 } = details?.space || {};
  const ImageMap = {
    "work station": Icons.dashboard.stationIcon,
    "meeting room": Icons.dashboard.meetingIcon,
  };
  const textMap = {
    "work station": "Work station",
    "meeting room": "Meeting room",
  };
  return (
    <div className="flex flex-col gap-1 w-full">
      <h3 className="font-medium text-[28px] leading-[140%]">{name}</h3>
      <div className="w-full flex justify-start gap-1 items-center">
        <img
          src={ImageMap[space_type as keyof typeof ImageMap]}
          alt={`Representation of ${space_type}`}
        />
        <span className="font-medium text-base leading-[180%] text-point-secondary-text">
          {textMap[space_type as keyof typeof ImageMap]}
        </span>
      </div>
      {space_type === "meeting room" && (
        <div className="flex items-center gap-1 font-medium text-sm leading-[180%] text-point-secondary-text">
          <h4>Expected guests: </h4>
          <strong className="text-point-primary-text">{expectedGuests}</strong>
        </div>
      )}
    </div>
  );
};

const PackageSection: FC<Pick<IDetail, "details">> = ({ details }) => {
  const { color, name } = details.package;
  return (
    <section className="w-full flex justify-start items-start">
      <div className="flex flex-col gap-1 w-1/2">
        <h5 className="font-medium text-sm leading-[160%] text-[#979797]">
          Package
        </h5>
        <div className="flex justify-start items-center">
          <PackageRepresentation
            color={color}
            otherVariant={getOtherColorVariant(color)}
          />
          <strong className="uppercase tracking-[0.08em] text-xs font-medium leading-[160%]">
            {name}
          </strong>
        </div>
      </div>
      <div className="justify-self-start self-start flex flex-col gap-1 items-start">
        <h5 className="font-medium text-sm leading-[160%] text-[#979797]">
          Time Period
        </h5>
        <div className="flex justify-start items-center">
          {(() => {
            if (
              details?.package?.package_type === "month" ||
              details?.package?.package_type === "day"
            )
              return `${details?.package?.quantity} ${details?.package?.package_type}`;
            else
              return (
                <span className="font-medium text-base leading-[180%]">
                  {(() => {
                    const date = new Date(details?.start_date);
                    return `${pad(date.getHours(), 2)}:${pad(
                      date.getMinutes(),
                      2
                    )} ${getMeridian(details?.start_date)}`;
                  })()}
                  <span className="text-[#979797] px-2">to</span>
                  {(() => {
                    const date = new Date(details?.end_date);
                    return `${pad(date.getHours(), 2)}:${pad(
                      date.getMinutes(),
                      2
                    )} ${getMeridian(details?.end_date)}`;
                  })()}
                </span>
              );
          })()}
        </div>
      </div>
    </section>
  );
};

const StatusSection: FC<Pick<IDetail, "details">> = ({ details }) => {
  const status = details.status as BookingStatus;
  const date = new Date(details?.start_date);
  const bookingId = details.booking_id;
  const clockIn = details.clock_in;
  const { mutateAsync: clockUserIn, isLoading } = useClockinBookingMutation();
  const onClickHandler = async () => {
    if (status !== "clock in") return;
    if (new Date().getHours() < date.getHours()) {
      ToastNotify("info", "It is not yet time to clock in user");
      return;
    }
    const resp = await clockUserIn(bookingId);
    if (resp) ToastNotify("success", "Successfully clocked in user");
  };
  return (
    <section className="w-full">
      {status === "clock in" ? (
        <Button
          style={{
            width: "100%",
          }}
          loading={isLoading}
          label={<strong>{status}</strong>}
          onClick={onClickHandler}
          className={`w-full capitalize rounded ${
            status !== "clock in" ? "cursor-text" : "cursor-pointer"
          } h-12 bg-point-blue mb-8 overflow-auto scroller  px-[65px] flex justify-center whitespace-nowrap items-center py-0.5 text-white font-semibold text-xs leading-[160%] gap-2`}
        />
      ) : (
        <div className="w-full flex flex-col gap-3">
          <h4 className="font-medium text-[22px] leading-[140%] text-point-secondary-text">
            Clock in Data
          </h4>
          <div className="w-full justify-start items-center flex gap-1">
            <img
              src={Icons.dashboard.onseatgreenIcon}
              alt="representation of when on seat"
            />
            <strong className="font-medium text-sm leading-[160%] text-point-primary-text">
              Clocked in at{" "}
              {(() => {
                const date = new Date(clockIn);
                return `${pad(date.getHours(), 2)}:${pad(
                  date.getMinutes(),
                  2
                )} ${getMeridian(clockIn)}`;
              })()}
            </strong>
          </div>
          <div className="w-full flex justify-start items-center gap-1">
            <img
              src={Icons.dashboard.clockoutblackIcon}
              alt="representation of when on seat"
            />
            <strong className="font-medium text-sm leading-[160%] text-point-primary-text">
              Clocked out at {"12:00 am"}{" "}
              <span className="text-xs font-light leading-[160%] text-point-secondary-text">
                System Initiated
              </span>
            </strong>
          </div>
        </div>
      )}
    </section>
  );
};

export default DetailModal;
