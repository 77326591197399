import React, { FC, HTMLAttributes } from "react";
import PropTypes from "prop-types";

import styles from "./breadcrumbs.module.css";

/**
 * @param {string | JSX} separator what seperates various links
 * @param {number} maxItems this shows how many items we want to show, the other should be replaced with ellipses
 *
 * @returns {JSX.Element}
 */

interface IBread extends HTMLAttributes<HTMLElement> {
  separator: React.ReactNode;
  maxItems?: number;
  ignoreFirst: boolean;
}

const Breadcrumbs: FC<IBread> = ({
  separator = "/",
  maxItems,
  className,
  children,
  ignoreFirst,
  ...props
}) => {
  const Children = React.Children.toArray(children);
  let toDisplay = [];
  if (maxItems && Children.length > maxItems) {
    toDisplay.push(Children[0]);
    toDisplay.push(<p className={styles.more}>...</p>);
    toDisplay.push(Children.slice(Children.length - maxItems - 1));
  } else {
    toDisplay = Children;
  }
  toDisplay = toDisplay.map((child, ind) => {
    return ind === toDisplay.length - 1 ? (
      child
    ) : ind == 0 && ignoreFirst ? (
      <div className={`${styles.item}`} key={ind}>
        {child}
      </div>
    ) : (
      <div className={`${styles.item}`} key={ind}>
        {child}
        <p>{separator}</p>
      </div>
    );
  });
  return (
    <div className={`${styles.Breadcrumbs} ${className || ""}`} {...props}>
      {toDisplay}
    </div>
  );
};

Breadcrumbs.propTypes = {
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxItems: PropTypes.number,
};
Breadcrumbs.defaultProps = {
  ignoreFirst: false,
};
export default Breadcrumbs;
