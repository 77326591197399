import { addDataToQueryString } from "./../../../../utils/stringHelpers";
import {
  getSingleSpace,
  getSpaces as getSpacesEndpoint,
} from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import { useQuery } from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";

export const GET_SPACES = "GET_SPACES";
export const GET_SPACE = "GET_SPACE";

const getSpaces = async (
  search?: string,
  occupied?: boolean,
  page?: number,
  spaceType?: string,
  published?: boolean | string,
  booking?: number | string,
  re_assign?: boolean | string
) => {
  const qstring = addDataToQueryString(
    "",
    {
      page: page || 1,
      occupied: occupied === undefined ? "" : String(occupied),
      name: search,
      space_type: spaceType,
      published: published === undefined ? "" : String(published),
      re_assign: re_assign === undefined ? "" : String(re_assign),
      booking: !booking ? "" : String(booking),
    },
    true
  );
  try {
    const { data } = await axiosAction(
      "GET",
      getSpacesEndpoint(`?${qstring}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getASpace = async (spaceId: string) => {
  try {
    const { data } = await axiosAction(
      "GET",
      getSingleSpace(spaceId),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
export const useGetSpacesQuery = (
  search?: string,
  occupied?: boolean | string,
  page?: number,
  space_type?: "meeting room" | "work station" | "",
  published?: boolean | string,
  booking?: number | string,
  re_assign?: boolean | string
) => {
  return useQuery(
    [
      GET_SPACES,
      search,
      occupied,
      page,
      space_type,
      published,
      booking,
      re_assign,
    ],
    () =>
      getSpaces(
        search,
        occupied as boolean,
        page,
        space_type,
        published,
        booking,
        re_assign
      )
  );
};
export const useGetSpaceQuery = (spaceId: string, opts?: any) => {
  return useQuery([GET_SPACE, spaceId], () => getASpace(spaceId), opts);
};
