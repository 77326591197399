import React from "react";

function ErrorLayout(props: { children: React.ReactNode }) {
  const { children } = props;
  return (
    <div className="px-0 mx-0 relative overflow-hidden max-h-screen flex flex-col h-screen justify-start items-center w-full">
      <div className="px-[72px] py-4 fixed top-0 left-0 border border-solid border-transparent border-b-point-gray flex justify-between w-full z-50 bg-white">
        <img
          src="/companyLogo.png"
          alt="CompanyLogo"
          width="60px"
          height="37px"
        />
      </div>
      {children}
      <div className="flex-1 flex justify-end flex-col items-center w-full">
        <h4 className=" mb-8 uppercase  font-medium text-xs leading-normal text-point-secondary-text">
          &copy; Copyright Marriott All Rights Reserved.
        </h4>
      </div>
    </div>
  );
}

export default ErrorLayout;
