import SimpleTable from "../../../../components/tables/SimpleTable";
import useMismatchPages from "../../../../hooks/useMismatchPages";
import Button from "../../../../pages/auth/components/Button";
import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "../../../../utils/debounce";
import { getSerialNumber } from "../../../../utils/table";
import { ViewType } from "../../dashboard.types";
import EmptyTable from "./../EmptyTable";
import DetailModal from "./../Calendar/DetailModal";

import { IBooking } from "./../Calendar/MonthlyView";
import MonthViewList from "./MonthViewList";
import DailyViewList from "./DailyViewList";

const statusMap: Record<string, "clock in" | "On-Seat" | "clocked out"> = {
  "clock-in": "clock in",
  "on-seat": "On-Seat",
  "clocked-out": "clocked out",
};
const BookingLists = (props: {
  viewType: ViewType;
  currentDate: Date;
  calendarData: any;
  searchBooking?: string;
  isLoading?: boolean;
}) => {
  const { calendarData, currentDate, searchBooking, viewType, isLoading } =
    props;

  const [viewDetail, setViewDetail] = useState<any>(null);

  return (
    <div className="w-full">
      {viewType === "monthly" && (
        <MonthViewList calendarData={calendarData} currentDate={currentDate} />
      )}

      {viewType === "daily" && (
        <DailyViewList calendarData={calendarData} currentDate={currentDate} />
      )}
    </div>
  );
};

export default BookingLists;
