/* eslint-disable react/react-in-jsx-scope */
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function ToastNotify(
  type = "default",
  title = "",
  desc = "",
  position = "top-right"
) {
  const newLocal = "text-center";
  const message = (
    <div>
      <p className={newLocal}>{title}</p>
      <p className={newLocal}>{desc}</p>
    </div>
  );

  const body = {
    position,
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    newestOnTop: true,
  };

  switch (type) {
    case "success":
      toast.success(message, body);
      break;

    case "error":
      toast.error(message, body);
      break;

    case "info":
      toast.info(message, body);
      break;

    case "warning":
      toast.warning(message, body);
      break;

    case "dark":
      toast.dark(message, body);
      break;

    default:
      toast.info(message, body);
      break;
  }
}
