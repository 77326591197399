import React  from 'react';
import { RefObject, useCallback } from 'react';
import { useEffect, useMemo, useState } from 'react';

const useObserver = <T extends HTMLElement>(parentRef: RefObject<T>,  initialElemId ?: string, options={}): [string, React.Dispatch<React.SetStateAction<string>>] => {
    const [elemVisible, setElemVisible] = useState(initialElemId || "A");

    const cb = (entries: any[]) => {
        const [entry] = entries;
        
    if (entry.isIntersecting) {
      setElemVisible(entry.target.id);
    }
    };
    
      const observerOptions = useMemo(
    () => ({
      root: parentRef.current,
      rootMargin: "0px",
              threshold: 0.8,
      ...options
    }),
    [parentRef.current, options]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(cb, observerOptions);

      const children = parentRef.current?.childNodes || [];

    Array.prototype.forEach.call(children, (child, ind) => {
      observer.observe(child);
    });
    return () => {
      Array.prototype.forEach.call(children, (child, ind) => {
        observer.unobserve(child);
      });
    };
  }, [observerOptions, cb]);
    
  return [elemVisible, setElemVisible]
}

export default useObserver