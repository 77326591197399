import { login } from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import {
  MutateOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
} from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";

const loginApp = async (body: any) => {
  try {
    const { data } = await axiosAction("POST", login, HttpStatusCode.Ok, body);
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useLoginAppMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  return useMutation(loginApp, args);
};
