import React, { useState } from "react";
import { useNavigate } from "react-router";
import { isValidEmail } from "../../utils/stringHelpers";
import { Icons } from "../../assets/icons";
import { RouteAwareCrumbs } from "../../components/breadcrumbs";
import InputWithAdornment from "../../components/Input/InputWithAdornment";
import Modal from "../../components/modal/Modal";
import Button from "./components/Button";
import FormGroup from "./components/FormGroup";
import { useForgotPasswordMutation } from "./utils/api/forgotPassword";
import { Storage } from "../../store/Storage";

const NameMap = {
  "/auth/recover-password": "Forgot password",
  "/auth": "Sign In",
};
function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { mutateAsync: resetPasswordAction } = useForgotPasswordMutation();
  const sendResetLink = async () => {
    setLoading(true);
    const res = await resetPasswordAction({
      email_address: email,
    });
    if (res) {
      setLoading(false);
      setShowModal(true);
      if (Storage.getItem("REMEMBER_LOGIN")) {
        Storage.removeItem("REMEMBER_LOGIN_PASSWORD");
        Storage.removeItem("REMEMBER_LOGIN_EMAIL");
      }
    }
  };
  return (
    <div className="w-full  mt-14">
      <RouteAwareCrumbs nameMap={NameMap} />
      <form
        className="w-3/5 flex flex-col justify-center items-start gap-[56px] mt-14"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="w-full">
          <h1>Forgot Password</h1>
          <p className="sub-heading">
            Please enter your email address so we can send you an email to reset
            your password.
          </p>
        </div>
        <div className="w-full flex flex-col gap-5">
          <FormGroup label="Email" id="email">
            <InputWithAdornment
              name="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="example@example.com"
              error={
                (!isValidEmail(email) as unknown as string) &&
                "Enter a valid email"
              }
            />
          </FormGroup>
        </div>
        <div className="w-full mt-12">
          <Button
            loading={loading}
            label="Send Reset Link"
            fullWidth
            onClick={sendResetLink}
          />
        </div>
      </form>
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setEmail("");
        }}
        className="top-[378px]"
      >
        <div className="py-7 w-full pl-7 pr-2 flex flex-col gap-8">
          <div className="w-full flex items-center justify-between">
            <h2 className="font-medium text-[28px] leading-8">
              Check your mail
            </h2>
            <button
              onClick={() => {
                setShowModal(false);
                setEmail("");
              }}
            >
              <img src={Icons.utility.cancelIcon} alt="cancel" />
            </button>
          </div>
          <p className="text-point-secondary-text font-light leading-[160%] text-base">
            A reset link has been sent to{" "}
            <strong className="font-medium">&ldquo;{email}&rdquo; </strong>. If
            you didn&apos;t receive a mail, please restart this process.
          </p>
          <Button
            onClick={() => navigate("/auth/sign-in")}
            className="capitalize w-[210px] px-4 text-base"
            label="back to sign in"
          />
        </div>
      </Modal>
    </div>
  );
}

export default ForgotPassword;
