import React from 'react';
import styles from './circular.module.css';
function CircularProgress({color="#ffffff", size = '25px'}) {
    return (
        <span style={{width:size, height:size, color}}>
            <span className={styles["lds-ring"]} style={{borderTopColor:color}}>
                <span style={{borderTopColor:color}}></span><span style={{borderTopColor:color}}></span><span style={{borderTopColor:color}}></span><span style={{borderTopColor:color}}></span>
            </span>
        </span>
    );
}

export default CircularProgress;