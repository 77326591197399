export const isValidEmail = (email) => {
  return email.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
  );
};

export const isEmpty = (str) => {
  if (typeof str !== "string") return !str;
  return !str.trim();
};

export function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export const redact = (str) => {
  if (typeof str !== "string") return str;
  return `${str.slice(0, 3)}*****${str.slice(str.length - 4)}`;
};

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export const capitalize = (str) => {
  if (typeof str !== "string" || !str) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export function ucword(string) {
  if (!string || typeof string !== "string") return "";
  return string.split(" ").map(capitalize).join(" ");
}
export const getMultiplier = (num) => {
  console.log(num, "NUMBER", typeof num !== "number");
  if (typeof num !== "number") return num;
  if (num > 1000000) {
    return (num / 1000000).toFixed(2) + "m";
  } else if (num > 1000) {
    return (num / 1000).toFixed(2) + "k";
  } else {
    return num;
  }
};
export const currencyFormat = (number) => {
  if (isNaN(Number(number))) return number;
  return Number(number)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const merge = (...args) => {
  return args.reduce((acc, cur) => {
    return {
      ...acc,
      ...cur,
    };
  }, {});
};

export const addDataToQueryString = (url, data, reset) => {
  if (typeof url !== "string") return url;

  let urlParams = new URLSearchParams(url);

  for (let key in data) {
    if (Array.isArray(data[key])) {
      let oldValues = urlParams.get(key);
      let fullValues = [];
      if (oldValues && !reset) {
        oldValues = oldValues.split(",");
        fullValues = oldValues.concat(data[key]);

        urlParams.set(
          key,
          Array.from(new Set(fullValues.map((item) => item.toString()))).join(
            ","
          )
        );
      } else if (data[key].length > 0) {
        urlParams.set(key, data[key].join(","));
      } else {
        urlParams.delete(key);
      }
    } else if (data[key] != "") {
      urlParams.set(key, data[key]);
    } else urlParams.delete(key);
  }

  return decodeURIComponent(urlParams.toString());
};

export function pad(number, length) {
  var str = "" + number;
  while (str.length < length) {
    str = "0" + str;
  }
  return str;
}

export const verifyNigerianPhoneNumber = (phone, should_return_data) => {
  if (typeof phone !== "string" && typeof phone !== "number") return false;
  let allDigitPhone = phone.replace(/\D/g, "");
  if (
    !/^([0]{1}|\+?[234]{3})([7-9]{1})([0|1]{1})([\d]{1})([\d]{7})$/g.test(
      allDigitPhone.toString()
    )
  ) {
    return false;
  }
  if (
    typeof should_return_data === "undefined" ||
    should_return_data === false
  ) {
    return true;
  }
  if (allDigitPhone.startsWith("0") || allDigitPhone.startsWith("+")) {
    allDigitPhone = allDigitPhone.substring(1);
  }
  if (allDigitPhone.startsWith("234")) {
    return `+${allDigitPhone}`;
  }
  return `+234${allDigitPhone}`;
};
