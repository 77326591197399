import React, { FC } from "react";

const StatusIndicator: FC<{
  status: "booked" | "selected day" | "selected hour" | "today";
}> = ({ status }) => {
  const colorMap = {
    booked: "pink-dark",
    "selected day": "blue",
    "selected hour": "blue",
    today: "green-dark",
  };
  const titleMap = {
    booked: "Booked Day",
    "selected day": "Selected day",
    "selected hour": "Selected hour",
    today: "today",
  };
  return (
    <div className="flex px-3 justify-center gap-1 items-center font-medium text-[10px] leading-[160%] uppercase tracking-[0.04rem]">
      <span
        className={`w-1 h-1 rounded-full bg-point-${colorMap[status]}`}
      ></span>
      <strong className={`text-point-${colorMap[status]}`}>
        {" "}
        {titleMap[status]}
      </strong>
    </div>
  );
};

export default StatusIndicator;
