import Modal from "../../../components/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import { Icons } from "../../../assets/icons";
import Connector from "./../components/Connector";
import ContactInformation from "../components/BookSpace/ContactInformation";
import SelectPackageType from "../components/BookSpace/SelectPackageType";
import MakePayment from "../components/BookSpace/MakePayment";
import { FormikHelpers, useFormik } from "formik";
import createWorkStationSchema from "../utils/createworkstation.validator";
import { useGetPackagesQuery } from "../../../pages/packages/utils/apis/getPackages";
import SelectWorkStation from "../components/BookSpace/SelectWorkStations";

import {
  INewBooking,
  useCreateBookingMutation,
} from "../utils/apis/getDashboard";

import BookSpaceSuccessful from "./components/BookSpaceSuccessful";
import FormActions from "./components/FormActions";

interface IBook {
  open: boolean;
  onClose: () => void;
}
function BookWorkStation(props: IBook) {
  const { open, onClose } = props;
  const [showCompleteBook, setShowCompleteBook] = useState(false);
  const [response, setResponse] = useState<INewBooking & { id?: number }>();
  useEffect(() => {
    setShowCompleteBook(false);
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="top-[94px] max-h-[786px] overflow-scroll scroller"
      variant={showCompleteBook ? "sm" : "md"}
      disableBackdrop={showCompleteBook}
    >
      {!showCompleteBook ? (
        <CreateSpace
          onClose={onClose}
          open={open}
          setShowCompleteBook={setShowCompleteBook}
          setResponse={setResponse}
        />
      ) : (
        <BookSpaceSuccessful
          payload={response}
          onClose={onClose}
          type="work station"
        />
      )}
    </Modal>
  );
}

const CreateSpace: FC<
  Pick<IBook, "onClose" | "open"> & {
    setShowCompleteBook: React.Dispatch<React.SetStateAction<boolean>>;
    setResponse: React.Dispatch<
      React.SetStateAction<
        | (INewBooking & {
            id?: number | undefined;
          })
        | undefined
      >
    >;
  }
> = ({ onClose, open, setShowCompleteBook, setResponse }) => {
  const { data: allPackages } = useGetPackagesQuery({
    page: 1,
    space_type: "work station",
  });

  return (
    <section className="px-6 py-[33px] flex flex-col gap-[71px]">
      <BookSpaceHeader onClose={onClose} />
      <CreateBookSpaceForm
        open={open}
        onFinal={(resp) => {
          setShowCompleteBook(true);
          setResponse(resp);
        }}
      />
    </section>
  );
};

const BookSpaceHeader: FC<Pick<IBook, "onClose">> = ({ onClose }) => {
  return (
    <header className="w-full flex flex-col gap-2">
      <div className="flex justify-end w-full">
        <button onClick={onClose}>
          <img src={Icons.utility.cancelIcon} alt="Cancel Modal" />
        </button>
      </div>
      <div>
        <h2 className="text-point-primary-text font-medium text-[24px] leading-[140%]">
          Book Work Station
        </h2>
        <p className="font-medium text-base leading-[180%] text-point-secondary-text">
          Secure an available work station now for the customers
        </p>
      </div>
    </header>
  );
};

const CreateBookSpaceForm: FC<
  Pick<IBook, "open"> & { onFinal: (payload: INewBooking) => void }
> = ({ open, onFinal }) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    customer_type: "resident",
    phone_number: "",
    package: "",
    space: "",
    start_date: "",
    end_date: "",
    num_of_packages: "",
    payment_type: "",
  };
  const totalStage = 4;
  const [currentStage, setCurrentStage] = useState(1);
  useEffect(() => {
    setCurrentStage(1);
    setValues(initialValues);
  }, [open]);
  const { mutateAsync: createBooking, isLoading: isCreating } =
    useCreateBookingMutation();
  const submitHandler = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    const resp = await createBooking(values);

    if (resp) {
      onFinal?.(resp);
    }
    setSubmitting(true);
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    onSubmit: submitHandler,
    validationSchema: createWorkStationSchema(currentStage),
    initialValues: initialValues,
  });

  return (
    <main className="w-full flex flex-col justify-start gap-[50px]">
      <Connector
        current={currentStage}
        total={totalStage}
        values={[
          { label: "Customer Info" },
          { label: "Package Type" },
          { label: "Work Station" },
          { label: "Payment" },
        ]}
      />
      <form
        onSubmit={handleSubmit}
        className="mt-[40px] flex flex-col justify-start gap-[50px] mb-20"
      >
        {currentStage === 1 && (
          <ContactInformation
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            touched={touched}
          />
        )}
        {currentStage === 2 && (
          <SelectPackageType
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            type="work station"
          />
        )}
        {currentStage === 3 && (
          <SelectWorkStation
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        )}
        {currentStage === 4 && (
          <MakePayment
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        )}

        <FormActions
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
          totalStage={totalStage}
          isSubmitting={isCreating || isSubmitting}
          isValid={
            (() => (currentStage === 3 ? Boolean(values.space) : true))() &&
            (() => (currentStage < 3 ? isValid : true))() &&
            (() => (currentStage === 4 ? Boolean(values.payment_type) : true))()
          }
        />
      </form>
    </main>
  );
};

export default BookWorkStation;
