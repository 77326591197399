import { useCallback } from "react";

const { useState, useEffect } = require("react");

const useTable = (data: any[], page: number, rowsPerPage: number) => {
  const calculateRange = useCallback(
    (data: any[], rowsPerPage: number) => {
      const range = [];
      const num = Math.ceil(data.length / rowsPerPage);
      let i = 1;
      for (let i = 1; i <= num; i++) {
        range.push(i);
      }
      return range;
    },
    [data, page, rowsPerPage]
  );

  const sliceData = useCallback(
    (data: any[], page: number, rowsPerPage: number) => {
      return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
    },
    [data, page, rowsPerPage]
  );

  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);

  useEffect(() => {
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const newslice = sliceData(data, page, rowsPerPage);
    setSlice([...newslice]);
  }, [data, page, rowsPerPage, calculateRange, sliceData]);

  return { slice, range: tableRange };
};

export default useTable;
