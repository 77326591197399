import { TOKEN_KEY } from "../apis/constants";
import { baseAPIURL } from "../constants/api";
import { useState, useCallback } from "react";

import { Storage } from "../store/Storage";

const useDownload = (deps?: any) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [doneDownloading, setDoneDownloading] = useState(false);

  const exportHandler = useCallback(
    async (
      endpoint: string,
      options: { file_type: string; file_name?: string }
    ) => {
      setIsDownloading(true);
      setDoneDownloading(false);
      const token = Storage.getItem(TOKEN_KEY);

      fetch(baseAPIURL + endpoint, {
        method: "GET",
        headers: {
          authorization: `Token ${token || ""}`,
          "Content-Type": "application/json",
          // "accept": "text/plain"
        },
      })
        .then((res) => {
          return res.blob();
        })
        .then((str) => {
          var url = window.URL || window.webkitURL;
          var link = url.createObjectURL(str);
          var a = document.createElement("a");
          a.setAttribute(
            "download",
            `${options.file_name || "downloaded at" + new Date().getTime()}.${
              options.file_type !== "excel" ? options.file_type : "xlsx"
            }`
          );
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(link);
          setIsDownloading(false);
          setDoneDownloading(true);
        })
        .catch((err) => {
          setIsDownloading(false);
          setDoneDownloading(false);
        });
    },
    [deps]
  );

  return {
    exportHandler,
    isDownloading,
    doneDownloading,
  };
};

export default useDownload;
