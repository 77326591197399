import { getPackages as getPackagesEndpoint } from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import { useQuery } from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";
import { addDataToQueryString } from "../../../../utils/stringHelpers";

export const GET_PACKAGES = "GET_PACKAGES";
const getPackages = async (body: any) => {
  const queryString = addDataToQueryString("", body, true);

  try {
    const { data } = await axiosAction(
      "GET",
      getPackagesEndpoint(`?${queryString}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useGetPackagesQuery = <T = any>(body: any) => {
  return useQuery<T>([GET_PACKAGES, { ...body }], () => getPackages(body), {
    refetchOnMount: true,
  });
};
