import React from "react";
import { Navigate, Outlet } from "react-router";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Storage } from "../store/Storage";
import { CURRENT_USER } from "../apis/constants";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add("scroller");
  }, [pathname]);

  return null;
}

function PublicRoutes() {
  const currentUser = Storage.getItem(CURRENT_USER);
  return !currentUser ? (
    <>
      <ScrollToTop />
      <Outlet />
    </>
  ) : (
    <Navigate to="/app/dashboard" />
  );
}

export default PublicRoutes;
