import { Icons } from "../../../../assets/icons";
import useDisplayDays from "../../../../pages/dashboard/hooks/useDisplayDays";
import React, { FC, useState } from "react";
import {
  days,
  getDateString,
  isDayInBetweenDaysInclusive,
  isNextMonth,
  isPrevDays,
  isPrevMonth,
  isToday,
  months,
} from "../../../../utils/dateHelper";

const SingleMonth: FC<{
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  disabled: boolean;
  selectedStartDate: Date | null;
  setSelectedStartDate?: React.Dispatch<React.SetStateAction<Date | null>>;
  onClick?: (date: Date) => void;
  onHover?: (date: Date) => void;
  onHoverOut?: () => void;
  selectedEndDate: Date | null;
  bookedDays?: Date[];
}> = (props) => {
  const {
    date,
    setDate,
    selectedStartDate,
    setSelectedStartDate,
    disabled,
    selectedEndDate,
    onClick,
    onHover,
    onHoverOut,
    bookedDays,
  } = props;
  const displayableDays = useDisplayDays(date);
  const [internalEndDate, setInternalEndDate] = useState(selectedEndDate);

  const goToNextMonth = () => {
    if (disabled) return;
    setDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          prev.getMonth() + 1,
          prev.getDate(),
          prev.getHours()
        )
    );
  };
  const goToPrevMonth = () => {
    if (disabled) return;
    setDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          prev.getMonth() - 1,
          prev.getDate(),
          prev.getHours()
        )
    );
  };

  return (
    <div className="p-5 flex flex-col justify-start items-start gap-4 w-[420px]  max-w-[50%]">
      <div className="w-full flex justify-between items-center">
        <button onClick={goToPrevMonth} type="button">
          <img
            src={Icons.dashboard.arrowLeftDashboard}
            alt="Move to previous month"
          />
        </button>
        <h2 className="text-sm font-medium leading-[160%] text-point-primary-text">
          {months[date.getMonth()]}
          {"  "}
          {date.getFullYear()}
        </h2>
        <button onClick={goToNextMonth} type="button">
          <img
            src={Icons.dashboard.arrowRightDashboard}
            alt="Move to next month"
          />
        </button>
      </div>
      <main id="mainbody" role="grid" className="w-full">
        <div
          className="grid grid-cols-7 w-full"
          role="group"
          aria-label="Days of Week"
        >
          {days.map((day) => {
            const res = day.slice(0, 3);
            return (
              <div
                role="gridcell"
                key={day}
                className="col-span-1 flex justify-center items-center bg-white text-point-primary-text font-light text-sm  leading-[160%]"
              >
                {res}
              </div>
            );
          })}
        </div>
        <div
          role="group"
          className="w-full grid grid-cols-7 grid-rows-5 grid-flow-row gap-x-0 gap-y-1"
        >
          {displayableDays.map((day: Date, ind: number) => {
            const isBooked = Boolean(
              bookedDays?.find((bookedDay) => {
                return getDateString(bookedDay) === getDateString(day);
              })
            );

            return (
              <button
                type="button"
                onMouseEnter={() => {
                  if (!selectedStartDate || disabled) return;
                  if (selectedStartDate && selectedEndDate) return;
                  setInternalEndDate(day);
                }}
                onMouseOut={() => (disabled ? null : setInternalEndDate(null))}
                onClick={(e) => {
                  if (
                    isPrevMonth(date, day) ||
                    isNextMonth(date, day) ||
                    isBooked
                  )
                    return;
                  onClick ? onClick(day) : setSelectedStartDate?.(day);
                }}
                style={
                  isBooked
                    ? {
                        color: "var(--pink-dark)",
                      }
                    : {}
                }
                disabled={disabled || isPrevDays(day)}
                key={getDateString(day)}
                className={`flex col-span-1 flex-col justify-center items-center 
                    h-11 rounded-sm 
                border border-point-input-bg font-medium text-xs leading-[160%] px-2 py-3
                
                ${
                  isPrevDays(day)
                    ? "text-point-secondary-text font-light bg-white rounded-none"
                    : "text-point-secondary-bg"
                }
                
                ${
                  isToday(day)
                    ? "border-point-green-dark rounded-xl text-point-primary-text"
                    : ""
                } 
                    ${
                      isBooked
                        ? "text-point-pink-dark cursor-text hover:!bg-white !bg-white hover:rounded-none"
                        : ""
                    }
                    ${
                      (selectedEndDate || internalEndDate) && selectedStartDate
                        ? isDayInBetweenDaysInclusive(
                            day,
                            selectedStartDate,
                            (selectedEndDate || internalEndDate) as Date
                          )
                          ? getDateString(selectedStartDate) ===
                              getDateString(day) ||
                            getDateString(
                              (selectedEndDate || internalEndDate) as Date
                            ) === getDateString(day) ||
                            isNextMonth(date, day) ||
                            isPrevMonth(date, day)
                            ? ""
                            : "bg-[#D3E2EE] hover:disabled:bg-[#D3E2EE] rounded-tr-none rounded-br-none"
                          : ""
                        : ""
                    }
                    ${
                      selectedStartDate
                        ? getDateString(selectedStartDate) ===
                          getDateString(day)
                          ? "bg-[#1C3C54] text-[#ffffff] rounded-tr-none rounded-br-none rounded-tl-2xl rounded-bl-2xl hover:disabled:bg-[#1C3C54] hover:rounded-tr-none hover:rounded-br-none"
                          : ""
                        : ""
                    }
                    ${
                      selectedEndDate || internalEndDate
                        ? getDateString(
                            (selectedEndDate || internalEndDate) as Date
                          ) === getDateString(day)
                          ? isNextMonth(date, day) || isPrevMonth(date, day)
                            ? ""
                            : "bg-[#1C3C54] text-[#ffffff] rounded-tr-2xl rounded-br-2xl hover:disabled:rounded-tr-2xl hover:disabled:rounded-br-2xl hover:disabled:bg-[#1C3C54] hover:disabled:text-white"
                          : ""
                        : ""
                    }
                    hover:bg-[#1C3C54] hover:rounded-2xl hover:text-white 
                    hover:disabled:rounded-none hover:disabled:bg-white hover:disabled:text-point-primary-text
                    disabled:hover:text-point-secondary-text disabled:hover:cursor-text
                    ${
                      isPrevMonth(date, day) || isNextMonth(date, day)
                        ? "hover:!bg-white hover:rounded-none"
                        : ""
                    }
                `}
              >
                {isPrevMonth(date, day) || isNextMonth(date, day)
                  ? ""
                  : day.getDate()}
              </button>
            );
          })}
        </div>
      </main>
    </div>
  );
};

export default SingleMonth;
