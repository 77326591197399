import { useClickOutside } from "../../../../hooks/useOutsideClick";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  convertToLocalTime,
  daysInMonth,
  getDateString,
  getDaysBetweenInclusive,
  getNumberOfDays,
} from "../../../../utils/dateHelper";
import SingleMonth from "./SingleMonth";
import StatusIndicator from "./StatusIndicator";
import { useGetCustomerBookingsQuery } from "../../../../pages/customers/utils/apis/bookings";
import { DatesTypes } from "../../../../pages/dashboard/utils/apis/getOccupiedSpaces";
import { ToastNotify } from "../../../../utils/toastNotify";

const DailyViewOfPackage: FC<{
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}> = (props) => {
  const { type, values, setFieldValue } = props;
  const today = new Date();
  const isInThesameMonth = () => {
    const daysCount = daysInMonth(today.getFullYear())[today.getMonth()];
    return daysCount > today.getDate() + 30;
  };
  return (
    <div className="flex flex-col gap-6 justify-start items-start w-full">
      <h1 className="font-light text-sm leading-[180%] text-point-secondary-text">
        Daily Booking (1 Day)
      </h1>
      <div className="w-full flex flex-col justify-start gap-2 ">
        <div
          className={`w-full flex items-center gap-3 ${
            isInThesameMonth() ? "justify-start" : "justify-center"
          }`}
        >
          {type === "meeting room" && <StatusIndicator status="booked" />}
          <StatusIndicator status="selected day" />
          <StatusIndicator status="today" />
        </div>
        <MonthCalendar
          currentDate={today}
          sameMonth={isInThesameMonth()}
          type={type}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
    </div>
  );
};

const MonthCalendar: FC<{
  currentDate: Date;
  sameMonth: boolean;
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
  type: "meeting room" | "work station";
}> = ({ currentDate, sameMonth, type, setFieldValue, values }) => {
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate()
    )
  );

  useEffect(() => {
    setStartDate(currentDate);
    setEndDate(
      new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      )
    );
  }, [currentDate.getDate()]);

  useEffect(() => {
    if (!selectedStartDate || !selectedEndDate) return;
    setFieldValue?.(
      "start_date",
      selectedStartDate ? selectedStartDate.toISOString() : ""
    );
    setFieldValue?.(
      "end_date",
      selectedEndDate ? selectedEndDate.toISOString() : ""
    );
    setFieldValue?.("num_of_packages", startDate || endDate ? 1 : 0);
    if (selectedStartDate && selectedEndDate) {
      setFieldValue?.(
        "num_of_packages",
        getNumberOfDays(selectedStartDate, selectedEndDate) + 1
      );
    }
  }, [selectedEndDate, selectedStartDate]);

  useEffect(() => {
    if (!values || !(values?.start_date && values?.end_date)) {
      setFieldValue?.("num_of_packages", "");
      setFieldValue?.("start_date", "");
      setFieldValue?.("end_date", "");
      return;
    }
    const { start_date, end_date } = values;
    const startDate = convertToLocalTime(start_date) as Date;
    const endDate = convertToLocalTime(end_date) as Date;
    console.log(startDate.toISOString(), endDate.toISOString(), "HERE WE ARE");
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setStartDate(startDate);
    setEndDate(
      endDate.getMonth() === startDate.getMonth()
        ? new Date(
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            endDate.getDate()
          )
        : endDate
    );
  }, [values?.start_date, values?.end_date]);
  const calendarRef = useRef<HTMLDivElement | null>(null);

  useClickOutside([calendarRef], () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  });
  const { data: bookedTimes } = useGetCustomerBookingsQuery(
    {
      package: values?.package,
      space: values?.space,
      available_space: true,
    },
    {
      enabled: Boolean(values?.package) || type === "meeting room",
    }
  );
  const bookedDays = useMemo(() => {
    if (!bookedTimes || !values?.space) return [];

    return (
      ((values?.space ? bookedTimes : bookedTimes.results) ||
        []) as DatesTypes[]
    ).reduce((acc: Date[], cur) => {
      const { start_date, end_date } = cur;

      const startDate = convertToLocalTime(start_date) as Date;
      const endDate = convertToLocalTime(end_date) as Date;
      const dates = getDaysBetweenInclusive(startDate, endDate);
      return acc.concat(dates);
    }, []);
  }, [bookedTimes]);

  const clickHandler = (date: Date) => {
    if (!selectedStartDate) {
      setSelectedStartDate(date);
      setSelectedEndDate(null);
    } else if (selectedEndDate && selectedStartDate) {
      setSelectedStartDate(date);
      setSelectedEndDate(null);
    } else if (selectedStartDate) {
      if (bookedDays[0]) {
        const daysFromStartToEnd = getDaysBetweenInclusive(
          date.getTime() < selectedStartDate.getTime()
            ? date
            : selectedStartDate,
          date.getTime() < selectedStartDate.getTime()
            ? selectedStartDate
            : date
        );
        if (
          daysFromStartToEnd.find((day) =>
            bookedDays.find(
              (bookedDay) => getDateString(day) === getDateString(bookedDay)
            )
          )
        ) {
          ToastNotify("info", "You can not book a date between booked dates");
          return;
        }
      }
      if (date.getTime() < selectedStartDate.getTime()) {
        setSelectedStartDate(date);
        setSelectedEndDate(selectedStartDate);
      } else {
        setSelectedEndDate(date);
      }
    }
  };

  return (
    <div className="flex w-full justify-between items-start" ref={calendarRef}>
      <SingleMonth
        disabled={false}
        date={startDate}
        setDate={setStartDate}
        selectedStartDate={selectedStartDate}
        setSelectedStartDate={setSelectedStartDate}
        onClick={clickHandler}
        selectedEndDate={selectedEndDate}
        bookedDays={bookedDays}
      />
      <SingleMonth
        disabled={false}
        date={endDate}
        setDate={setEndDate}
        selectedStartDate={selectedStartDate}
        onClick={clickHandler}
        setSelectedStartDate={setSelectedStartDate}
        selectedEndDate={selectedEndDate}
        bookedDays={bookedDays}
      />
    </div>
  );
};

export default DailyViewOfPackage;
