import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router";
import MissingPage from "../pages/errors/MissingPage";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import AuthRoute from "../pages/auth";
import SignIn from "../pages/auth/SignIn";
import App from "../App";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Dashboard from "../pages/dashboard";
import Unauthorize from "../pages/errors/Unauthorize";
import ErrorLayout from "../layouts/error";

const PendingVerification = lazy(
  () => import("../pages/dashboard/frontdesk/PendingVerification")
);
const PendingRefunds = lazy(
  () => import("../pages/dashboard/frontdesk/PendingRefund")
);
const Users = lazy(() => import("../pages/users"));
const Spaces = lazy(() => import("../pages/spaces"));
const Packages = lazy(() => import("../pages/packages"));
const Customers = lazy(() => import("../pages/customers"));
const ChatRoom = lazy(() => import("../pages/customers/ChatRoom"));
const CustomerDetail = lazy(() => import("../pages/customers/CustomerDetail"));

const AuditLogs = lazy(() => import("../pages/audits"));
const Reports = lazy(() => import("../pages/analytics/reports"));
const Metrics = lazy(() => import("../pages/analytics/metrics"));
const UserProfile = lazy(() => import("../pages/profiles/UserProfile"));
const Broadcast = lazy(() => import("../pages/dashboard/admin/Broadcast"));
const SpaceDetails = lazy(() => import("../pages/spaces/SpaceDetails"));

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PublicRoutes />}>
        <Route index element={<Navigate to="/auth/sign-in" />} />
        <Route path="auth" element={<AuthRoute />}>
          <Route index element={<Navigate to="/auth/sign-in" />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="recover-password" element={<ForgotPassword />} />
        </Route>
      </Route>

      {/* auth routes */}
      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/app" element={<App />}>
          <Route index element={<Navigate to="/app/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="dashboard/broadcast" element={<Broadcast />} />
          <Route
            path="dashboard/pending-verification"
            element={<PendingVerification />}
          />
          <Route path="dashboard/refunds" element={<PendingRefunds />} />
          <Route path="spaces/:title" element={<SpaceDetails />} />
          <Route path="spaces" element={<Spaces />} />
          <Route path="packages" element={<Packages />} />
          <Route path="customers" element={<Customers />} />
          <Route path="customers/chat" element={<ChatRoom />} />
          <Route path="customers/:fullName" element={<CustomerDetail />} />
          <Route path="audit-logs" element={<AuditLogs />} />
          <Route path="analytics/reports" element={<Reports />} />
          <Route path="analytics/metrics" element={<Metrics />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="users" element={<Users />} />
          <Route path="unauthorized" element={<Unauthorize />} />
        </Route>
        <Route
          path="*"
          element={
            <ErrorLayout>
              <MissingPage />
            </ErrorLayout>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <ErrorLayout>
            <MissingPage />
          </ErrorLayout>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
