export enum HttpStatusCode {
  Ok = 200,
  Accepted = 203,
  Created = 201,
  No_Response = 204,
}

export const TOKEN_KEY = "CURRENT_USER_TOKEN";
export const CURRENT_USER = "CURRENT_USER";
export const USER_PERMISSIONS = "USER_PERMISSION";
