import SimpleTable from "../../../../components/tables/SimpleTable";
import Button from "../../../../pages/auth/components/Button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  convertToLocalTime,
  getDateString,
  getMeridian,
  isDayInBetweenDaysInclusive,
  isToday,
} from "../../../../utils/dateHelper";
import { IBooking } from "../Calendar/MonthlyView";
import EmptyTable from "../EmptyTable";

import StatusButton from "../Calendar/StatusButton";
import { useClockinBookingMutation } from "../../../../pages/dashboard/utils/apis/getDashboard";
import { ToastNotify } from "../../../../utils/toastNotify";
import { pad } from "../../../../utils/stringHelpers";
import DetailModal from "../Calendar/DetailModal";

const statusMap: Record<string, "clock in" | "On-Seat" | "clocked out"> = {
  "clock-in": "clock in",
  "on-seat": "On-Seat",
  "clocked-out": "clocked out",
};
const DailyViewList = (props: {
  currentDate: Date;
  calendarData: any;
  searchBooking?: string;
  isLoading?: boolean;
}) => {
  const { calendarData, currentDate, searchBooking, isLoading } = props;
  const [viewDetail, setViewDetail] = useState<any>(null);
  const fullData = useMemo<IBooking[]>(() => {
    if (!calendarData) return [];
    return calendarData.reduce((acc: IBooking[], cur: any) => {
      return acc.concat(
        cur.bookings.map((el: any) => ({
          ...el,
          space: {
            name: cur.name,
            space_type: cur.space_type,
          },
        }))
      );
    }, []);
  }, [calendarData]);

  const { mutateAsync: clockUserIn, isLoading: isClockIn } =
    useClockinBookingMutation();

  const clockInHandler = async (data: IBooking) => {
    if (data.clock_status !== "clock-in") return;
    if (!isToday(currentDate)) {
      return ToastNotify("info", "You can't clock in if not in that day ");
    }
    const resp = await clockUserIn(data.booking_id);
    if (resp) ToastNotify("success", "Successfully clocked in user");
  };

  const showDetailHandler = (data: any) => {
    setViewDetail(data);
  };

  return (
    <div className="w-full h-full">
      <SimpleTable
        perPage={10}
        heading
        style={{ width: "100%" }}
        className="w-full"
        loading={isLoading}
        columns={[
          {
            title: "S/N",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              borderRadius: "8px 0 0 0",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
              maxWidth: "50px",
            },
            field: "sn",
            cellStyle: {
              paddingLeft: "20px",
              color: "var(--secondary-text)",
            },
          },
          {
            title: "Customer Name",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "600",
              minWidth: "160px",
              color: "#666",
              textTransform: "capitalize",
            },
            field: "customer_name",
            renderItem: (rowData) => {
              return (
                <span
                  onClick={() => showDetailHandler(rowData)}
                  className="hover:underline cursor-pointer hover:text-point-primary-text"
                >
                  {rowData.customer_name}
                </span>
              );
            },
          },

          {
            title: "Space Name",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "160%",
              fontWeight: "500",

              color: "var(--primary-text)",
              minWidth: "220px",
              textTransform: "capitalize",
            },
            field: "space_name",
            renderItem: (rowData) => {
              return (
                <span
                  onClick={() => showDetailHandler(rowData)}
                  className="hover:underline cursor-pointer hover:text-point-primary-text"
                >
                  {rowData.space_name}
                </span>
              );
            },
          },
          {
            title: "Space Type",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "600",
              minWidth: "160px",
              color: "#666",
              textTransform: "capitalize",
            },
            field: "space_type",
          },
          {
            title: "Time",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: "600",
              minWidth: "160px",
              color: "#666",
            },
            field: "time",
          },

          {
            title: "Status/Action",
            headerStyle: {
              borderBottom: "1px solid #D0D5DD",
              backgroundColor: "#FCFCFC",
              padding: "13px 25px 13px 16px",
              color: "#979797",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "180%",
              height: "55px",
            },
            cellStyle: {
              fontSize: "14px",
              lineHeight: "160%",
              fontWeight: "600",
              minWidth: "195px",
            },
            field: "action",
            renderItem: (rowData) => {
              const statusMap: Record<
                string,
                "clock in" | "On-Seat" | "clocked out"
              > = {
                "clock-in": "clock in",
                "on-seat": "On-Seat",
                "clocked-out": "clocked out",
              };
              return (
                <StatusButton
                  onClick={() =>
                    rowData.status === "clock-in"
                      ? clockInHandler(rowData)
                      : null
                  }
                  status={statusMap[rowData.status]}
                  className="py-0.5 gap-1 rounded h-9 w-[101px]"
                />
              );
            },
          },
        ]}
        data={fullData
          .sort((a: any, b: any) => {
            if (a.space.space_type === b.space.space_type) {
              return a.space.name.localeCompare(b.space.name);
            }
            return a.space.space_type.localeCompare(b.space.space_type);
          })
          .map((el, idx) => ({
            sn: idx + 1,
            ...el,
            customer_name: `${el.customer?.first_name} ${el.customer?.last_name}`,
            space_name: `${el.space.name}`,
            space_type: el.space.space_type,
            status: el.clock_status,
            time: (() => {
              const Package = el.package;
              if (
                Package.package_type === "month" ||
                Package.package_type === "day"
              )
                return "All Day";
              return `${pad(
                new Date(el.start_date).getHours() === 0 ||  new Date(el.start_date).getHours() === 12
                  ? 12
                  : new Date(el.start_date).getHours() % 12,
                2
              )}: ${pad(
                new Date(el.start_date).getMinutes(),
                2
              )}  ${getMeridian(el.start_date).toLowerCase()} - ${pad(
                new Date(el.end_date).getHours() === 0 ||  new Date(el.start_date).getHours() === 12
                  ? 12
                  : new Date(el.end_date).getHours() % 12,
                2
              )} : ${pad(new Date(el.end_date).getMinutes(), 2)} ${getMeridian(
                el.end_date
              ).toLowerCase()}`;
            })(),
          }))}
        emptyComponent={
          <EmptyTable
            title={
              searchBooking
                ? "No Space with that search criteria"
                : "No Spaces has been booked"
            }
          />
        }
        footerStyle={{
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
          right: "-30%",
          justifySelf: "flex-end",
          position: "relative",
          bottom: "0",
        }}
      />
      <DetailModal
        open={Boolean(viewDetail)}
        onClose={() => setViewDetail(null)}
        details={{
          ...(viewDetail || {}),
          user: {
            ...viewDetail?.customer,
            fullName: `${viewDetail?.customer_name}`,
            status: viewDetail?.customer?.customer_type,
          },
          package: {
            ...(viewDetail?.package || {}),
            color: viewDetail?.package?.color_code,
            quantity: viewDetail?.num_of_packages,
          },
          status: statusMap[viewDetail?.clock_status as keyof typeof statusMap],
        }}
      />
    </div>
  );
};

export default DailyViewList;
