import SearchInput from "../../../../components/Input/SearchInput";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/dashboard/chevron_left.svg";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/dashboard/chevron_right.svg";
import DailyView from "./DailyView";
import MonthlyView from "./MonthlyView";
import { Icons } from "../../../../assets/icons";
import SelectInput from "../../../../components/Input/SelectInput";
import { useGetFrontdeskCalendarQuery } from "../../../../pages/dashboard/utils/apis/getDashboard";
import {
  daysInMonth,
  getDateString,
  months,
} from "../../../../utils/dateHelper";
import useLayout from "../../../../hooks/useLayouts";
import {
  BookingViews,
  SpaceTypes,
  ViewType,
} from "../../../../pages/dashboard/dashboard.types";
import Calendar from "./Calendar";
import BookingLists from "../BookingLists/index";

function FrontdeskBookings() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [spaceType, setSpaceType] = useState<SpaceTypes>("all");
  const [viewType, setViewType] = useState<ViewType>("daily");
  const [searchBooking, setSearchBooking] = useState("");
  const BOOKINGS_VIEWS: Record<
    BookingViews,
    (props: {
      viewType: ViewType;
      currentDate: Date;
      calendarData: any;
      searchBooking?: string;
      isLoading?: boolean;
    }) => React.ReactElement
  > = {
    calendar: Calendar,
    list: BookingLists,
  };
  const { layout, LayoutComponent, setLayout } = useLayout(
    BOOKINGS_VIEWS,
    "calendar"
  );

  const { data: calendarData, isLoading } = useGetFrontdeskCalendarQuery({
    space_type: spaceType === "all" ? "" : spaceType,
    search: searchBooking,
    start_date:
      viewType === "daily"
        ? getDateString(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate(),
              0,
              0,
              0
            )
          )
        : getDateString(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              1,
              currentDate.getHours(),
              0,
              0
            )
          ),
    end_date:
      viewType === "daily"
        ? getDateString(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate(),
              23,
              0,
              0
            )
          )
        : getDateString(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              daysInMonth(currentDate.getFullYear())[currentDate.getMonth()],
              currentDate.getHours(),
              0,
              0
            )
          ),
  });

  const goToNextDate = () => {
    setCurrentDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          viewType === "monthly" ? prev.getMonth() + 1 : prev.getMonth(),
          viewType === "daily" ? prev.getDate() + 1 : prev.getDate(),
          prev.getHours()
        )
    );
  };
  const goToPrevDate = () => {
    setCurrentDate(
      (prev) =>
        new Date(
          prev.getFullYear(),
          viewType === "monthly" ? prev.getMonth() - 1 : prev.getMonth(),
          viewType === "daily" ? prev.getDate() - 1 : prev.getDate(),
          prev.getHours()
        )
    );
  };
  const resetDate = () => {
    setCurrentDate(new Date());
  };

  const handleSpaceChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLSelectElement>) => {
    setSpaceType(value as SpaceTypes);
  };
  const handleSearchChange = ({
    currentTarget: { value },
  }: FormEvent<HTMLInputElement>) => {
    setSearchBooking(value);
  };

  return (
    <div className="w-full flex flex-col justify-start items-start gap-5">
      <Filters
        currentDate={currentDate}
        gotoNext={goToNextDate}
        gotoPrev={goToPrevDate}
        resetDate={resetDate}
        spaceType={spaceType}
        handleSpaceChange={handleSpaceChange}
        viewType={viewType}
        setViewType={setViewType}
        handleSearchChange={handleSearchChange}
        searchValue={searchBooking}
        layout={layout}
        setLayout={setLayout}
      />
      <LayoutComponent
        currentDate={currentDate}
        viewType={viewType}
        calendarData={calendarData}
        isLoading={isLoading}
      />
    </div>
  );
}

const layoutIconsMap: Record<BookingViews, { true: string; false: string }> = {
  calendar: {
    true: Icons.dashboard.Calendar,
    false: Icons.dashboard.emptyCalendar,
  },
  list: {
    true: Icons.dashboard.listingIcon,
    false: Icons.dashboard.emptyListing,
  },
};
type IFilters = {
  currentDate: Date;
  resetDate: () => void;
  gotoPrev: () => void;
  gotoNext: () => void;
  spaceType: SpaceTypes;
  handleSpaceChange: (e: FormEvent<HTMLSelectElement>) => void;
  handleSearchChange: (e: FormEvent<HTMLInputElement>) => void;
  searchValue: string;
  viewType: ViewType;
  setViewType: React.Dispatch<React.SetStateAction<ViewType>>;
  layout: BookingViews;
  setLayout: React.Dispatch<React.SetStateAction<BookingViews>>;
};
const Filters: FC<IFilters> = (props) => {
  const {
    currentDate,
    resetDate,
    gotoPrev,
    gotoNext,
    spaceType,
    handleSpaceChange,
    handleSearchChange,
    searchValue,
    viewType,
    setViewType,
    layout,
    setLayout,
  } = props;

  return (
    <header className="flex flex-col justify-start items-start gap-8 w-full">
      <div className="w-full flex justify-between items-center">
        <div className="flex justify-start items-center font-medium text-sm leading-5 ">
          <button
            onClick={gotoPrev}
            className="bg-point-blue  w-11 h-10 border-r border-r-[#244C6B] rounded-tl rounded-bl flex justify-center items-center py-2.5 px-3"
          >
            <ChevronLeft stroke="#FCFCFD" />
          </button>
          <button
            onClick={resetDate}
            className="bg-point-blue text-[#FCFCFD] w-[74px] h-10 border-r border-r-[#244C6B]  flex justify-center items-center py-2.5 px-3"
          >
            Today
          </button>
          <button
            onClick={gotoNext}
            className="bg-point-blue  w-11 h-10 border-r border-r-[#244C6B] rounded-tr rounded-br flex justify-center items-center  py-2.5 px-3"
          >
            <ChevronRight stroke="#FCFCFD" />
          </button>
        </div>
        <div className="flex justify-self-center justify-between items-center gap-4">
          <SelectInput
            className="w-[210px] h-10 bg-[#EDEDED] rounded"
            onChange={handleSpaceChange}
            menuClassName="w-[200px]"
            value={spaceType}
            options={[
              { label: "All Space", value: "all" },
              { label: "Work Station", value: "work station" },
              { label: "Meeting Room", value: "meeting room" },
            ]}
            optionsText={(item) => item.label}
            optionsValue={(item) => item.value}
          />

          <div
            role="switch"
            className="p-0.5 rounded-md bg-[#EAECF0] flex justify-start gap-1 items-center font-medium text-sm transition-colors duration-300"
          >
            <button
              className={`rounded py-2 px-[26px] gap-2.5 flex justify-center items-center ${
                viewType === "daily"
                  ? "bg-[#FCFCFD] text-[#1C3C54]"
                  : "bg-transparent text-[#475467]"
              }`}
              onClick={() => setViewType("daily")}
            >
              Daily
            </button>
            <button
              className={`rounded py-2 px-[26px] gap-2.5 flex justify-center items-center ${
                viewType === "monthly"
                  ? "bg-[#FCFCFD] text-[#1C3C54]"
                  : "bg-transparent text-[#475467]"
              }`}
              onClick={() => setViewType("monthly")}
            >
              Monthly
            </button>
          </div>
        </div>
        <div className="max-w-[30%] min-w-[220px]">
          <SearchInput
            placeholder="Name, Category, Space..."
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="justify-between items-center flex w-full">
        <h3 className="font-semibold text-[24px] leading-[28px] text-point-primary-text">
          {`${currentDate.getDate()} ${
            months[currentDate.getMonth()]
          } ${currentDate.getFullYear()}`}
        </h3>
        <div className="flex justify-end gap-3 items-center">
          {viewType === "monthly" && (
            <div className="flex justify-between items-center">
              <SpaceType type="station" />
              <SpaceType type="meeting" />
            </div>
          )}
          <div className="flex justify-between items-center gap-3">
            <button onClick={() => setLayout("calendar")}>
              <img
                src={
                  layoutIconsMap["calendar"][
                    String(layout === "calendar") as "true" | "false"
                  ]
                }
                alt={`${
                  layout === "calendar" ? "show" : "unshow"
                } calendar view`}
              />
            </button>
            <button onClick={() => setLayout("list")}>
              <img
                src={
                  layoutIconsMap["list"][
                    String(layout === "list") as "true" | "false"
                  ]
                }
                alt={`${layout === "list" ? "show" : "unshow"} list view`}
              />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

const SpaceType: FC<{ type: "station" | "meeting" }> = ({ type }) => {
  const ImageMap = {
    station: Icons.dashboard.stationUserIcon,
    meeting: Icons.dashboard.meetingUserIcon,
  };
  const TextMap = {
    station: "Work Station",
    meeting: "Meeting Room",
  };
  return (
    <div className="flex gap-2 justify-center items-center px-2 py-3 font-semibold text-sm leading-5 text-[#475467]">
      <img src={ImageMap[type]} alt="represent" />
      <strong>{TextMap[type]}</strong>
    </div>
  );
};
export default FrontdeskBookings;
