import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import signInImage from "../assets/images/welcome.png";
import Modal from "../components/modal/Modal";
import MissingPage from "../pages/errors/MissingPage";

function AuthAppLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const [currentPage, setCurrentPage] = useState<
    "sign-in" | "recover-password"
  >(pathname.split("/").slice(-1)[0] as "sign-in" | "recover-password");

  const bgImages: {
    "sign-in": string;
    "recover-password": string;
  } = {
    "sign-in": signInImage,
    "recover-password": signInImage,
  };
  useEffect(() => {
    if (!pathname) return;
    setCurrentPage(
      pathname.split("/").slice(-1)[0] as "sign-in" | "recover-password"
    );
  }, [pathname]);
  // if (!Object.keys(bgImages).includes(currentPage)) return <MissingPage />;
  return (
    <div className="flex flex-col relative overflow-hidden min-h-screen">
      <div className="px-[72px] py-4 border border-solid border-transparent border-b-point-gray w-full z-50 bg-white">
        <img
          src="/companyLogo.png"
          alt="CompanyLogo"
          width="60px"
          height="37px"
        />
      </div>

      <div
        className="w-full overflow-auto  flex justify-start p-0 m-0 items-start bg-white sm:flex-col md:flex-row"
        style={{ maxHeight: "calc(100vh - 70px)" }}
      >
        <div className="sm:w-full md:w-[56%] ml-[72px] h-full flex flex-col justify-start ">
          {children}

          <div className="w-full flex-1 mb-8 mt-16 uppercase  font-medium text-xs leading-normal text-point-secondary-text">
            &copy; Copyright Marriott All Rights Reserved.
          </div>
        </div>
        <div className="md:w-[44%]  hidden md:block bg-no-repeat">
          <img
            className="h-full md:w-[38%]   object-cover absolute right-0 top-0"
            src={bgImages[currentPage]}
            alt="representation of page"
          />
        </div>
      </div>
    </div>
  );
}

export default AuthAppLayout;
