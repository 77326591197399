import { unreadChatCtx } from "./../providers/UnreadChats";

import { useContext } from "react";

const useUnreadChats = () => {
  const ctxValue = useContext(unreadChatCtx);
  if (ctxValue === undefined)
    throw new Error("Please Ensure You are under the provider");
  return ctxValue;
};

export default useUnreadChats;
