import { Icons } from "../../../../assets/icons";
import defaultImage from "../../../../assets/images/defaultImage.png";
import React, { FC, useState } from "react";
import PackageRepresentation from "../../../../pages/packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../../pages/packages/utils";
import DetailModal from "./DetailModal";
import StatusButton from "./StatusButton";
import {
  convertToLocalTime,
  isDayInBetweenDaysInclusive,
  isDayPreviousToThatDay,
  isPrevDays,
  isThatDay,
  isToday,
  isYesterday,
} from "../../../../utils/dateHelper";

function DailyView(props: { data: any[]; currentDate: Date }) {
  const { data, currentDate } = props;

  return (
    <section
      role="grid"
      className="w-full max-h-screen scroller pb-4 mb-8 overflow-auto flex flex-col justify-start items-start"
    >
      <SpaceHeader
        headers={Object.keys(
          data.reduce((acc, cur) => {
            acc[cur.name] = 0;
            return acc;
          }, {})
        )}
      />
      <SpaceTimeStamps
        content={Object.values(
          data.reduce((acc, cur) => {
            acc[cur.name] = cur.bookings.map((booking: any) => ({
              ...booking,
              space: {
                ...(booking.space || {}),
                name: cur.name,
                space_type: cur.space_type,
              },
            }));
            return acc;
          }, {})
        )}
        currentDate={currentDate}
      />
    </section>
  );
}

// Header Components
const SpaceHeader: FC<{ headers: string[] }> = ({ headers }) => {
  return (
    <header
      className="w-full flex justify-start items-center gap-8 pr-8 sticky top-0 z-20"
      role="row"
    >
      <strong
        className="font-medium text-xs leading-[160%] text-point-primary-text sticky top-0 left-0 z-20 bg-white  py-3 px-4 h-[49px]"
        role="columnheader"
      >
        Time
      </strong>
      <div className="flex-1 flex justify-start items-center  ">
        {headers.map((head, ind) => (
          <HeaderItem name={head} key={ind} />
        ))}
      </div>
    </header>
  );
};

const HeaderItem: FC<{ name: string }> = ({ name }) => {
  return (
    <div
      role="gridcell"
      style={{ backgroundColor: "rgb(232,235,237)" }}
      className="sticky top-0 w-[244px] max-w-[33%] text-ellipsis whitespace-nowrap flex-1 capitalize h-[49px]   py-3 px-4 flex text-point-blue font-bold text-[18px] leading-[180%] text-center justify-center items-center gap-2.5 border-t-0 border-r-0 border-b border-l border-white"
    >
      <span className="w-[180px] whitespace-nowrap text-ellipsis overflow-hidden">
        {name}
      </span>
    </div>
  );
};

// Body component
const SpaceTimeStamps: FC<{ content: any[][]; currentDate: Date }> = ({
  content,
  currentDate,
}) => {
  return (
    <main
      className="w-full h-full flex justify-start items-start gap-8 pr-8 min-w-max"
      role="row"
    >
      <TimeHeaders />
      <SpaceContent content={content} currentDate={currentDate} />
    </main>
  );
};

// Body Title
const AvailableTime = [
  "07:am",
  "08:am",
  "09:am",
  "10:am",
  "11:am",
  "12:pm",
  "01:pm",
  "02:pm",
  "03:pm",
  "04:pm",
  "05:pm",
  "06:pm",
  "07:pm",
  "08:pm",
  "09:pm",
];
const TimeHeaders: FC = ({}) => {
  return (
    <aside
      className="font-medium text-xs leading-[160%] text-point-primary-text sticky left-0 z-10 bg-white  py-3 px-[3px] h-full flex justify-start gap-3"
      role="group"
    >
      <div
        className="w-[1px] h-[1700px] bg-point-tertiary-bg"
        role="separator"
      ></div>
      <div className="flex flex-col justify-start items-center">
        {AvailableTime.map((time) => (
          <TimeItem value={time} key={time} />
        ))}
      </div>
    </aside>
  );
};

const TimeItem: FC<{ value: string }> = ({ value }) => {
  return (
    <div
      className="flex flex-col justify-start gap-1 items-center py-0.5"
      role="gridcell"
    >
      <span className="border border-dashed h-10 w-0 border-[#d9d9d9]"></span>
      <strong className="font-medium text-sm">{value}</strong>
      <span className="border border-dashed h-10 w-0 border-[#d9d9d9]"></span>
    </div>
  );
};

// Body Content
const SpaceContent: FC<{ content: any[][]; currentDate: Date }> = ({
  content,
  currentDate,
}) => {
  return (
    <div className="flex-1 flex justify-start items-start" role="group">
      {content.map((items, ind) => (
        <SpaceItems
          items={items}
          key={ind}
          last={content.length === ind + 1}
          currentDate={currentDate}
        />
      ))}
    </div>
  );
};

const getHoursFromString = (hourStr: string) => {
  const [hour, meridian] = hourStr.split(":");

  if (meridian === "am" || parseInt(hour) === 12) return parseInt(hour);
  return parseInt(hour) + 12;
};
const SpaceItems: FC<{ items: any[]; last?: boolean; currentDate: Date }> = ({
  items,
  last,
  currentDate,
}) => {
  const computeSpacialData = () => {
    if (!items.length) return new Array(AvailableTime.length).fill(null);
    const newItems = items
      .map((cur) => {
        return {
          ...cur,
          startTime: convertToLocalTime(cur.start_date),
          endTime: convertToLocalTime(cur.end_date),
          user: cur.customer,
        };
      })
      .sort((a, b) => {
        return a.startTime > b.startTime ? -1 : 1;
      });
    const indexOfDailyOrMonthly = newItems.findIndex(
      (cur) =>
        (cur.package.package_type === "month" ||
          cur.package.package_type === "day") &&
        isDayInBetweenDaysInclusive(currentDate, cur.startTime, cur.endTime)
    );

    if (indexOfDailyOrMonthly !== -1) {
      return [
        {
          ...newItems[indexOfDailyOrMonthly],
          startTimeMod: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            7
          ),
          endTimeMod: new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            21
          ),
        },
      ];
    }

    const newArr: (null | string)[] = [];
    for (let idx = 0; idx < AvailableTime.length; idx++) {
      newItems.forEach((el: any) => {
        if (!isDayInBetweenDaysInclusive(currentDate, el.startTime, el.endTime))
          return;

        const startHours = el.startTime.getHours();
        const endHours = el.endTime.getHours();

        if (
          isThatDay(currentDate, el.endTime) &&
          isDayPreviousToThatDay(currentDate, el.startTime) &&
          getHoursFromString(AvailableTime[idx]) <= endHours
        ) {
          newArr[idx] = el.booking_id;
        } else if (
          isThatDay(currentDate, el.endTime) &&
          isThatDay(currentDate, el.startTime) &&
          getHoursFromString(AvailableTime[idx]) >= startHours &&
          getHoursFromString(AvailableTime[idx]) <= endHours
        ) {
          newArr[idx] = el.booking_id;
        } else {
          newArr[idx] = newArr[idx] || null;
        }
      });
    }

    for (let idx = 0; idx < newArr.length; idx++) {
      if (typeof newArr[idx] === "string") {
        if (newArr[idx] === newArr[idx + 1]) {
          newArr.splice(idx + 1, 1);
          idx--;
        }
      }
    }
    // console.log( newItems, newArr, newArr.includes('n5c53q') )
    const res: any[] = newArr.map((el) => {
      if (typeof el !== "string") {
        return el;
      } else {
        const found = newItems.find((cur) => cur.booking_id === el);
        if (!found) return el;
        return {
          ...found,
          startTimeMod: isDayPreviousToThatDay(currentDate, found.startTime)
            ? new Date(
                found.startTime.getFullYear(),
                found.startTime.getMonth(),
                found.startTime.getDate(),
                0,
                found.startTime.getMinutes()
              )
            : found.startTime,
          endTimeMod: found.endTime,
        };
      }
    });
    return res;
  };

  const displayItems = computeSpacialData();
  // console.log(displayItems, "DISPLAY ITEMS",items)
  return (
    <div
      role="group"
      className={`w-[244px] flex-1 flex flex-col justify-start items-center h-[1680px] max-w-[33%] ${
        last ? "border-r-0" : "border-r"
      }`}
    >
      {displayItems.map((item: any, ind: number) =>
        item === null ? (
          <NoActivityItem key={ind} last={ind === displayItems.length - 1} />
        ) : (
          <SpaceItem
            item={item}
            last={ind === displayItems.length - 1}
            key={item.booking_id || item.start_date}
          />
        )
      )}
    </div>
  );
};

const statusMap: Record<string, "clock in" | "On-Seat" | "clocked out"> = {
  "clock-in": "clock in",
  "on-seat": "On-Seat",
  "clocked-out": "clocked out",
};
const SpaceItem: FC<{ item: any; last?: boolean }> = ({ item, last }) => {
  const [showDetail, setShowDetail] = useState(false);
  const {
    startTimeMod: startTime,
    endTimeMod: endTime,
    user,
    status,
    package: Package,
    num_of_packages,
    clock_in,
    clock_out,
    clock_status,
  } = item;
  const blockSize = endTime.getHours() - startTime.getHours();
  const itemHeight = (blockSize + 1) * 112;

  return (
    <div
      style={{ height: `${itemHeight}px` }}
      className={` border-b py-3 px-6 w-full cursor-pointer max-w-full `}
      role="gridcell"
      onClick={() => setShowDetail(true)}
    >
      <div
        className={`w-full h-full  p-2 flex items-start gap-2.5 rounded-lg ${
          blockSize === 1 ? "bg-transparent" : "bg-[#FAFAFA]"
        }  `}
      >
        <div className="w-full py-2 px-3 flex flex-col justify-center items-start bg-[#F2F2F2] rounded-lg border border-dashed border-spacing-1 border-[#D9D9D9] self-stretch flex-1">
          <div
            className={`w-full -mt-1 flex flex-col ${
              blockSize < 6 ? "h-full" : ""
            }`}
          >
            <UserRepresentation
              user={{
                ...user,
                fullName: `${user?.first_name} ${user?.last_name}`,
                status: user?.customer_type,
              }}
            />
            {blockSize > 1 && (
              <div className="w-full my-7">
                <PackageSection
                  Package={{
                    ...(Package as any),
                    color: Package?.color_code,
                    quantity: num_of_packages,
                  }}
                />
              </div>
            )}
            <div
              className={`flex-1 flex flex-col justify-end justify-self-center self-center w-full ${
                blockSize > 5 ? "mt-[60px]" : "px-0 mx-0"
              }`}
            >
              <StatusButton
                status={statusMap[clock_status as keyof typeof statusMap]}
              />
            </div>
          </div>
        </div>
      </div>
      <DetailModal
        open={showDetail}
        onClose={() => setShowDetail(false)}
        details={{
          ...item,
          user: {
            ...item.user,
            fullName: `${user?.first_name} ${user?.last_name}`,
            status: user?.customer_type,
          },
          package: {
            ...item.package,
            color: item.package?.color_code,
            quantity: item.num_of_packages,
          },
          status: statusMap[clock_status as keyof typeof statusMap],
        }}
      />
    </div>
  );
};

const NoActivityItem: FC<{ last?: boolean }> = ({ last }) => {
  return (
    <div
      className={`border-b py-3 px-6 w-full h-[112px] flex justify-center items-center `}
    >
      <strong className="uppercase tracking-[0.08em] text-xs font-medium text-point-secondary-text leading-[160%]">
        No Activity
      </strong>
    </div>
  );
};

const imageMap = {
  resident: Icons.dashboard.residentIcon,
  visitor: Icons.dashboard.visitorIcon,
};
const UserRepresentation: FC<{
  user: { image?: string; fullName: string; status: "resident" | "visitor" };
}> = ({ user }) => {
  const { image, fullName, status: type } = user;
  return (
    <div className="flex justify-start items-center gap-2 -mt-1">
      <img
        src={image || defaultImage}
        alt={`image of ${fullName}`}
        onError={(e) => {
          e.currentTarget.src = defaultImage;
        }}
        className="w-[27px] h-[27px] object-fill object-center rounded-full"
      />
      <div className="flex flex-col justify-center items-start">
        <h5 className="font-medium text-sm leading-[17px] capitalize">
          {fullName}
        </h5>
        <div className="flex items-center gap-1 justify-center">
          <img
            src={imageMap[type]}
            alt={`representation of ${type}`}
            width={10}
            height={10}
          />
          <strong
            className={`uppercase text-[10px] leading-[160%] font-medium ${
              type === "visitor"
                ? "text-point-secondary-bg"
                : "text-point-tertiary-bg"
            }`}
            style={{ letterSpacing: "0.08em" }}
          >
            {type}
          </strong>
        </div>
      </div>
    </div>
  );
};

const PackageSection: FC<{
  Package: { color: string; name: string; quantity: number };
}> = ({ Package }) => {
  const { color, name, quantity } = Package;
  return (
    <section className="flex flex-col gap-1">
      <h5 className="font-medium text-sm leading-[160%] text-[#979797]">
        Package
      </h5>
      <div className="flex justify-start items-center">
        <PackageRepresentation
          color={color}
          otherVariant={getOtherColorVariant(color)}
        />
        <strong className="uppercase tracking-[0.08em] text-xs font-medium leading-[160%]">
          {name} X {quantity}
        </strong>
      </div>
    </section>
  );
};

export default DailyView;
