import React, {
  FC,
  HTMLAttributes,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import useTable from "../../hooks/useTable";
import Pagination from "./Pagination";
import RiseLoader from "react-spinners/RiseLoader";

type Column<T = unknown> = {
  field?: string;
  title?: React.ReactNode;
  headerStyle?: React.CSSProperties;
  cellStyle?: React.CSSProperties;
  renderItem?: (rowData: T) => React.ReactNode;
};
interface ITable<T = unknown> extends HTMLAttributes<HTMLTableElement> {
  columns: Column<any>[];
  data: any[];
  heading?: boolean;
  emptyComponent?: React.ReactNode;
  alternate?: boolean;
  loading?: boolean;
  perPage?: number;
  useFooter?: boolean;
  footerStyle?: React.CSSProperties;
  onPageChange?: (page: number) => void;
  total?: number;
  pageMismatch?: boolean;
  keyExtractor?: (item: any) => string | number;
  page?: number;
  moveDataBy?: {
    requestPerPage: number;
    requestPage: number;
  };
}
const SimpleTable: FC<ITable> = ({
  columns = [
    {
      field: "name",
      title: "Name",
      headerStyle: {},
      cellStyle: {},
      renderItem: (rowData) => {},
    },
  ],
  data = [],
  heading = false,
  emptyComponent,
  alternate = false,
  loading,
  className,
  perPage = 10,
  useFooter = true,
  footerStyle = {},
  onPageChange,
  total,
  pageMismatch,
  style,
  keyExtractor,
  page,
  moveDataBy,
  ...rest
}) => {
  const [currentPage, setCurrentPage] = React.useState(page || 1);

  const fullData = useMemo(() => {
    return total
      ? getFullData(
          data,
          currentPage,
          perPage,
          total,
          pageMismatch as boolean,
          moveDataBy || {
            requestPage: 1,
            requestPerPage: 30,
          }
        )
      : data;
  }, [perPage, total, data, currentPage, moveDataBy]);

  const { slice } = useTable(fullData || [], page || currentPage, perPage);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      onPageChange?.(page);
    },
    [onPageChange]
  );
  useEffect(() => {
    if (!page) return;
    setCurrentPage(page);
  }, [page]);
  if (!!emptyComponent && !(Array.isArray(data) && data?.length) && !loading) {
    return emptyComponent as ReactElement;
  }

  return (
    <div
      className={className}
      style={{
        width: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        position: "relative",
      }}
    >
      <div className="flex-1 scroller pb-4">
        <table
          style={{ minWidth: 650, overflow: "auto", ...style }}
          aria-label="simple table"
          {...rest}
        >
          {heading && (
            <thead style={{ width: "100%" }}>
              <tr>
                {columns.map((_, ind) => {
                  return columns[ind] ? (
                    <th
                      className="last:pr-0 first-of-type:pl-0 px-6"
                      key={ind}
                      style={{ ...(columns[ind].headerStyle || {}) }}
                      align="left"
                    >
                      {columns[ind].title}
                    </th>
                  ) : null;
                })}
              </tr>
            </thead>
          )}
          {loading ? (
            <tbody>
              <tr className="w-full">
                <td
                  rowSpan={4}
                  colSpan={columns.length}
                  className="w-full text-center "
                >
                  <span className="min-h-[200px] w-full flex justify-center items-center">
                    <RiseLoader loading={loading} color="#1C3C54" size={10} />
                  </span>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {Array.isArray(slice) && data?.length ? (
                slice.map((datum, index) => {
                  return (
                    <tr
                      key={
                        keyExtractor?.(datum) ||
                        datum?.id ||
                        Math.random().toString(16)
                      }
                      className={`px-4 ${
                        alternate
                          ? "odd:bg-point-gray-dark"
                          : "odd:bg-point-primary-bg"
                      }  border-b`}
                    >
                      {columns.map((_, ind) => {
                        return columns[ind] ? (
                          <td
                            className="last:pr-0 first-of-type:pl-0 px-6 py-[22px]  last:border-0 "
                            key={datum?.id + " - " + ind}
                            style={{
                              ...(columns[ind].cellStyle || {}),
                            }}
                          >
                            {columns[ind].renderItem
                              ? columns[ind]?.renderItem?.(datum || {})
                              : datum?.[columns[ind]?.field as string]}
                          </td>
                        ) : null;
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    className="text-center mt-4 w-20"
                    colSpan={columns.length}
                  >
                    {" "}
                    No Records Found{" "}
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </table>
      </div>
      {useFooter && (
        <div
          style={{
            width: "100%",
            marginTop: 20,

            ...footerStyle,
          }}
        >
          <Pagination
            currentPage={page || currentPage}
            totalCount={total || data.length || 5}
            pageSize={perPage}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  heading: PropTypes.bool,
  emptyComponent: PropTypes.element,
};

SimpleTable.defaultProps = {
  useFooter: true,
  pageMismatch: false,
  data: [],
};

const getFullData = (
  data: any[],
  currentPage: number,
  perPage: number,
  total: number,
  isMisMatch: boolean,
  moveDataBy?: {
    requestPerPage: number;
    requestPage: number;
  }
) => {
  if (!data) return data;
  const { requestPerPage = 30, requestPage = 1 } = moveDataBy || {};
  const totalBefore = new Array((currentPage - 1) * perPage)
    .fill(0)
    .map((el, ind) => ({ unique: ind + (currentPage - 1) * perPage + "bf" }));
  const totalAfter = new Array(
    total - currentPage * perPage > -1 ? total - currentPage * perPage : 0
  )
    .fill(0)
    .map((el, ind) => ({ unique: ind + total - currentPage * perPage + "af" }));
  return [
    ...totalBefore,
    ...(isMisMatch
      ? data.slice(
          (currentPage - 1 - (requestPage - 1) * (requestPerPage / perPage)) *
            perPage,
          (currentPage - 1 - (requestPage - 1) * (requestPerPage / perPage)) *
            perPage +
            perPage
        )
      : data),
    ...totalAfter,
  ];
};
export default SimpleTable;
