import React, { startTransition } from "react";
import { Link, useNavigate } from "react-router-dom";

type ListItem = {
  icon: React.ReactNode;
  label: string;
  selected: boolean;
  onClick: () => void;
  url: string;
};

function SidebarItem({ icon, label, onClick, selected, url }: ListItem) {
  const navigate = useNavigate();
  return (
    <li
      onClick={onClick}
      role="menuitem"
      aria-selected={selected}
      tabIndex={selected ? 0 : -1}
    >
      <div
        role="link"
        tabIndex={0}
        onClick={() => startTransition(() => navigate(url))}
        className={`flex items-center justify-start gap-4 px-4 py-3 bg-transparent hover:bg-[#112433] ${
          selected ? "bg-[#112433]" : "bg-transparent"
        } w-full cursor-pointer`}
      >
        <span id={label} className="text-white">
          {icon}
        </span>
        <span
          aria-labelledby={label}
          className="text-white font-medium text-base leading-[30px]"
        >
          {label}
        </span>
      </div>
    </li>
  );
}

export default SidebarItem;
