import { CURRENT_USER, USER_PERMISSIONS } from "../apis/constants";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router";
import { Storage } from "../store/Storage";

function ProtectedRoutes() {
  const currentUser = Storage.getItem(CURRENT_USER);
  const location = useLocation();
  return currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="auth/sign-in" state={{ from: location }} replace />
  );
  // return <Outlet />;
}

export default ProtectedRoutes;
