import React, { FC, useEffect, useState } from "react";
import InputWithAdornment from "../../components/Input/InputWithAdornment";
import FormGroup from "./components/FormGroup";
import { Icons } from "../../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "./components/Button";
import Checkbox from "../../components/Input/Checkbox";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import loginSchema from "./utils/login.validator";
import { useLoginAppMutation } from "./utils/api/login";
import { addAuthToDevice } from "../../utils/api";
import { Storage } from "../../store/Storage";

interface Values {
  password: string;
  email: string;
}

const lastRemembered = Storage.getItem("REMEMBER_LOGIN");

function SignIn() {
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state?.from?.pathname || "/app/dashboard";
  const { mutateAsync: submitLoginDetails, data: ResponseData } =
    useLoginAppMutation();

  const loginHandler = async (
    values: FormikValues,
    { setSubmitting, resetForm }: FormikHelpers<Values>
  ) => {
    setSubmitting(true);
    const res = await submitLoginDetails({ ...values });
    setSubmitting(false);
    if (res) {
      const { token, admin_permissions, ...user } = res;
      addAuthToDevice({ token, user, permissions: admin_permissions });
      if (Storage.getItem("REMEMBER_LOGIN")) {
        Storage.setItem("REMEMBER_LOGIN_PASSWORD", values.password);
        Storage.setItem("REMEMBER_LOGIN_EMAIL", values.email);
      }
      // resetForm();
      navigate(from, {
        replace: true,
      });
    }
  };
  const {
    values,
    isSubmitting,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    isValid,
    setValues,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: loginHandler,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (lastRemembered) {
      setValues({
        email: Storage.getItem("REMEMBER_LOGIN_EMAIL"),
        password: Storage.getItem("REMEMBER_LOGIN_PASSWORD"),
      });
    }
  }, []);

  return (
    <div className="w-full  mt-36">
      <form
        onSubmit={handleSubmit}
        className="w-3/5 flex flex-col justify-center items-start gap-[76px]"
      >
        <div className="w-full">
          <h1>Admin Sign in</h1>
          <p className="sub-heading">You will now be signed in as an admin.</p>
        </div>
        <div className="w-full flex flex-col gap-5">
          <FormGroup label="Email" id="email">
            <InputWithAdornment
              name="email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="example@example.com"
              error={
                errors.email &&
                (touched.email as unknown as string) &&
                errors.email
              }
            />
          </FormGroup>
          <FormGroup label="Password" id="password">
            <InputWithAdornment
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type={showPassword ? "text" : "password"}
              right={
                <img
                  onClick={() => setShowPassword((prev) => !prev)}
                  className="cursor-pointer"
                  width={20}
                  height={20}
                  src={showPassword ? Icons.auth.eyeClose : Icons.auth.eyeOpen}
                  alt="show or hide password"
                />
              }
              error={
                errors.password &&
                (touched.password as unknown as string) &&
                errors.password
              }
            />
          </FormGroup>

          <ForgotPasswordLink isValid={isValid} />
        </div>
        <div className="w-full mt-12">
          <Button
            loading={isSubmitting}
            label="Sign In"
            fullWidth
            type="submit"
            disabled={!isValid || isSubmitting}
          />
        </div>
      </form>
    </div>
  );
}

const ForgotPasswordLink: FC<{ isValid: boolean }> = ({ isValid }) => {
  const [rememberPassword, setRememberPassword] = useState(
    Boolean(lastRemembered)
  );
  const modifyRememberState = (checked: boolean) => {
    Storage.setItem("REMEMBER_LOGIN", checked);
    setRememberPassword(checked);
  };

  return (
    <div className="w-full flex justify-between items-center">
      <div className="flex justify-start items-center gap-2">
        <Checkbox
          id="remember"
          checked={rememberPassword}
          // disabled={isValid}
          onChange={(e) => modifyRememberState(e.currentTarget.checked)}
        />
        <label htmlFor="remember">Remember me</label>
      </div>
      <Link
        to="/auth/recover-password"
        className="text-point-secondary-text hover:text-point-tertiary-text hover:underline"
      >
        Forgot Password?
      </Link>
    </div>
  );
};
export default SignIn;
