import { hexToRgb } from "../../utils/stringHelpers";

const isANumber = (num: any) => {
  return typeof num === "number" && !isNaN(Number(num));
};
const parseValue = (num: any) =>
  parseInt(((num as number) * 0.8902).toString());

export const getOtherColorVariant = (hex: string) => {
    const { r, g, b } = hexToRgb(hex) || {};
    if (!(isANumber(r) && isANumber(g) && isANumber(b))) return hex;
    return `rgb(${parseValue(r)}, ${parseValue(g)}, ${parseValue(b)})`;
};
  
