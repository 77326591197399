import {
  SpaceTypes,
  ViewType,
} from "../../../../pages/dashboard/dashboard.types";
import React, { FC } from "react";
import DailyView from "./DailyView";
import MonthlyView from "./MonthlyView";

const Calendar = ({
  viewType,
  currentDate,
  calendarData,
  searchBooking,
  isLoading,
}: {
  viewType: ViewType;
  currentDate: Date;
  calendarData: any;
  searchBooking?: string;
  isLoading?: boolean;
}) => {
  return (
    <div className="w-full h-full">
      {viewType === "daily" && (
        <DailyView
          currentDate={currentDate}
          data={(calendarData || []).sort((a: any, b: any) => {
            if (a.space_type === b.space_type) {
              return a.name.localeCompare(b.name);
            }
            return a.space_type.localeCompare(b.space_type);
          })}
        />
      )}
      {viewType === "monthly" && (
        <MonthlyView
          data={(calendarData || []).sort((a: any, b: any) => {
            if (a.space_type === b.space_type) {
              return a.name.localeCompare(b.name);
            }
            return a.space_type.localeCompare(b.space_type);
          })}
          currentDate={currentDate}
        />
      )}
    </div>
  );
};

export default Calendar;
