import useQueryParams from "../../hooks/useQueryParams";
import React, { useMemo, useState } from "react";
import Button from "../auth/components/Button";
import AdminDashboard from "./admin";
import FrontdeskDashboard from "./frontdesk";
import { useLocation, useNavigate } from "react-router";
import SelectInput from "../../components/Input/SelectInput";
import { Storage } from "../../store/Storage";
import { CURRENT_USER, USER_PERMISSIONS } from "../../apis/constants";
import Title from "../../components/meta/Title";

function Dashboard() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showBookWorkStationModal, setShowBookWorkStationModal] =
    useState(false);
  const [showBookMeetingRoom, setShowBookMeetingRoom] = useState(false);
  let userPermissions = Storage.getItem(USER_PERMISSIONS);
  const [dashboardType, setDashboardType] = useState(() =>
    state?.from === "pending-verification" || state?.from === "refunds"
      ? "frontdesk"
      : "admin"
  );

  const dashboardOptions = useMemo(() => {
    return [
      {
        label: "Admin Dashboard",
        value: "admin",
      },
      {
        label: "Frontdesk Dashboard",
        value: "frontdesk",
      },
    ];
  }, [userPermissions]);

  return (
    <div className="w-full h-full">
      <Title>Dashboard - Four Point Co-Working Space</Title>
      <div className="w-full flex flex-col gap-8">
        <div className="w-full flex justify-between items-center">
          <h1>Dashboard</h1>
          <div className="flex justify-end gap-4">
            {dashboardType === "admin" && (
              <Button
                label="Broadcast & Promotions"
                onClick={() => navigate("/app/dashboard/broadcast")}
                className="lg:min-w-[170px] xl:min-w-[208px] whitespace-nowrap w-auto !px-4 text-sm"
              />
            )}
            {dashboardType === "frontdesk" && (
              <div className="flex justify-end items-center gap-6">
                <Button
                  label="Pending Refunds"
                  onClick={() => navigate("/app/dashboard/refunds")}
                  className="lg:min-w-[150px] xl:min-w-[208px]  w-auto px-4 text-sm bg-point-tertiary-bg whitespace-nowrap"
                />
                <Button
                  label="Book Meeting Room"
                  onClick={() => setShowBookMeetingRoom(true)}
                  className="lg:min-w-[160px] xl:min-w-[208px] whitespace-nowrap w-auto px-4 text-sm "
                />
                <Button
                  label="Book Work Station"
                  onClick={() => setShowBookWorkStationModal(true)}
                  className=" lg:min-w-[160px] xl:min-w-[208px] whitespace-nowrap w-auto px-4 text-sm bg-point-primary-text"
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <SelectInput
            options={dashboardOptions}
            value={dashboardType}
            onChange={(e) => setDashboardType(e.currentTarget.value)}
            optionsText={(item) => item.label}
            optionsValue={(item) => item.value}
            className="bg-point-input-bg border border-point-input-bg rounded min-w-[200px]  h-[38px] px-2 py-1.5"
            menuClassName="-mt-5 ml-[170px] rounded-tl-none rounded-tr-none"
          />
        </div>
      </div>
      <div className="w-full mt-[22px]">
        {dashboardType === "admin" && <AdminDashboard />}
        {dashboardType === "frontdesk" && (
          <FrontdeskDashboard
            showBookWorkStationModal={showBookWorkStationModal}
            setShowBookWorkStationModal={setShowBookWorkStationModal}
            showBookMeetingRoomModal={showBookMeetingRoom}
            setShowMeetingRoomModal={setShowBookMeetingRoom}
          />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
