import { useGetSpacesQuery } from "../../../../pages/spaces/utils/apis/getSpaces";
import React, { FC, useEffect, useMemo, useState } from "react";
import { extractDateForServer } from "../../../../pages/dashboard/utils/helpers";
import { useGetAvailableWorkStationQuery } from "../../../../pages/dashboard/utils/apis/getOccupiedSpaces";
import { convertToLocalTime } from "../../../../utils/dateHelper";

const SelectWorkStation = (props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
}) => {
  const { setFieldValue, values, errors } = props;

  const [selectedWorkStation, setSelectedWorkStation] = useState<string>("");

  const {
    data: availableWorkStation,
    isLoading,
    isError,
  } = useGetAvailableWorkStationQuery({
    start_date: extractDateForServer(
      convertToLocalTime(values?.start_date) as Date
    ),
    end_date: extractDateForServer(
      convertToLocalTime(values?.end_date) as Date
    ),
  });
  useEffect(() => {
    if (!selectedWorkStation) return;
    setFieldValue?.("space", selectedWorkStation);
  }, [selectedWorkStation]);

  return (
    <div className="pt-6 bg-white w-full flex flex-col gap-7">
      <h2 className="text-point-primary-text font-medium leading-[140%] text-[22px]">
        Work Station
      </h2>
      {availableWorkStation && (
        <div className="w-full flex flex-row justify-start items-start py-4 pl-6 pr-4 h-full bg-point-input-bg">
          {!isLoading && (
            <div className="mt-[102px] pb-[86px]">
              <LeftBatch
                data={availableWorkStation["compartment A"]
                  .map((el: any) => {
                    return {
                      ...el,
                      occupied: !el.available,
                      checked: selectedWorkStation === el.id,
                      onClick: () => setSelectedWorkStation(el.id),
                    };
                  })
                  .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))}
              />
            </div>
          )}
          {!isLoading && (
            <div className="h-full relative ml-6">
              <div
                style={{
                  width: "calc(100% - 88px)",
                }}
              >
                <TopBatch
                  data={(availableWorkStation["compartment C"] || [])
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                    .slice(0, 3)
                    .map((el: any) => {
                      return {
                        ...el,
                        occupied: !el.available,
                        checked: selectedWorkStation === el.id,
                        onClick: () => setSelectedWorkStation(el.id),
                      };
                    })}
                />
                <CenterBatch
                  data={(availableWorkStation["compartment B"] || [])
                    .map((el: any) => {
                      return {
                        ...el,
                        occupied: !el.available,
                        checked: selectedWorkStation === el.id,
                        onClick: () => setSelectedWorkStation(el.id),
                      };
                    })
                    .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))}
                />
              </div>
              <BottomBatch
                data={(availableWorkStation["compartment C"] || [])
                  .sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
                  .slice(3)
                  .map((el: any) => {
                    return {
                      ...el,
                      occupied: !el.available,
                      checked: selectedWorkStation === el.id,
                      onClick: () => setSelectedWorkStation(el.id),
                    };
                  })}
              />
            </div>
          )}
          {(isError || !availableWorkStation?.["compartment A"]) &&
            !isLoading && (
              <div className="text-[22px] text-point-primary-text text-center flex justify-center items-center w-full h-full">
                No Work station to choose from
              </div>
            )}
        </div>
      )}
    </div>
  );
};

interface IStation {
  name: string;
  id: string;
  occupied: boolean;
  onClick: () => {};
  status: "occupied" | "available";
  checked: boolean;
  published?: boolean;
}
const LeftBatch: FC<{ data: IStation[] }> = ({ data = [] }) => {
  const newForm = data.reduce((acc: any, cur: IStation, ind) => {
    if (ind === 0 || ind % 2 === 0) {
      let arr = [];
      arr.push(cur);
      acc.push(arr);
    } else {
      let lastArr = acc.pop();
      lastArr.push(cur);
      acc.push(lastArr);
    }
    return acc;
  }, []);

  return (
    <div className="grid grid-cols-2 gap-x-5 gap-y-2 relative ">
      {newForm.map((row: IStation[], yind: number) => {
        return row.map((el: IStation, xInd: number) => {
          return (
            <StationCard
              key={el.id}
              status={el?.occupied ? "occupied" : "available"}
              name={el?.name}
              checked={el?.checked}
              onClick={el.onClick}
              published={el.published}
            />
          );
        });
      })}
    </div>
  );
};

const TopBatch: FC<{ data: IStation[] }> = ({ data = [] }) => {
  return (
    <div className="relative flex-1 flex gap-5 ">
      <StationCard
        status={data[0]?.occupied ? "occupied" : "available"}
        name={data[0]?.name}
        checked={data[0]?.checked}
        onClick={data[0].onClick}
        published={data[0]?.published}
      />
      <div className="ml-[88px]">
        <StationCard
          status={data[1]?.occupied ? "occupied" : "available"}
          name={data[1]?.name}
          checked={data[1]?.checked}
          onClick={data[1].onClick}
          published={data[1]?.published}
        />
      </div>
      <StationCard
        status={data[2]?.occupied ? "occupied" : "available"}
        name={data[2]?.name}
        checked={data[2]?.checked}
        onClick={data[2].onClick}
        published={data[2]?.published}
      />
    </div>
  );
};

const CenterBatch: FC<{ data: IStation[] }> = ({ data }) => {
  const newForm = data.reduce((acc: any, cur: IStation, ind) => {
    if (ind === 0 || ind % 4 === 0) {
      let arr = [];
      arr.push(cur);
      acc.push(arr);
    } else {
      let lastArr = acc.pop();
      lastArr.push(cur);
      acc.push(lastArr);
    }
    return acc;
  }, []);

  return (
    <div className="relative flex-1  mt-[104px] mb-[86px] grid grid-cols-4 gap-2 ">
      {newForm.map((row: IStation[], yind: number) => {
        return row.map((el: IStation, xInd: number) => {
          return (
            <StationCard
              key={el.id}
              status={el?.occupied ? "occupied" : "available"}
              name={el?.name}
              checked={el?.checked}
              onClick={el.onClick}
              published={el?.published}
            />
          );
        });
      })}
    </div>
  );
};

const BottomBatch: FC<{ data: IStation[] }> = ({ data = [] }) => {
  return (
    <div className="relative flex-1  ml-[88px] grid grid-cols-4 gap-2">
      {data?.map((el: IStation, xInd) => {
        return (
          <StationCard
            key={el.id}
            status={el?.occupied ? "occupied" : "available"}
            name={el?.name}
            checked={el?.checked}
            onClick={el.onClick}
            published={el?.published}
          />
        );
      })}
    </div>
  );
};

const StationCard: FC<{
  onClick?: () => void;
  checked?: boolean;
  name: string;
  status: "occupied" | "available";
  published?: boolean;
}> = (props) => {
  const { name, status, checked, onClick, published } = props;
  return (
    <button
      type="button"
      disabled={status === "occupied" || !published}
      onClick={onClick}
      className={`h-[63px] p-2 gap-2 min-w-[77px] w-[88px] col-span-1 ${
        status === "occupied" || !published
          ? "bg-point-input-bg border border-transparent"
          : "bg-point-white border border-[#D9D9D9] hover:border-transparent"
      } rounded-lg  flex flex-col overflow-hidden hover:shadow-md disabled:hover:shadow-none relative
       items-center  justify-center  disabled:transition-none transition-all duration-300
       ${checked ? "shadow-lg !border-point-secondary-bg" : ""}`}
    >
      <span
        role="heading"
        className="font-medium text-xs text-point-primary-text leading-[140%]"
      >
        {name}
      </span>
      <span
        className={`flex justify-center select-none items-center cursor-pointer tracking-[0.08em] uppercase text-[8px] ${
          status === "occupied" || !published
            ? "bg-[#D9D9D9] opacity-60 text-[#181818]"
            : "text-white bg-point-green-dark"
        } rounded-sm h-5 w-[60px] py-1 px-2 font-medium whitespace-nowrap ${
          !published ? "min-w-[80px]" : ""
        } `}
        role="presentation"
      >
        {!published ? "unavailable" : status}
      </span>
    </button>
  );
};

export default SelectWorkStation;
