import React, { useEffect, useState } from "react";
import { getOtherColorVariant } from "../utils";

function PackageRepresentation({
  color,
  otherVariant,
}: {
  color: string;
  otherVariant?: string;
}) {
  const id = Math.random().toString(16).substring(2);
  const isValid = (val: string) => val?.startsWith?.("rgb");

  return (
    <div
      style={{
        padding: "1px",
        filter: "drop-shadow(0px 0px 8.74317px rgba(251, 207, 231, 0.4))",
      }}
    >
      <svg
        width="25"
        height="26"
        viewBox="0 0 25 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.4873 2.67832C11.3912 1.73662 12.8973 1.73662 13.8013 2.67832L13.9794 2.86392C14.5423 3.45026 15.3731 3.69549 16.1641 3.50877L16.4101 3.45071C17.6752 3.15206 18.9359 3.96198 19.1902 5.23682L19.2495 5.53368C19.4071 6.32378 19.9671 6.97354 20.7253 7.24595L21.0022 7.34543C22.2164 7.78162 22.8332 9.13192 22.368 10.3352L22.247 10.6483C21.9586 11.3943 22.0791 12.2366 22.565 12.8719L22.7675 13.1366C23.5496 14.159 23.3388 15.6245 22.3003 16.3851L22.0439 16.5729C21.3969 17.0467 21.0439 17.8238 21.1126 18.6229L21.1404 18.9459C21.2515 20.236 20.2748 21.3628 18.9821 21.4361L18.7125 21.4514C17.9041 21.4972 17.1796 21.9653 16.8055 22.6834L16.6772 22.9297C16.0748 24.0861 14.6317 24.5096 13.4998 23.8623L13.2846 23.7392C12.5781 23.3351 11.7105 23.3351 11.0039 23.7392L10.7887 23.8623C9.65686 24.5096 8.21375 24.0861 7.61134 22.9297L7.48304 22.6834C7.10895 21.9653 6.38442 21.4972 5.57603 21.4514L5.30648 21.4361C4.01375 21.3628 3.03707 20.236 3.1481 18.9459L3.17591 18.6229C3.24468 17.8238 2.89168 17.0467 2.24464 16.5729L1.98828 16.3851C0.949754 15.6245 0.738993 14.159 1.52109 13.1366L1.72352 12.8719C2.20947 12.2366 2.32994 11.3943 2.04154 10.6483L1.92053 10.3352C1.45536 9.13192 2.07219 7.78162 3.2863 7.34543L3.5632 7.24595C4.32142 6.97354 4.88148 6.32378 5.03909 5.53368L5.09831 5.23682C5.35262 3.96198 6.6133 3.15206 7.87849 3.45071L8.12444 3.50877C8.91546 3.69549 9.74627 3.45026 10.3091 2.86392L10.4873 2.67832Z"
          fill={`url(#paint${id}_linear_116_2920)`}
        />
        <path
          d="M10.4873 2.67832C11.3912 1.73662 12.8973 1.73662 13.8013 2.67832L13.9794 2.86392C14.5423 3.45026 15.3731 3.69549 16.1641 3.50877L16.4101 3.45071C17.6752 3.15206 18.9359 3.96198 19.1902 5.23682L19.2495 5.53368C19.4071 6.32378 19.9671 6.97354 20.7253 7.24595L21.0022 7.34543C22.2164 7.78162 22.8332 9.13192 22.368 10.3352L22.247 10.6483C21.9586 11.3943 22.0791 12.2366 22.565 12.8719L22.7675 13.1366C23.5496 14.159 23.3388 15.6245 22.3003 16.3851L22.0439 16.5729C21.3969 17.0467 21.0439 17.8238 21.1126 18.6229L21.1404 18.9459C21.2515 20.236 20.2748 21.3628 18.9821 21.4361L18.7125 21.4514C17.9041 21.4972 17.1796 21.9653 16.8055 22.6834L16.6772 22.9297C16.0748 24.0861 14.6317 24.5096 13.4998 23.8623L13.2846 23.7392C12.5781 23.3351 11.7105 23.3351 11.0039 23.7392L10.7887 23.8623C9.65686 24.5096 8.21375 24.0861 7.61134 22.9297L7.48304 22.6834C7.10895 21.9653 6.38442 21.4972 5.57603 21.4514L5.30648 21.4361C4.01375 21.3628 3.03707 20.236 3.1481 18.9459L3.17591 18.6229C3.24468 17.8238 2.89168 17.0467 2.24464 16.5729L1.98828 16.3851C0.949754 15.6245 0.738993 14.159 1.52109 13.1366L1.72352 12.8719C2.20947 12.2366 2.32994 11.3943 2.04154 10.6483L1.92053 10.3352C1.45536 9.13192 2.07219 7.78162 3.2863 7.34543L3.5632 7.24595C4.32142 6.97354 4.88148 6.32378 5.03909 5.53368L5.09831 5.23682C5.35262 3.96198 6.6133 3.15206 7.87849 3.45071L8.12444 3.50877C8.91546 3.69549 9.74627 3.45026 10.3091 2.86392L10.4873 2.67832Z"
          fill={`url(#paint1_linear_116_2920)`}
          fillOpacity="0.1"
        />
        <path
          d="M3.79089 13.1634C3.79089 8.54997 7.5308 4.81006 12.1442 4.81006V4.81006C16.7576 4.81006 20.4975 8.54997 20.4975 13.1634V13.3352C20.4975 17.9486 16.7576 21.6885 12.1442 21.6885V21.6885C7.5308 21.6885 3.79089 17.9486 3.79089 13.3352V13.1634Z"
          fill="black"
          fillOpacity="0.04"
        />
        <path
          d="M19.6399 13.1634V13.3352C19.6399 17.4749 16.284 20.8309 12.1442 20.8309C8.00444 20.8309 4.64849 17.4749 4.64849 13.3352V13.1634C4.64849 9.02361 8.00444 5.66766 12.1442 5.66766C16.284 5.66766 19.6399 9.0236 19.6399 13.1634Z"
          stroke="white"
          strokeOpacity="0.3"
          strokeWidth="1.7152"
        />
        <path
          opacity="0.8"
          d="M10.7676 14.7745L9.16365 13.1541C9.07729 13.0666 8.96005 13.0175 8.83778 13.0175C8.71551 13.0175 8.59827 13.0666 8.51191 13.1541C8.33164 13.3362 8.33164 13.6304 8.51191 13.8125L10.444 15.7645C10.6243 15.9466 10.9155 15.9466 11.0958 15.7645L15.9861 10.8238C16.1664 10.6417 16.1664 10.3475 15.9861 10.1654C15.8998 10.078 15.7825 10.0288 15.6602 10.0288C15.538 10.0288 15.4207 10.078 15.3344 10.1654L10.7676 14.7745Z"
          fill="white"
          stroke="#FDFDFD"
          strokeWidth="0.34304"
        />
        <path
          d="M20.6105 13.3261C20.6105 18.0018 16.8201 21.7923 12.1443 21.7923C7.46856 21.7923 3.6781 18.0018 3.6781 13.3261C3.6781 8.65032 7.46856 4.85986 12.1443 4.85986C16.8201 4.85986 20.6105 8.65032 20.6105 13.3261ZM5.42109 13.3261C5.42109 17.0392 8.43118 20.0493 12.1443 20.0493C15.8575 20.0493 18.8675 17.0392 18.8675 13.3261C18.8675 9.61295 15.8575 6.60285 12.1443 6.60285C8.43118 6.60285 5.42109 9.61295 5.42109 13.3261Z"
          fill="url(#paint2_linear_116_2920)"
          fillOpacity="0.5"
        />
        <g filter="url(#filter0_ddd_116_2920)">
          <path
            d="M57.6274 62.6156L54.4323 59.3877C54.2603 59.2135 54.0268 59.1156 53.7832 59.1156C53.5396 59.1156 53.3061 59.2135 53.1341 59.3877C52.775 59.7505 52.775 60.3365 53.1341 60.6993L56.9829 64.5877C57.342 64.9505 57.922 64.9505 58.2811 64.5877L68.0228 54.7458C68.3819 54.383 68.3819 53.797 68.0228 53.4342C67.8508 53.26 67.6172 53.1621 67.3737 53.1621C67.1301 53.1621 66.8966 53.26 66.7245 53.4342L57.6274 62.6156Z"
            fill="white"
          />
          <path
            d="M57.6274 62.6156L54.4323 59.3877C54.2603 59.2135 54.0268 59.1156 53.7832 59.1156C53.5396 59.1156 53.3061 59.2135 53.1341 59.3877C52.775 59.7505 52.775 60.3365 53.1341 60.6993L56.9829 64.5877C57.342 64.9505 57.922 64.9505 58.2811 64.5877L68.0228 54.7458C68.3819 54.383 68.3819 53.797 68.0228 53.4342C67.8508 53.26 67.6172 53.1621 67.3737 53.1621C67.1301 53.1621 66.8966 53.26 66.7245 53.4342L57.6274 62.6156Z"
            stroke="#FDFDFD"
            strokeWidth="0.996009"
          />
        </g>
        <defs>
          <filter
            id="filter0_ddd_116_2920"
            x="0.199841"
            y="0.497204"
            width="111.126"
            height="107.396"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="4.81551" dy="4.81551" />
            <feGaussianBlur stdDeviation="7.22326" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_116_2920"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-4.81551" dy="-4.81551" />
            <feGaussianBlur stdDeviation="23.6757" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.498039 0 0 0 0 0.866667 0 0 0 0 0.32549 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="effect1_dropShadow_116_2920"
              result="effect2_dropShadow_116_2920"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="2.91892" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.85959 0 0 0 0 0.991856 0 0 0 0 0.578524 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_dropShadow_116_2920"
              result="effect3_dropShadow_116_2920"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect3_dropShadow_116_2920"
              result="shape"
            />
          </filter>
          <linearGradient
            id={`paint${id}_linear_116_2920`}
            x1="4.32712"
            y1="3.20865"
            x2="17.5437"
            y2="24.0409"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color} />
            <stop
              offset="1"
              stopColor={
                isValid(otherVariant || getOtherColorVariant(color))
                  ? otherVariant || getOtherColorVariant(color)
                  : "#CBCDFC"
              }
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_116_2920"
            x1="-4.04195"
            y1="-0.930364"
            x2="14.2623"
            y2="31.7411"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_116_2920"
            x1="12.1443"
            y1="4.85986"
            x2="12.1443"
            y2="21.7923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="0.0001" stopColor="white" stopOpacity="0.81" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default PackageRepresentation;
