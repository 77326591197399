import Sidebar from "../../../components/modal/Sidebar";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Notification from "./Notification";
import useObserver from "../../../hooks/useObserver";

import { useInfiniteQuery } from "react-query";
import { ChatDataWithCursor } from "../../../pages/customers/utils/apis/chats";
import {
  getNotificationsAction,
  GET_NOTIFICATIONS,
  useGetNotificationQuery,
} from "../../../pages/customers/utils/apis/notification";
import { Icons } from "../../../assets/icons";
import useNotification from "../../../hooks/useNotification";
import BeatLoader from "react-spinners/BeatLoader";
import BounceLoader from "react-spinners/BounceLoader";

const getUniques = (arr: any[], key: string = "id") => {
  return arr
    .map((item) => item[key])
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((item) => {
      return {
        [key]: item,
        ...arr.find((s) => s[key] === item),
      };
    });
};
function Notifications() {
  const cursorRef = useRef<HTMLDivElement>(null);
  const thresholdRef = useMemo(() => {
    return { threshold: 0.2 };
  }, []);
  const [showNotifications, setShowNotifications] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [bottomElement] = useObserver(cursorRef, "", thresholdRef);

  const inView = bottomElement.toLowerCase() === "bottom";

  useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const {
    data: AllNotification,
    isLoading,
    isFetchingNextPage,
    isSuccess,
    isError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<ChatDataWithCursor>(
    [GET_NOTIFICATIONS],
    ({ pageParam = 1 }) =>
      getNotificationsAction({
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage?.next
          ? new URL(lastPage.next).searchParams.get("page")
          : 1;
      },
    }
  );
  const allFlattenNots = useMemo(() => {
    if (!AllNotification?.pages) return [];
    return getUniques(
      AllNotification.pages.reduce<any[]>((acc, cur) => {
        return acc.concat((cur?.results as any[]) || []);
      }, [])
    ).filter((el) => el.object_type !== "chat");
  }, [AllNotification?.pages]);

  const unreadCount = allFlattenNots.reduce((acc, cur) => {
    return acc + (cur.read ? 0 : 1);
  }, 0);

  return (
    <div className="">
      <div
        className="relative"
        role="button"
        onClick={() => setShowNotifications((prev) => !prev)}
      >
        <img
          src={Icons.menubar.notificationIcon}
          alt="notifications"
          className="w-7 h-7"
        />
        {unreadCount ? (
          <span className="absolute -top-1 right-0.5 bg-point-pink-dark align-middle text-point-white rounded-full font-medium text-[8px] w-4 h-4 p-1 flex justify-center items-center">
            {unreadCount > 9 ? "9+" : unreadCount}
          </span>
        ) : null}
      </div>
      <Sidebar
        transparent
        open={showNotifications}
        onClose={() => setShowNotifications(false)}
      >
        {selectedItem ? (
          <NotificationDetail
            data={selectedItem}
            onClose={() => setSelectedItem(null)}
          />
        ) : (
          <NotificationSummary
            unreadCount={unreadCount}
            cursorRef={cursorRef}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            allFlattenNots={allFlattenNots}
            onClick={(not) => setSelectedItem(not)}
          />
        )}
      </Sidebar>
    </div>
  );
}

const NotificationSummary = (props: {
  unreadCount: number;
  cursorRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  allFlattenNots: any[];
  onClick: (not: any) => void;
}) => {
  const {
    unreadCount,
    cursorRef,
    isLoading,
    isFetchingNextPage,
    allFlattenNots,
    onClick,
  } = props;
  return (
    <div className="w-full h-full flex flex-col gap-8">
      <div className="flex flex-col px-4 pt-4">
        <h2 className="font-medium text-[28px] leading-[140%] text-point-primary-text">
          Notifications
        </h2>
        <p className="text-point-secondary-text text-base leading-[180%] font-medium">
          {unreadCount} unread
        </p>
      </div>
      <>
        <div
          ref={cursorRef}
          className="h-full flex-1 flex flex-col justify-start items-start w-full  gap-4 p-5 pr-3 pb-32 max-h-[83vh] overflow-auto  scroller"
        >
          {isLoading ? (
            <div className="w-full flex justify-center items-center">
              <BounceLoader color="#1C3C54" size={10} />
            </div>
          ) : (
            (allFlattenNots || []).map((not: any, idx) => {
              return (
                <Notification
                  onClick={() => onClick(not)}
                  key={not.id}
                  data={not}
                />
              );
            })
          )}
          <div id="bottom"></div>
        </div>
        {isFetchingNextPage ? (
          <div className="w-full flex justify-center items-center">
            <BeatLoader color="#1C3C54" size={10} />
          </div>
        ) : null}{" "}
      </>
    </div>
  );
};

const NotificationDetail: FC<{ onClose: () => void; data: any }> = ({
  onClose,
  data: payload,
}) => {
  const { data: details, isLoading } = useGetNotificationQuery(payload?.id, {
    enabled: !!payload.id,
  });
  console.log(details, "DETAILS");
  return isLoading ? (
    <div className="w-full flex justify-center items-center h-[80vh]">
      <BounceLoader color="#1C3C54" size={10} />
    </div>
  ) : (
    <div className="w-full h-full p-4 flex flex-col max-h-[80vh] overflow-auto gap-5">
      <button
        onClick={onClose}
        className="bg-point-input-bg flex justify-center items-center w-9 h-9 rounded"
      >
        <img src={Icons.utility.leftArrowIcon} alt="go back" />
      </button>
      <h2 className="mt-3 text-point-primary-text leading-[140%] font-medium text-[22px] capitalize">
        {details?.object_type}
      </h2>
      <p className="w-full font-light text-base leading-[180%] text-point-primary-text">
        {details?.message}
      </p>
    </div>
  );
};

export default Notifications;
