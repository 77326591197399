import React, {
  createContext,
  FC,
  useCallback,
  useMemo,
  useState,
} from "react";

interface INotification {
  notifications: any[];
  unreadCount: number;
  loadNotifications: (notifications: any[], unread: number) => void;
  addNotification: (notification: any) => void;
}
export const notCtx = createContext<INotification>({
  notifications: [],
  addNotification: (not: any) => {},
  loadNotifications: (not: any, count: number) => {},
  unreadCount: 0,
});

const NotificationProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const [unread, setUnread] = useState(0);
  const loadNotifications = useCallback(
    (notifications: any[], count: number) => {
      setNotifications(notifications);
      setUnread(count);
    },
    []
  );
  const addNotification = (not: any) => {
    setNotifications((prev) => [not, ...prev]);
  };
  const api: INotification = useMemo(() => {
    return {
      notifications,
      loadNotifications,
      addNotification,
      unreadCount: unread,
    };
  }, []);

  return <notCtx.Provider value={api}>{children}</notCtx.Provider>;
};

export default NotificationProvider;
