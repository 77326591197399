import Modal from "../../../components/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import { Icons } from "../../../assets/icons";
import Connector from "./../components/Connector";
import ContactInformation from "../components/BookSpace/ContactInformation";
import Button from "../../../pages/auth/components/Button";
import SelectSpaceType from "../components/BookSpace/SelectSpaceType";
import SelectPackageType from "../components/BookSpace/SelectPackageType";
import MakePayment from "../components/BookSpace/MakePayment";
import PackageRepresentation from "../../../pages/packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../pages/packages/utils";
import SelectMeetingRoom from "../components/BookSpace/SelectMeetingRoom";
import { useGetSpacesQuery } from "../../../pages/spaces/utils/apis/getSpaces";
import { FormikHelpers, useFormik } from "formik";
import createWorkStationSchema from "../utils/createworkstation.validator";
import createMeetingRoomSchema from "../utils/createmeetingroom.validator";
import BookSpaceSuccessful from "./components/BookSpaceSuccessful";
import FormActions from "./components/FormActions";
import {
  INewBooking,
  useCreateBookingMutation,
} from "../utils/apis/getDashboard";

interface IBook {
  open: boolean;
  onClose: () => void;
}
function BookMeetingRoom(props: IBook) {
  const { open, onClose } = props;
  const [showCompleteBook, setShowCompleteBook] = useState(false);
  const [response, setResponse] = useState<INewBooking & { id?: number }>();
  useGetSpacesQuery("", "", 1, "meeting room", true);
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="top-[94px] max-h-[786px] overflow-scroll scroller"
      variant={showCompleteBook ? "sm" : "md"}
      disableBackdrop={showCompleteBook}
    >
      {!showCompleteBook ? (
        <CreateSpace
          onClose={onClose}
          open={open}
          setShowCompleteBook={setShowCompleteBook}
          setResponse={setResponse}
        />
      ) : (
        <BookSpaceSuccessful
          onClose={onClose}
          type="meeting room"
          payload={response}
        />
      )}
    </Modal>
  );
}

const CreateSpace: FC<
  Pick<IBook, "onClose" | "open"> & {
    setShowCompleteBook: React.Dispatch<React.SetStateAction<boolean>>;
    setResponse: React.Dispatch<
      React.SetStateAction<
        | (INewBooking & {
            id?: number | undefined;
          })
        | undefined
      >
    >;
  }
> = ({ onClose, open, setShowCompleteBook, setResponse }) => {
  return (
    <section className="px-6 py-[33px] flex flex-col gap-[71px]">
      <BookSpaceHeader onClose={onClose} />
      <CreateBookSpaceForm
        open={open}
        onFinal={(resp) => {
          setShowCompleteBook(true);
          setResponse(resp);
        }}
      />
    </section>
  );
};

const BookSpaceHeader: FC<Pick<IBook, "onClose">> = ({ onClose }) => {
  return (
    <header className="w-full flex flex-col gap-2">
      <div className="flex justify-end w-full">
        <button onClick={onClose}>
          <img src={Icons.utility.cancelIcon} alt="Cancel Modal" />
        </button>
      </div>
      <div>
        <h2 className="text-point-primary-text font-medium text-[24px] leading-[140%]">
          Book Meeting Room
        </h2>
        <p className="font-medium text-base leading-[180%] text-point-secondary-text">
          Secure an available meeting room now for the customers
        </p>
      </div>
    </header>
  );
};

const CreateBookSpaceForm: FC<
  Pick<IBook, "open"> & { onFinal: (payload: INewBooking) => void }
> = ({ open, onFinal }) => {
  const totalStage = 4;
  const [currentStage, setCurrentStage] = useState(1);
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    customer_type: "resident",
    phone_number: "",
    package: "",
    space: "",
    start_date: "",
    end_date: "",
    num_of_packages: "",
    payment_type: "",
  };
  useEffect(() => {
    setCurrentStage(1);
  }, [open]);

  const { mutateAsync: createBooking, isLoading: isCreating } =
    useCreateBookingMutation();
  const submitHandler = async (
    values: typeof initialValues,
    { setSubmitting }: FormikHelpers<typeof initialValues>
  ) => {
    console.log(values, "VALUES FROM SUBMIT");
    const resp = await createBooking(values);
    console.log(resp, "RESPONSE FROM SERVER");
    if (resp) {
      onFinal?.(resp);
    }
    setSubmitting(true);
  };
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
  } = useFormik({
    onSubmit: submitHandler,
    validationSchema: createMeetingRoomSchema(currentStage),
    initialValues: initialValues,
  });
  console.log(values, "VALUES", errors);
  return (
    <main className="w-full flex flex-col justify-start gap-[50px]">
      <Connector
        current={currentStage}
        total={totalStage}
        values={[
          { label: "Customer Info" },
          { label: "Meeting Room" },
          { label: "Package Type" },
          { label: "Payment" },
        ]}
      />
      <form
        onSubmit={handleSubmit}
        className="flex flex-col justify-start gap-[50px]"
      >
        <div className="mt-[40px]">
          {currentStage === 1 && (
            <ContactInformation
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              touched={touched}
            />
          )}
          {currentStage === 2 && (
            <SelectMeetingRoom setFieldValue={setFieldValue} values={values} />
          )}
          {currentStage === 3 && (
            <SelectPackageType
              type="meeting room"
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
            />
          )}
          {currentStage === 4 && (
            <MakePayment values={values} setFieldValue={setFieldValue} />
          )}
        </div>
        <FormActions
          currentStage={currentStage}
          setCurrentStage={setCurrentStage}
          totalStage={totalStage}
          isSubmitting={isCreating || isSubmitting}
          isValid={
            (() => (currentStage === 2 ? Boolean(values.space) : true))() &&
            (() =>
              currentStage === 1 || currentStage === 3 ? isValid : true)() &&
            (() => (currentStage === 4 ? Boolean(values.payment_type) : true))()
          }
        />
      </form>
    </main>
  );
};

export default BookMeetingRoom;
