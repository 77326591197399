import Button from "../../../../pages/auth/components/Button";
import React, { FC } from "react";

const FormActions: FC<{
  currentStage: number;
  setCurrentStage: React.Dispatch<React.SetStateAction<number>>;
  totalStage: number;
  isValid: boolean;
  isSubmitting: boolean;
}> = ({ currentStage, setCurrentStage, totalStage, isValid, isSubmitting }) => {
  const onNext = () => {
    if (currentStage > totalStage || currentStage === 4) return;
    setCurrentStage((prev) => prev + 1);
  };
  const onPrev = () => {
    if (currentStage <= 1) return;
    setCurrentStage((prev) => prev - 1);
  };

  return (
    <div className="w-full flex justify-start items-center gap-2.5">
      <Button
        onClick={onPrev}
        label="Back"
        className="bg-[#F7F7F7] h-12 disabled:bg-[#F7F7F780] text-point-primary-text disabled:text-[#666]"
        disabled={1 === currentStage}
      />
      <Button
        onClick={onNext}
        loading={isSubmitting}
        type={currentStage === 4 ? "submit" : "button"}
        label={currentStage === 4 ? "Make Payment" : "Next"}
        disabled={totalStage + 1 === currentStage || !isValid || isSubmitting}
        className={`px-8 whitespace-nowrap h-12 ${
          currentStage === 4 ? "min-w-[170px]" : ""
        } `}
      />
    </div>
  );
};

export default FormActions;
