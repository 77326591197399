import { baseAPIURL } from "../constants/api";

export { baseAPIURL };

export const login = "/users/auth/signin/";
export const forgotPassword = "/users/auth/password/reset/";
export const changePassword = "/users/auth/password/change/";
export const updateMyDetails = (userId: string, clear?: boolean) =>
  `/users/admins/${userId}/${clear ? "?clear=true" : ""}`;
export const resetAdminPassword = "/users/auth/admin/password/reset/";
export const createAdmin = "/users/admin/signup/";
export const getAdmins = (qs: string) => `/users/admins/${qs}`;
export const adminPermissions = "/users/admin/permissions/";

export const getActivities = (qs: string) => `/users/activities/${qs}`;

export const dashboard = "/dashboard/";
export const frontdesk = "/frontdesk/";

export const getSpaces = (qs: string) => `/spaces/${qs}`;
export const getSpaceImages = (qs: string) => `/space_images/${qs}`;

export const getSingleSpace = (spaceId: string) => `/spaces/${spaceId}/`;

export const getBroadcast = (qs: string) => `/broadcasts/${qs}`;

export const getPromotion = (qs: string) => `/promotions/${qs}`;

export const promotionImages = "/promotion_images/";

export const getPackages = (qs: string) => `/packages/${qs}`;

export const getCustomers = (qs: string) => `/users/customers/${qs}`;

export const getBookings = (qs: string) => `/bookings/${qs}`;

export const getSpaceUsages = (qs: string) => `/spaces/usage/${qs}`;
export const getPackageUsages = (qs: string) => `/packages/usage/${qs}`;
export const getSpaceValuations = (qs: string) => `/spaces/valuation/${qs}`;

export const getCustomersReports = (qs: string) =>
  `/users/customers/report/${qs}`;
export const getCustomerReportsDetail = (qs: string) =>
  `/users/customers/report/detail/${qs}`;

export const getPromotionsReport = (qs: string) => `/promotions/report/${qs}`;
export const getPromotionPerformance = (qs: string) =>
  `/promotion/performance/${qs}`;

export const packagesReport = "/packages/report/";

export const getMetrics = (qs: string) => `/metrics/${qs}`;
export const getRevenue = (qs: string) => `/revenue/${qs}`;
export const getOccupancy = (qs: string) => `/occupancy/${qs}`;

export const getChatsSummary = (qs: string) => `/users/chats/${qs}`;
export const getMessagesOfChat = (qs: string) => `/users/chat_messages/${qs}`;

export const getNotified = (qs: string) => `/users/notifications/${qs}`;
