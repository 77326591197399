import { Icons } from "../../../../assets/icons";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import Sidebar from "../../../../components/modal/Sidebar";
import {
  convertToLocalTime,
  days,
  getMeridian,
  isNextMonth,
  isPrevMonth,
  isToday,
  months,
} from "../../../../utils/dateHelper";
import PackageRepresentation from "../../../../pages/packages/components/PackageRepresentation";
import { getOtherColorVariant } from "../../../../pages/packages/utils";
import StatusButton from "./StatusButton";
import { IBooking } from "./MonthlyView";
import { pad } from "../../../../utils/stringHelpers";
import { BookingStatus } from "pages/dashboard/dashboard.types";
const statusMap: Record<string, BookingStatus> = {
  "clock-in": "clock in",
  "on-seat": "On-Seat",
  "clocked-out": "clocked out",
};
const getDateFormatted = (date: string) => {
  const startDate = new Date(date);
  return `${startDate.getDate()} ${days[startDate.getDay()]}, ${
    months[startDate.getMonth()]
  } ${startDate.getFullYear()}`;
};
const DayOfMonth: FC<{
  day: any;
  index: number;
  onClick?: () => void;
  currentDate: Date;
  data?: IBooking[];
}> = (props) => {
  const { day, index, onClick = () => {}, currentDate, data } = props;
  const [showDetail, setShowDetail] = useState(false);
  const [viewType, setViewType] = useState<"work station" | "meeting room">(
    "work station"
  );

  useEffect(() => {
    if (showDetail) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = `0px`;
      document.body.style.right = `0px`;
    } else {
      document.body.style.overflowY = "auto";
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [showDetail]);
  const selectedDay = currentDate;
  const today = new Date();
  const isFirstColumn = () => index % 7 === 0;
  const isLastRow = () => index > 27;

  const countDetails =
    useMemo(
      () =>
        data?.reduce?.((acc: Record<string, IBooking[]>, cur) => {
          acc[cur.space.space_type] = (acc[cur.space.space_type] || []).concat(
            cur
          );
          return acc;
        }, {}),
      [data]
    ) || {};

  return (
    <>
      <div
        onClick={
          isPrevMonth(selectedDay, day) ? () => {} : () => setShowDetail(true)
        }
        className={`h-[166px] 
     border-t-0 ${
       isFirstColumn() ? "border-l-1" : "border-l-0"
     } last-of-type:rounded-br-lg ${
          isFirstColumn() && isLastRow() ? "rounded-bl-lg" : ""
        }
      ${
        isPrevMonth(selectedDay, day) || isNextMonth(selectedDay, day)
          ? " bg-[#F8FAFC] text-[#94A3B8]"
          : ""
      }
      ${isToday(day) ? " bg-[#1C3C54] text-white" : ""}
      
     flex col-span-1 flex-col justify-between items-start gap-3.5 px-2 xl:px-5 pt-3.5 pb-5  border border-[#94a3b8] cursor-pointer`}
      >
        <strong className="font-semibold text-[20px] leading-7">
          {day.getDate()}
        </strong>
        {!(isPrevMonth(selectedDay, day) || isNextMonth(selectedDay, day)) && (
          <div className="flex-1 flex justify-start items-center content-start gap-2.5 flex-wrap overflow-hidden h-10 hover:h-24">
            <SpaceStatus count={23} type="work station" onseat={"clock in"} />
            <SpaceStatus count={3} type="meeting room" onseat={"clock in"} />
            {isToday(day) && (
              <SpaceStatus
                count={countDetails["work station"]?.length || 0}
                type="work station"
                onseat={"On-Seat"}
              />
            )}
            {isToday(day) && (
              <SpaceStatus
                count={countDetails["meeting room"]?.length || 0}
                type="meeting room"
                onseat={"On-Seat"}
              />
            )}
          </div>
        )}
      </div>
      <MonthlyDetailsModal
        countDetails={countDetails}
        viewType={viewType}
        setViewType={setViewType}
        showDetail={showDetail}
        setShowDetail={setShowDetail}
      />
    </>
  );
};

const imageIconMap = {
  "work station": {
    "clock in": Icons.dashboard.stationUserIcon,
    "clocked out": Icons.dashboard.stationUserIcon,
    "On-Seat": Icons.dashboard.stationUserCheckedIcon,
  },
  "meeting room": {
    "clock in": Icons.dashboard.meetingUserIcon,
    "clocked out": Icons.dashboard.meetingUserIcon,
    "On-Seat": Icons.dashboard.meetingUserCheckedIcon,
  },
};

const SpaceStatus: FC<{
  count: number;
  type: "work station" | "meeting room";
  onseat: "On-Seat" | "clocked out" | "clock in";
}> = ({ count, type, onseat }) => {
  return (
    <div
      style={{
        boxShadow:
          "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
      }}
      className="flex flex-1 justify-center items-center gap-1.5 h-[36px] bg-point-white rounded  py-2 xl:px-3 lg:px-2 lg:gap-1"
    >
      <img
        src={imageIconMap[type][onseat]}
        alt={`${type}-${onseat ? "clocked-in" : "not yet clockedin"}`}
        width={onseat !== "On-Seat" && type == "work station" ? 13 : 20}
        height={20}
      />
      <strong className="font-semibold lg:text-xs xl:text-sm  leading-5 text-[#475467] text-right">
        {count}
      </strong>
    </div>
  );
};

export const MonthlyDetailsModal: FC<{
  showDetail: boolean;
  setShowDetail: (val: boolean) => void;
  setViewType: React.Dispatch<
    React.SetStateAction<"work station" | "meeting room">
  >;
  viewType: "work station" | "meeting room";
  countDetails: Record<"work station" | "meeting room", any[]>;
}> = ({ showDetail, setShowDetail, viewType, setViewType, countDetails }) => {
  return (
    <Sidebar freeze open={showDetail} onClose={() => setShowDetail(false)}>
      <div className="w-full pb-6 flex flex-col justify-start items-start relative h-[780px] overflow-auto scroller">
        <header className="w-full sticky top-0 left-0 bg-white pt-6 z-50 flex flex-col gap-6 px-6 pb-5 border-b">
          <div className="w-full flex items-center justify-between">
            <h1 className="text-[20px] leading-7 py-0.5 text-[#101828] font-medium">
              Space Bookings
            </h1>
            <button onClick={() => setShowDetail(false)}>
              <img
                src={Icons.utility.cancelIcon}
                alt="close detail"
                className="w-8 h-8"
              />
            </button>
          </div>
          <div
            role="switch"
            className="p-0.5 rounded-md bg-[#EAECF0] flex justify-start gap-1 items-center font-medium text-sm transition-colors duration-300"
          >
            <button
              className={`rounded py-2 px-[26px] gap-2.5 flex justify-center items-center ${
                viewType === "work station"
                  ? "bg-[#FCFCFD] text-[#1C3C54]"
                  : "bg-transparent text-[#475467]"
              }`}
              onClick={() => setViewType("work station")}
            >
              <span>Work Stations</span>
              <span className="w-6 h-6 py-0.5 px-2 rounded-full bg-[#f2f4f7] flex justify-center items-center font-medium text-xs leading-[18px] text-[#344054]">
                {countDetails["work station"]?.length
                  ? countDetails["work station"]?.length > 9
                    ? "9+"
                    : countDetails["work station"]?.length
                  : 0}
              </span>
            </button>
            <button
              className={`rounded py-2 px-[26px] gap-2.5 flex justify-center items-center ${
                viewType === "meeting room"
                  ? "bg-[#FCFCFD] text-[#1C3C54]"
                  : "bg-transparent text-[#475467]"
              }`}
              onClick={() => setViewType("meeting room")}
            >
              <span>Meeting Rooms</span>
              <span className="w-6 h-6 py-0.5 px-2 rounded-full bg-[#f2f4f7] flex justify-center items-center font-medium text-xs leading-[18px] text-[#344054]">
                {countDetails["meeting room"]?.length
                  ? countDetails["meeting room"]?.length > 9
                    ? "9+"
                    : countDetails["meeting room"]?.length
                  : 0}
              </span>
            </button>
          </div>
        </header>
        <main className="w-full p-6 flex flex-col gap-4  flex-1">
          {!!(countDetails[viewType] || []).length ? (
            countDetails[viewType].map((el) => {
              return (
                <SpaceDetails
                  key={el.booking_id}
                  type={viewType}
                  onseat={statusMap[el.clock_status]}
                  userDetails={{
                    name: `${el.customer.first_name} ${el.customer.last_name}`,
                    packageColor: el.package.color_code,
                    packageName: el.package.name,
                    packageType: el.package.package_type,
                  }}
                  startDate={el.start_date}
                  endDate={el.end_date}
                  name={el.space.name}
                  clockIn={el.clock_in}
                  clockOut={el.clock_out}
                />
              );
            })
          ) : (
            <div className="h-full flex justify-center items-center text-[18px] font-medium text-center">
              No {viewType} bookings for today Or check your applied filter
            </div>
          )}
        </main>
      </div>
    </Sidebar>
  );
};

const SpaceDetails: FC<{
  type: "work station" | "meeting room";
  onseat: "On-Seat" | "clocked out" | "clock in";
  name: string;
  userDetails?: {
    name: string;
    packageName: string;
    packageColor: string;
    packageType: "day" | "hour" | "month";
  };
  startDate: string;
  endDate: string;
  clockIn: string;
  clockOut: string;
}> = ({
  type,
  name,
  userDetails,
  startDate,
  endDate,
  onseat,
  clockIn,
  clockOut,
}) => {
  const [showAll, setShowAll] = useState(false);
  return (
    <div
      className={`w-full min-h-[107px] ${
        showAll ? "  max-h-max" : "h-[107px]"
      } overflow-hidden transition-all duration-300`}
    >
      <div
        className={`flex transition-all duration-300 flex-col gap-0.5 bg-white border border-[#EAECF0] rounded-lg  pl-6 pr-3 pt-3 pb-4 ${
          showAll ? "rounded-bl-none rounded-br-none" : ""
        } `}
      >
        <div className="w-full flex justify-between items-center">
          <div className="flex gap-4 items-center justify-start">
            <img
              src={imageIconMap[type][onseat]}
              alt={`${type}-${onseat ? "clocked-in" : "not yet clockedin"}`}
              width={
                (onseat === "clock in" || onseat === "clocked out") &&
                type == "work station"
                  ? 13
                  : 20
              }
              height={20}
            />
            <h2 className="font-medium text-[18px] leading-[28px] text-[#344054]">
              {name}
            </h2>
          </div>
          <button
            className="appearance-none"
            type="button"
            onClick={() => setShowAll((prev) => !prev)}
          >
            <img
              src={
                showAll
                  ? Icons.dashboard.collapseIcon
                  : Icons.dashboard.expandIcon
              }
              alt="expand or collapse"
              className="w-6 h-6"
            />
          </button>
        </div>
        {userDetails ? (
          <div className="w-full flex justify-center items-center gap-2">
            <h4 className="text-[#667085] text-sm font-medium leading-6 text-center">
              {userDetails.name}
            </h4>
            <PackageCard
              name={userDetails.packageName}
              color={userDetails.packageColor}
            />
          </div>
        ) : null}
      </div>
      <div className="w-full bg-[#f9fafb] flex-1 py-6 px-14 flex flex-col items-start h-full gap-8 ">
        {onseat === "clock in" && (
          <>
            <div className="flex flex-col gap-1 justify-start items-start">
              <h4 className="font-medium text-sm leading-[160%] text-[#667085]">
                Start Date
              </h4>
              <p className="font-medium text-base leading-6 text-[#344054]">
                {getDateFormatted(startDate)}
              </p>
            </div>
            <div className="flex flex-col gap-1 justify-start items-start">
              <h4 className="font-medium text-sm leading-[160%] text-[#667085]">
                End Date
              </h4>
              <p className="font-medium text-base leading-6 text-[#344054]">
                {getDateFormatted(endDate)}
              </p>
            </div>
          </>
        )}
        {onseat === "clocked out" && (
          <div className="flex flex-col gap-1 justify-start items-start">
            <h4 className="font-medium text-base leading-6 text-[#667085]">
              {getDateFormatted(clockOut)}
            </h4>
            <p className="font-medium text-[#344054] text-sm leading-6">
              {userDetails?.packageType === "day" ||
              userDetails?.packageType === "month"
                ? "08:00 AM - 06:00 PM" +
                  `${userDetails?.packageType === "month" ? "   Daily" : ""}`
                : (() => {
                    const startTime = convertToLocalTime(startDate) as Date;
                    const endTime = convertToLocalTime(endDate) as Date;
                    return `${pad(startTime.getHours(), 2)}:${pad(
                      startTime.getMinutes(),
                      2
                    )} ${getMeridian(startDate)} - 
                    ${pad(endTime.getHours(), 2)}:${pad(
                      endTime.getMinutes(),
                      2
                    )} ${getMeridian(endDate)}
                    `;
                  })()}
            </p>
          </div>
        )}
        {onseat === "On-Seat" && (
          <div className="flex flex-col gap-1 justify-start items-start">
            <h4 className="font-medium text-base leading-6 text-[#667085]">
              {getDateFormatted(clockIn)}
            </h4>
            <p className="font-medium text-[#344054] text-sm leading-6">
              {userDetails?.packageType === "day" ||
              userDetails?.packageType === "month"
                ? "08:00 AM - 06:00 PM" +
                  `${userDetails?.packageType === "month" ? "   Daily" : ""}`
                : (() => {
                    const startTime = convertToLocalTime(startDate) as Date;
                    const endTime = convertToLocalTime(endDate) as Date;
                    return `${pad(startTime.getHours(), 2)}:${pad(
                      startTime.getMinutes(),
                      2
                    )} ${getMeridian(startDate)} - 
                    ${pad(endTime.getHours(), 2)}:${pad(
                      endTime.getMinutes(),
                      2
                    )} ${getMeridian(endDate)}
                    `;
                  })()}
            </p>
          </div>
        )}
        <StatusButton
          status={onseat}
          className="min-h-[44px] text-base font-semibold leading-6"
        />
      </div>
    </div>
  );
};

const PackageCard: FC<{
  name: string;
  color: string;
}> = ({ name, color }) => {
  return (
    <div
      role="presentation"
      style={{
        background: `linear-gradient(#fff, #fff) padding-box, linear-gradient(45deg,  ${color}, ${getOtherColorVariant(
          color || ""
        )}) border-box`,
        border: "1px solid transparent",
      }}
      className={`flex justify-start items-center rounded p-2 gap-2 
       border  text-[#d9d9d9] bg-white text-[${color}]`}
    >
      <PackageRepresentation color={color} />
      <h6
        style={{ color }}
        className="capitalize text-sm leading-[180%] font-medium"
      >
        {name}
      </h6>
    </div>
  );
};
export default DayOfMonth;
