
import React from "react";
import PropTypes from 'prop-types';
import AppError from "../pages/errors/AppError";
import ErrorLayout from "../layouts/error";


class AppErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, error:null };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error: error };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      // Maybe call an external logging service or endpoint here
      
    }
    
    render()  {
      if ((this.state).hasError) {
        // You can render any custom fallback UI
        return (
            <ErrorLayout>
            <AppError error={this.state.error} />
            </ErrorLayout>
        );
      }
  
      return this.props.children; 
    }
}
  
AppErrorBoundary.propTypes = {
  children : PropTypes.element
}

  export default AppErrorBoundary;