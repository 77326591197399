import Button from "../../pages/auth/components/Button";
import React, { FC } from "react";
import { useNavigate } from "react-router";

function AppError() {
  return <CardDesign />;
}

const CardDesign: FC = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <div
      style={{ boxShadow: "0px 40px 64px rgba(5, 24, 38, 0.15)" }}
      className="bg-point-blue rounded-3xl py-10 px-8 gap-10 flex flex-col items-center  mt-[186px] w-[564px]"
    >
      <h1 className="text-white font-extrabold text-[100px] leading-[160%] ">
        Oops!
      </h1>
      <h2 className="text-white tracking-[0.1em] uppercase leading-[140%] text-[18px] font-bold">
        IT SEEMS THE APPLICATION HAS CRASHED
      </h2>
      <p className="text-center text-base font-medium text-white">
        {" "}
        This is no fault of yours, our engineers will be looking into this
        shortly.
      </p>
      <div className="action flex justify-between items-center gap-4">
        <Button
          onClick={goBack}
          label="Go Back"
          className="bg-point-blue min-w-[204px] h-12 py-1 px-[65px] gap-2 text-point-white font-medium text-base leading-[180%] border border-point-white"
        />
        <Button
          onClick={() => navigate("/")}
          label="Go To Homepage"
          className="bg-point-input-bg min-w-[234px] h-12 py-1  gap-2 text-point-primary-text font-medium text-base leading-[180%] border border-transparent"
        />
      </div>
    </div>
  );
};

export default AppError;
