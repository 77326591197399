import Button from "../../auth/components/Button";
import React, { FC, useState } from "react";
import BookingVerification from "../components/BookingVerification";
import SpaceCard from "../components/SpaceCard";

import BookWorkStation from "./BookWorkStation";
import { useGetFrontdeskQuery } from "../utils/apis/getDashboard";
import BookMeetingRoom from "./BookMeetingRoom";
import FrontdeskBookings from "../components/Calendar";

interface IChange {
  showBookWorkStationModal: boolean;
  setShowBookWorkStationModal: React.Dispatch<React.SetStateAction<boolean>>;
  showBookMeetingRoomModal: boolean;
  setShowMeetingRoomModal: React.Dispatch<React.SetStateAction<boolean>>;
}
function FrontdeskDashboard({
  showBookWorkStationModal,
  setShowBookWorkStationModal,
  showBookMeetingRoomModal,
  setShowMeetingRoomModal,
}: IChange) {
  return (
    <div className="w-full flex flex-col gap-8 ">
      <DashboardCard />
      <FrontdeskBookings />
      <BookWorkStation
        open={showBookWorkStationModal}
        onClose={() => setShowBookWorkStationModal(false)}
      />
      <BookMeetingRoom
        open={showBookMeetingRoomModal}
        onClose={() => setShowMeetingRoomModal(false)}
      />
    </div>
  );
}

const DashboardCard: FC = () => {
  const { data } = useGetFrontdeskQuery();

  return (
    <div className="flex w-full gap-3 justify-start items-center">
      <div className="w-1/2">
        <BookingVerification
          users={data?.latest_booking_verification?.map((el: any) => ({
            fullName: el.customer,
            image: el.customer_image,
            type: el.customer_status,
          }))}
          count={data?.total_booking_verification}
        />
      </div>
      <SpaceCard
        stats="unbooked"
        value={data?.total_unbooked_spaces}
        url="/app/spaces?filter=available"
      />
      <SpaceCard
        stats="occupied"
        value={data?.total_occupied_spaces}
        url="/app/spaces?filter=occupied"
      />
    </div>
  );
};

export default FrontdeskDashboard;
