import AppErrorBoundary from "./hoc/AppErrorBoundary";
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";
import NotificationProvider from "./providers/Notification";
import BounceLoader from "react-spinners/BounceLoader";
import ChatProvider from "./providers/UnreadChats";
const queryClient = new QueryClient();
function CombineProviders({ children }: { children: React.ReactNode }) {
  return (
    <>
      <BrowserRouter>
        <AppErrorBoundary>
          <Suspense
            fallback={
              <div className="w-screen h-screen flex justify-center items-center bg-white">
                <BounceLoader color="#1C3C54" loading size={75} />
              </div>
            }
          >
            <ChatProvider>
              <NotificationProvider>
                <QueryClientProvider client={queryClient}>
                  {children}
                </QueryClientProvider>
              </NotificationProvider>
            </ChatProvider>
          </Suspense>
        </AppErrorBoundary>
      </BrowserRouter>
      <ToastContainer style={{ zIndex: 150000 }} limit={2} />
    </>
  );
}

export default CombineProviders;
