import { getBookings } from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import { QueryKey, useQuery, UseQueryOptions } from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";
import { addDataToQueryString } from "../../../../utils/stringHelpers";

export const GET_CUSTOMERS = "GET_CUSTOMERS_BOOKING";

const getCustomerBookingsAction = async (body: any) => {
  const qs = addDataToQueryString("", body);
  try {
    const { data } = await axiosAction(
      "GET",
      getBookings(`?${qs}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useGetCustomerBookingsQuery = <T = any>(
  body: any,
  opts?: Omit<UseQueryOptions<T, unknown, T, QueryKey>, "queryKey" | "queryFn">
) => {
  return useQuery<T>(
    [GET_CUSTOMERS, { ...body }],
    () => getCustomerBookingsAction(body),
    opts
  );
};
