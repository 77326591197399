import RadioButton from "../../../../components/Input/RadioButton";
import React, { useState } from "react";

function MakePayment(props: {
  setFieldValue?: (name: string, value: any) => void;
  values?: Record<string, any>;
  errors?: Record<string, any>;
}) {
  const { setFieldValue, values } = props;

  return (
    <section role="tabpanel" className="flex flex-col gap-6 w-full">
      <h1 className="font-medium text-[22px] leading-[140%]">
        Select a Payment method
      </h1>
      <div
        className="w-full flex justify-start gap-12 items-start content-start flex-wrap"
        role="radiogroup"
      >
        <RadioButton
          onChange={(e) =>
            setFieldValue?.("payment_type", e.currentTarget.value)
          }
          label="Room"
          id="room"
          name="paymentmethod"
          value="room"
        />
        <RadioButton
          onChange={(e) =>
            setFieldValue?.("payment_type", e.currentTarget.value)
          }
          label="POS/Cash"
          id="pos-cash"
          name="paymentmethod"
          value="cash"
        />
      </div>
    </section>
  );
}

export default MakePayment;
