import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AuthAppLayout from "../../layouts/auth";
import MissingPage from "../errors/MissingPage";

function AuthRoute() {
  return (
    <AuthAppLayout>
      <Outlet />
    </AuthAppLayout>
  );
}

export default AuthRoute;
