import React from "react";
import { getMeridian, isToday, isYesterday } from "../../../utils/dateHelper";
import { pad } from "../../../utils/stringHelpers";

interface INotification {
  id: number;
  read: boolean;
  node: string;
  message: string;
  sender_message?: string;
  sender?: Sender;
  title: string;
  created_at: string;
  object_id: number;
  object_type: string;
}

interface Sender {
  id: number;
  name: string;
  type: string;
  email: string;
  online: boolean;
  customer_type: string;
}

const Notification = (props: { data: INotification; onClick: () => void }) => {
  const { data, onClick } = props;

  return (
    <div
      onClick={onClick}
      className="flex justify-start items-start w-full gap-3 px-2 py-4 border-b hover:bg-[#a7a7a71a] cursor-pointer "
    >
      <div
        className={`w-2 h-2 rounded-full m-0 ${
          !data?.read ? "bg-point-green-dark" : "bg-transparent"
        }`}
      ></div>
      <div className="flex flex-col -mt-2 justify-start gap-1 items-stretch flex-1 h-full">
        <div className="flex justify-between item-center">
          <h3 className="font-medium text-base leading-[180%] capitalize text-point-primary-text max-w-[50%] text-ellipsis overflow-hidden ">
            {data?.object_type}
          </h3>
          <div className="text-[#7a7a7a] tracking-[0.08em] leading-[160%] font-light text-sm ">
            {getTimeFormatted(data?.created_at)}
          </div>
        </div>
        <div className="overflow-hidden w-full pr-2 text-ellipsis h-[58px] text-point-primary-text font-light leading-[180%]">
          {data?.message?.length > 82
            ? data.message.substring(0, 82) + "..."
            : data.message}
        </div>
      </div>
    </div>
  );
};

const getTimeFormatted = (str: string) => {
  const date = new Date(str);
  if (isToday(date)) {
    const [hours, minutes] = date.toLocaleTimeString().split(":").map(Number);
    return pad(hours % 12, 2) + ":" + pad(minutes, 2) + " " + getMeridian(str);
  } else if (isYesterday(date)) {
    const [hours, minutes] = date.toLocaleTimeString().split(":").map(Number);
    return (
      "Yesterday - " +
      (pad(hours % 12, 2) + ":" + pad(minutes, 2) + " " + getMeridian(str))
    );
  } else {
    return date.toLocaleDateString();
  }
};
export default Notification;
