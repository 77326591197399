import { addDataToQueryString } from "./../../../../utils/stringHelpers";
import { getSpaces } from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import { useQuery } from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";

export const GET_OCCUPIED_SPACES = "GET_OCCUPIED_SPACES";
export const GET_WORKSTATION = "GET_WORKSTATION";
export type DatesTypes = {
  start_date: string;
  end_date: string;
};
const getOccupiedSpaces = async (
  search: string,
  occupied?: boolean | string
) => {
  const qstring = addDataToQueryString("", {
    page: 1,
    occupied: String(occupied === undefined ? true : occupied),
    name: search,
  });
  try {
    const { data } = await axiosAction(
      "GET",
      getSpaces(`?${qstring}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

const getAvailableWorkStations = async ({
  start_date,
  end_date,
}: DatesTypes) => {
  const qstring = addDataToQueryString("", {
    start_date,
    end_date,
  });
  try {
    const { data } = await axiosAction(
      "GET",
      getSpaces(`available_workstations/?${qstring}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};
export const useGetOccupiedSpacesQuery = (
  search: string,
  occupied?: boolean | string
) => {
  return useQuery(
    [
      GET_OCCUPIED_SPACES,
      search,
      String(occupied === undefined ? true : occupied),
    ],
    () => getOccupiedSpaces(search, occupied)
  );
};
export const useGetAvailableWorkStationQuery = (dates: DatesTypes) => {
  return useQuery(
    [GET_WORKSTATION, dates],
    () => getAvailableWorkStations(dates),
    {
      enabled: Boolean(dates.start_date && dates.end_date),
    }
  );
};
