import { Icons } from "../../assets/icons";
import React, { FC, startTransition, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

interface ISidebar {
  parentPath: string;
  position: number;
  title: string;
  activeIcon: string;
  icon: string;
  items: {
    label: string;
    onClick?: () => void;
    selected?: boolean;
    url: string;
  }[];
}

const SidebarItemWithChildren: FC<ISidebar> = (props) => {
  const {
    parentPath = "/app/settings",
    position,
    title,
    activeIcon,
    icon,
    items,
  } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState(pathname.includes(parentPath));

  const [page, setPage] = useState(pathname);
  useEffect(() => {
    setIsActive(pathname.includes(parentPath));

    setPage(pathname);
  }, [pathname]);

  const showSubMenus = () => {
    setIsActive((prev) => !prev);
  };
  return (
    <li
      role="menuitem"
      aria-posinset={position}
      tabIndex={0}
      className={"flex flex-col w-full "}
    >
      <div
        className={`flex items-center justify-start gap-4 px-4 py-3 bg-transparent w-full cursor-pointer`}
        onClick={showSubMenus}
      >
        <span id={title} className="text-white">
          <img src={isActive ? activeIcon : icon} alt={title} />
        </span>
        <span
          aria-labelledby={"Settings"}
          className="text-white font-medium text-base leading-[30px]"
        >
          {title}
        </span>
        <span className="cursor-pointer justify-self-end ml-auto w-8">
          <img
            src={Icons.utility.dropdownIcon}
            alt="show or hide dropdown"
            className={isActive ? "mt-3" : "-mt-2"}
            style={{
              transform: `${isActive ? "rotate(180deg)" : "rotate(0deg)"}`,
              transition: "transform 200ms ease-in",
            }}
          />
        </span>
      </div>
      {isActive && (
        <ul className="w-full flex flex-col gap-1" role="tree">
          {items.map((child) => (
            <li
              key={child.url}
              onClick={() => setPage(child.url)}
              className={`text-white font-medium text-sm leading-[29px] hover:bg-[#112433] h-10 flex flex-col justify-center items-start ${
                page.includes(child.url) ? "bg-[#112433]" : "bg-transparent"
              } w-full cursor-pointer pl-[54px]`}
              role="treeitem"
              tabIndex={1}
            >
              <div
                role="link"
                tabIndex={0}
                className="w-full"
                onClick={() => startTransition(() => navigate(child.url))}
              >
                {child.label}
              </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default SidebarItemWithChildren;
