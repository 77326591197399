import React, {
  AllHTMLAttributes,
  HTMLAttributes,
  useEffect,
  useState,
} from "react";

interface ICheckbox extends AllHTMLAttributes<HTMLInputElement> {
  checkComponent?: React.ReactElement;
  uncheckedComponent?: React.ReactElement;
  indeterminate?: boolean;
}

function Checkbox(props: ICheckbox) {
  const {
    onChange,
    checked: passCheck,
    checkComponent,
    uncheckedComponent,
    indeterminate,
  } = props;
  const [checked, setChecked] = useState(passCheck);
  const changeHandler = (e: any) => {
    setChecked(e.currentTarget.checked);
    onChange?.(e);
  };
  useEffect(() => {
    setChecked(passCheck);
  }, [passCheck]);
  return (
    <div className="cursor-pointer relative">
      <input
        type="checkbox"
        // onClick={(e) => setChecked(e.currentTarget.checked)}
        onChange={changeHandler}
        {...props}
        className="opacity-0 absolute h-8 w-8 outline-none border-none peer"
      />
      {checkComponent && uncheckedComponent ? (
        checked ? (
          checkComponent
        ) : (
          uncheckedComponent
        )
      ) : (
        <div
          className={`bg-point-tertiary-bg ${
            checked || indeterminate ? "border-0" : "border-2"
          } border-[#D9D9D9] w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 
         rounded peer-indeterminate:fill-point-tertiary-bg `}
        >
          {indeterminate ? (
            <svg
              width="32px"
              height="32px"
              viewBox="0 0 32 32"
              id="icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="32"
                height="32"
                fill="var(--icon-bg)"
                rx={4}
                ry={4}
              />
              <line
                x1="5"
                y1="16"
                x2="27"
                y2="16"
                strokeWidth={4}
                stroke="white"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              className="fill-current hidden w-4 h-4 text-point-tertiary-bg"
              version="1.1"
              viewBox="0 0 17 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fillRule="evenodd">
                <g
                  transform="translate(-9 -11)"
                  fill="#FFFF"
                  fillRule="nonzero"
                >
                  <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                </g>
              </g>
            </svg>
          )}
        </div>
      )}
    </div>
  );
}

export default Checkbox;
