import { Icons } from "../../../assets/icons";
import React, { HTMLAttributes } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as SpaceIcon } from "../../../assets/icons/dashboard/space.svg";

interface ISpaceCard extends HTMLAttributes<HTMLDivElement> {
  stats: "unbooked" | "occupied";
  value: string;
  url: string;
}
function SpaceCard(props: ISpaceCard) {
  const { stats, value, url, className = "", ...rest } = props;
  return (
    <section
      className={`flex-1 flex flex-col justify-start items-start p-4 h-[184px] gap-5 border border-[#d9d9d9] rounded-lg ${className} `}
      {...rest}
    >
      <div className="w-full flex flex-col justify-start items-start gap-0.5">
        <div className="w-full flex justify-between items-start">
          <h4 className="capitalize font-medium test-base leading-[180%] text-point-secondary-text">
            {stats} Spaces
          </h4>
          <div
            className={`rounded-full h-10 w-10 flex justify-center items-center ${
              stats === "unbooked"
                ? "bg-point-orange-light"
                : "bg-point-green-light"
            }`}
          >
            <SpaceIcon
              fill={
                stats === "unbooked"
                  ? "var(--orange-dark)"
                  : "var(--green-dark)"
              }
            />
          </div>
        </div>
        <h3 className="font-bold text-[38px] leading-[120%] text-point-primary-text">
          {value}
        </h3>
      </div>
      <Link
        to={url}
        className="font-bold text-point-secondary-text flex-1 justify-self-end flex justify-start items-center gap-1 text-sm leading-5"
      >
        <span>Show More</span>
        <img src={Icons.utility.rightArrowIcon} alt="showMore" />
      </Link>
    </section>
  );
}

SpaceCard.defaultProps = {
  className: "",
};
export default SpaceCard;
