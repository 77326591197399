import { Icons } from "../../assets/icons";
import React, { useEffect } from "react";

interface ISidebar {
  onClose?: () => void;
  open: boolean;

  children: React.ReactNode;
  transparent?: boolean;
  className?: string;
  freeze?: boolean;
}
function Sidebar(props: ISidebar) {
  const { onClose, open, children, transparent, className, freeze } = props;
  useEffect(() => {
    if (open && freeze) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.left = `0px`;
      document.body.style.right = `0px`;
    } else {
      document.body.style.overflowY = "auto";
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [open, freeze]);
  return (
    <div
      onClick={onClose}
      className={`w-screen h-screen fixed z-30  left-0 right-0 ${
        open
          ? "top-0 " + (transparent ? "bg-transparent" : "bg-[#00000080]")
          : "-top-[1000vh]"
      } ${className || ""}`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`absolute z-0  top-[78px] right-0  h-screen bg-white duration-500 w-[430px] shadow ${
          open ? "translate-x-0" : "translate-x-[400px]"
        }`}
      >
        <main className="w-full">{children}</main>
      </div>
    </div>
  );
}

export default Sidebar;
