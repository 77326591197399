import {
  getBookings,
  getChatsSummary,
  getMessagesOfChat,
} from "./../../../../apis/endpoints";
import { axiosAction } from "./../../../../apis/axiosInstance";
import {
  MutateOptions,
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { HttpStatusCode } from "../../../../apis/constants";
import { addDataToQueryString } from "../../../../utils/stringHelpers";

export const GET_CUSTOMERS_CHATS = "GET_CUSTOMERS_CHATS";
export const GET_CUSTOMER_MESSAGES = "GET_CUSTOMER_MESSAGES";
export type AllChartsDTO = {
  page?: number;
  unread?: boolean | string;
  sort?: boolean;
  search?: string;
};
export type AllMessagesDTO = {
  page?: number;
  chatId?: number;
};
export type MessageDTO = {
  chat: number;
  message: string;
};
export type ChatDataWithCursor = {
  count: number;
  next: null | string;
  previous: null | string;
  results: any[];
};

export const getCustomerChatsAction = async (body: AllChartsDTO) => {
  const qs = addDataToQueryString("", {
    page: body.page,
    by_unread: body.unread,
    search: body.search || "",
    by_name: body.sort === true ? body.sort : "",
  });
  try {
    const { data } = await axiosAction(
      "GET",
      getChatsSummary(`?${qs}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getMessagesOfChatAction = async (body: AllMessagesDTO) => {
  const qs = addDataToQueryString("", {
    page: body.page,
    chat_id: body.chatId,
  });
  if (!body.chatId) return;

  try {
    const { data } = await axiosAction(
      "GET",
      getMessagesOfChat(`?${qs}`),
      HttpStatusCode.Ok
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

const sendMessageAction = async (body: MessageDTO) => {
  try {
    const { data } = await axiosAction(
      "POST",
      getMessagesOfChat(""),
      HttpStatusCode.Created,
      body
    );
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const useGetCustomerChatsQuery = <T = any>(
  body: AllChartsDTO,
  opts?: Omit<UseQueryOptions<T, unknown, T, QueryKey>, "queryKey" | "queryFn">
) => {
  return useQuery<T>(
    [GET_CUSTOMERS_CHATS, { ...body }],
    () => getCustomerChatsAction(body),
    opts
  );
};

export const useSendMessageMutation = (
  args?: MutateOptions<any, any, any, any>
) => {
  const qc = useQueryClient();
  return useMutation(sendMessageAction, {
    onSuccess: (_d, variables) => {
      qc.invalidateQueries({
        queryKey: [GET_CUSTOMER_MESSAGES],
      });
      qc.invalidateQueries({
        queryKey: [GET_CUSTOMERS_CHATS],
      });
    },
    ...args,
  });
};
