import SelectInput from "../../../components/Input/SelectInput";
import React, { HTMLAttributes, useState } from "react";
import { Icons } from "../../../assets/icons";

interface ICard extends HTMLAttributes<HTMLElement> {
  color: "orange" | "purple" | "pink" | "green";
  title: string;
  value: string;
  onChangeOption?: (value: string) => void;
  option?: string;
  options?: any[];
}
const colorImageMap = {
  orange: Icons.dashboard.orangeCardIcon,
  purple: Icons.dashboard.purpleCardIcon,
  pink: Icons.dashboard.pinkCardIcon,
  green: Icons.dashboard.greenCardIcon,
};
function Card({ color, title, value, option, onChangeOption, options }: ICard) {
  const [innerOption, setInnerOption] = useState(option || "all");
  const changeHandler = (value: string) => {
    onChangeOption?.(value);
    setInnerOption(value);
  };
  const getValueFromOption = (opt: string) => {
    if (!options) return;
    return options.find((el) => el.name === opt)?.value || 0;
  };
  return (
    <div
      style={{ backgroundColor: `var(--${color})` }}
      className={`flex-grow rounded border-point-accent-boder border border-solid p-4 pt-6 min-h-[120px]`}
    >
      <div className="w-full flex justify-between">
        <div>
          <h5 className="text-point-secondary-text font-medium leading-8">
            {title}
          </h5>
          <h6 className="text-point-primary-text font-medium leading-tight text-[38px]">
            {options ? getValueFromOption(innerOption) : value}
          </h6>
        </div>
        <div className="flex justify-center flex-col items-end gap-1">
          {color === "orange" && (
            <SelectInput
              className="text-point-orange-dark text-[13px] font-light leading-[160%] max-h-5 h-5 fill-[#FF9D00] min-w-max px-0 -mr-5"
              menuClassName="-mt-3 rounded-tl-none rounded-tr-none ml-8"
              value={option}
              options={options as any[]}
              optionsValue={(item) => item.name}
              optionsText={(item) => item.label}
              onChange={(e) => changeHandler(e.currentTarget.value)}
            />
          )}
          {color === "purple" && (
            <SelectInput
              className="text-point-purple-dark text-[13px] font-light leading-[160%] max-h-5 h-5 fill-[#705BFA] min-w-max px-0 -mr-5"
              menuClassName="-mt-3 rounded-tl-none rounded-tr-none ml-10"
              value={option}
              options={options as any[]}
              optionsValue={(item) => item.name}
              optionsText={(item) => item.label}
              onChange={(e) => changeHandler(e.currentTarget.value)}
            />
          )}
          <div
            style={{ backgroundColor: `var(--${color}-accent)` }}
            className={`w-10 h-10 rounded-full flex justify-center items-center bg-point-${color}-accent`}
          >
            <img
              src={colorImageMap[color]}
              alt={"representation of " + title}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
