import React from "react";

function Backdrop({
  onClose,
  children,
}: {
  onClose: () => void;
  children: React.ReactNode;
}) {
  return (
    <div
      onClick={onClose}
      style={{ zIndex: 100000 }}
      className="w-screen h-screen min-h-screen fixed top-0 left-0 bg-black bg-opacity-80 z-100"
    >
      {children}
    </div>
  );
}

export default Backdrop;
