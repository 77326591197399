import { Icons } from "../../../assets/icons";
import React, { FC } from "react";

interface IConnector {
  current: number;
  total: number;
  values: Array<{ label: string; value?: string }>;
}
function Connector(props: IConnector) {
  const { current, total, values } = props;
  return (
    <div className="w-full text-center relative" role="tablist">
      <div className="w-full rounded h-1 bg-[#EEEEEE]"></div>
      <div className="px-1 flex absolute -top-4 left-0 justify-between w-full">
        {values.map((value, ind) => {
          return (
            <ConnectorNode
              key={ind}
              active={current >= ind + 1}
              label={value.label}
            />
          );
        })}
      </div>
    </div>
  );
}

const ConnectorNode: FC<{ active: boolean; label: string }> = ({
  active,
  label,
}) => {
  return (
    <div
      role="tab"
      className={`flex flex-col justify-start items-center gap-0.5 ${
        active ? "text-point-primary-text" : "text-point-secondary-text"
      }`}
    >
      <img
        src={
          active
            ? Icons.dashboard.circleChecked
            : Icons.dashboard.circleCheckedEmpty
        }
        alt={label + " selected"}
      />
      <strong className="leading-[180%] font-medium capitalize text-base">
        {label}
      </strong>
    </div>
  );
};

export default Connector;
